import { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  FlatList,
  Platform,
  View,
  Text,
  Image,
  ImageBackground,
  ScrollView,
  SafeAreaView,
} from "react-native";
import LottieView from "lottie-react-native";
import {
  AntDesign,
  Octicons,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  Zocial,
  Fontisto,
} from "@expo/vector-icons";

import { inject, observer } from "mobx-react";
import { WebView } from "react-native-webview";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Paragraph, Dialog, Portal, useTheme } from "react-native-paper";
import BouncingPreloader from "react-native-bouncing-preloaders";

import * as RootNavigation from "../navigation/RootNavigation";

import {
  CloseDialog,
  ReviewDialog,
  PayPalDialog,
  MomoDialog,
} from "../components/RestaurantComponents";

export const RestaurantScreen = observer(({ route, navigation, props }) => {
  const [restaurant, setRestaurant] = useState();

  useEffect(() => {
    if (
      route &&
      route.params &&
      store.selectedRestaurantId !== route.params.id
    ) {
      store.setSelectedRestaurantId(route.params.id);
      setRestaurant(store.selectedRestaurant);
      if (store.selectedRestaurant) {
        store.setDescription(store.selectedRestaurant.description);
        store.setMetaImage(store.selectedRestaurant.heroImage);
      }
    }
  }, [route.params]);

  useEffect(() => {
    store.setShowCloseAlert(false);
    setTimeout(() => {
      store.setShowCloseAlert(true);
    }, 30000);
  }, []);

  if (!restaurant) return <View></View>;
  if (props && props.closeRestModal)
    return (
      <RestaurantDetails
        rest={restaurant}
        closeRestModal={props.closeRestModal}
      />
    );
  else return <RestaurantDetails rest={restaurant} />;
});

export const RestaurantDetails = observer((props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [restaurant, setRestaurant] = useState(
    props.restaurant ? props.restaurant : null
  );
  useEffect(() => {
    if (!restaurant) {
      setRestaurant(store.selectedRestaurant);
      if (store.selectedRestaurant) {
        store.setDescription(store.selectedRestaurant.description);
        store.setMetaImage(store.selectedRestaurant.heroImage);
      }
    }
  }, []);
  if (!restaurant) return <View></View>;
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: restaurant.showHeader && !props.inline ? 70 : 0,
      }}
    >
      <MomoDialog restaurant={restaurant} />
      <PayPalDialog restaurant={restaurant} />
      <ReviewDialog restaurant={restaurant} />
      {props.closeRestModal ? (
        <CloseDialog
          closeRestModal={props.closeRestModal}
          restaurant={restaurant}
        />
      ) : (
        <CloseDialog restaurant={restaurant} />
      )}
      {/* <Image
        source={{ uri: store.getImageDirectory(restaurant.logoImageUrl) }}
        style={{ width: 50, height: 50, borderRadius: 25 }}
      />
      <Text style={[styles.title, { color: restaurant.primaryColor }]}>
        {restaurant.title}
      </Text> */}

      {(!restaurant.showHeader || props.inline) && (
        <View
          style={{
            width: "100%",
            backgroundColor: restaurant.backgroundColor,
            height: 30,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (props.inline) props.closeRestModal();
              else store.goBack();
            }}
            style={{ position: "absolute", top: 0, left: 5 }}
          >
            <MaterialCommunityIcons
              name="chevron-left"
              size={Platform.OS === "web" ? 24 : 28}
              color={restaurant.primaryColor}
            />
          </TouchableOpacity>
          <View
            style={{ flexDirection: "row", position: "absolute", left: 35 }}
          >
            {store.screenWidth < 800 && restaurant.paypal !== "" && (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  // width: 65,
                  // height: 32,
                  // position: "absolute",
                  // top: -10,
                  // left: 0,
                }}
                onPress={(e) => {
                  store.setPaypalDialogVisible(true);
                }}
              >
                <Entypo
                  name="paypal"
                  size={20}
                  color={restaurant.primaryColor}
                />
              </TouchableOpacity>
            )}
            {store.screenWidth < 800 && restaurant.momo !== "" && (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 10,
                }}
                onPress={(e) => {
                  store.setMomoDialogVisible(true);
                }}
              >
                <Image
                  source={{
                    uri: store.getImageDirectory("/foood.vn/momo.png"),
                  }}
                  resizeMode={"contain"}
                  style={{
                    width: 20,
                    minHeight: 20,
                    tintColor: restaurant.primaryColor,
                  }}
                />
              </TouchableOpacity>
            )}
          </View>
          {props.inline && restaurant && restaurant.showHeader && (
            <View
              style={{
                position: "absolute",
                alignSelf: "center",
                top: -3,
                zIndex: 100,
              }}
            >
              <Image
                source={{
                  uri: store.getImageDirectory(restaurant.logoImageUrl),
                }}
                resizeMode="contain"
                style={{
                  minHeight: 30,
                  maxHeight: 50,
                  width: 70, //"100%",
                  minWidth: 70,
                }}
              />
            </View>
          )}
          <View
            style={{
              // minHeight: 70,
              // marginTop: Constants.statusBarHeight + 5,
              position: "absolute",
              right: 25,
              minWidth:
                store.screenWidth > 800
                  ? 210
                  : Math.round(store.screenWidth / 3),
              top: 3,
              // zIndex: 1000,
              // flex: 0.3,
              // backgroundColor: "blue",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {store.screenWidth > 800 && restaurant.rating !== "" && (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onPress={(e) => {
                  store.setReviewDialogVisible(true);
                }}
              >
                {/* <Text
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: restaurant.primaryColor,
                                                    fontSize: 24,
                                                  }}
                                                  numberOfLines={2}
                                                >
                                                  {restaurant.rating}
                                                </Text> */}
                <MaterialCommunityIcons
                  name="star"
                  size={20}
                  color={restaurant.primaryColor}
                />
              </TouchableOpacity>
            )}
            {store.screenWidth > 800 && restaurant.paypal !== "" && (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onPress={(e) => {
                  store.setPaypalDialogVisible(true);
                }}
              >
                <Entypo
                  name="paypal"
                  size={20}
                  color={restaurant.primaryColor}
                />
              </TouchableOpacity>
            )}
            {store.screenWidth > 800 &&
              restaurant.momo &&
              restaurant.momo !== "" && (
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 2,
                  }}
                  onPress={(e) => {
                    store.setMomoDialogVisible(true);
                  }}
                >
                  <Image
                    source={{
                      uri: store.getImageDirectory("/foood.vn/momo.png"),
                    }}
                    resizeMode={"contain"}
                    style={{
                      width: 20,
                      minHeight: 20,
                      tintColor: restaurant.primaryColor,
                    }}
                  />
                </TouchableOpacity>
              )}
            {restaurant.phone !== "" && (
              <TouchableOpacity
                style={{ marginHorizontal: 2 }}
                onPress={(e) => {
                  if (Platform.OS === "web")
                    window.open("tel//:" + restaurant.phone, "_blank").focus();
                  else Linking.openURL("tel//:" + restaurant.phone);
                }}
              >
                <MaterialIcons
                  name="phone"
                  size={20}
                  color={restaurant.primaryColor}
                  style={
                    {
                      //marginBottom: 20,
                      // marginRight: 20,
                    }
                  }
                />
              </TouchableOpacity>
            )}
            {restaurant.facebook !== "" && (
              <TouchableOpacity
                style={{ marginHorizontal: 2 }}
                onPress={(e) => {
                  if (Platform.OS === "web")
                    window.open(restaurant.facebook, "_blank").focus();
                  else
                    Linking.openURL(
                      "fb://facewebmodal/f?href=" + restaurant.facebook
                    );
                }}
              >
                <MaterialCommunityIcons
                  name="facebook"
                  size={20}
                  color={restaurant.primaryColor}
                  style={
                    {
                      //marginBottom: 20,
                      // marginRight: 20,
                    }
                  }
                />
              </TouchableOpacity>
            )}
            {restaurant.messenger !== "" && (
              <TouchableOpacity
                style={{ marginHorizontal: 2 }}
                onPress={(e) => {
                  if (Platform.OS === "web")
                    window.open(restaurant.messenger, "_blank").focus();
                  else
                    Linking.openURL(
                      "fb://facewebmodal/f?href=" + restaurant.messenger
                    );
                }}
              >
                <MaterialCommunityIcons
                  name="facebook-messenger"
                  size={20}
                  color={restaurant.primaryColor}
                  style={
                    {
                      //marginBottom: 20,
                      // marginRight: 20,
                    }
                  }
                />
              </TouchableOpacity>
            )}
          </View>
          {props.inline && (
            <TouchableOpacity
              onPress={() => {
                if (props.inline) props.closeRestModal();
                else store.goBack();
              }}
              style={{ position: "absolute", top: 0, right: 5 }}
            >
              <Ionicons
                name={"close"}
                size={Platform.OS === "web" ? 24 : 28}
                color={restaurant.primaryColor}
              />
            </TouchableOpacity>
          )}
        </View>
      )}
      {/* {isLoading && (
        <LottieView
          source={
            // uri: store.getImageDirectory("/foood.vn/loading-animation.json"),
            require("../assets/loading-animation.json")
          }
          autoPlay
          loop
          style={{ width: 350, marginTop: 50, alignItems: "center" }}
        />
      )} */}
      {isLoading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: store.screenHeight,
            backgroundColor: restaurant.backgroundColor,
          }}
        >
          <View
            style={{
              position: "absolute",
              alignSelf: "center",
              top: 300,
              zIndex: 100,
            }}
          >
            <BouncingPreloader
              icons={[store.getImageDirectory(restaurant.logoImageUrl)]}
              leftRotation="-680deg"
              rightRotation="360deg"
              leftDistance={-180}
              rightDistance={-250}
              size={150}
              speed={2500}
            />
          </View>
        </View>
      )}

      {Platform.OS === "web" ? (
        <iframe
          onLoad={(e) => {
            setTimeout(() => {
              setIsLoading(false);
            }, 400);
          }}
          loading="eager"
          key={store.key}
          src={restaurant.orderingLink}
          title={restaurant.title}
          width="100%"
          height="100%"
          frameBorder={0}
          style={{ borderWidth: 0, opacity: isLoading ? 0 : 1 }}
          id="restaurant_orderingLink"
          // thirdPartyCookiesEnabled={true}
        ></iframe>
      ) : (
        <WebView
          source={{ uri: restaurant.orderingLink }}
          style={{
            marginTop: 20,
            minHeight: store.screenHeight,
            minWidth: store.screenWidth,
            height: "100%",
            width: "100%",
          }}
          thirdPartyCookiesEnabled={true}
        />
      )}
    </View>
  );
});
