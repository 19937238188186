import React, { Component, useState, useRef, useEffect } from "react";
import * as Linking from "expo-linking";

import {
  ScrollView,
  SafeAreaView,
  ImageBackground,
  Dimensions,
  Button,
  View,
  Text,
  Image,
  Platform,
  TouchableOpacity,
  TextInput,
  Keyboard,
} from "react-native";
import {
  useLinking,
  NavigationContainer,
  DrawerActions,
  Link,
  StackActions,
  useIsFocused,
} from "@react-navigation/native";
import { useDrawerStatus } from "@react-navigation/drawer";

import {
  Feather,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
  Ionicons,
  Entypo,
  MaterialCommunityIcons,
  Fontisto,
  Octicons,
} from "@expo/vector-icons";

import { inject, observer } from "mobx-react";

import * as RootNavigation from "../navigation/RootNavigation";

import Constants from "expo-constants";

const { width, height } = Dimensions.get("window");

import {
  ArrowDropDown,
  Notifications,
  RestaurantRounded,
  Search,
} from "@material-ui/icons";

export const Navbar = observer((props) => {
  const { store } = props;

  var isDrawerOpen = false; //
  // if (store.screenType === "mobile")
  //   isDrawerOpen = useDrawerStatus() === "open";

  // const onChangeSearch = (query) => {
  //   store.setSearch(query);
  //   if (query !== "" && store.screen !== "Restaurants")
  //     RootNavigation.navigate("Tabs", {
  //       screen: "RestaurantsStack",
  //       params: {
  //         screen: "Restaurants",
  //         params: {
  //           category: "all",
  //           sort: "default",
  //         },
  //       },
  //     });
  // };

  // useEffect(() => {
  //   store.setShowSearch(false);
  // }, []);

  return (
    <View
      style={[
        {
          width: "100%",
          color: "#fff",
          fontSize: 14,
          position: "absolute",
          top: 0,
          zIndex: 999,
          borderBottomColor: store.primaryColor,
          borderBottomWidth: store.screen === "Posts" ? 1 : 0,
          borderBottomStyle: "solid",
          backgroundColor:
            store.screen === "Posts" ? "#fff" : store.backgroundColor,
        },
        { minWidth: width, paddingLeft: 0 },
      ]}
    >
      {store.screenWidth < 800 && (
        <View
          style={{
            position: "absolute",
            alignSelf: "center",
            top: "5%",
            zIndex: 100,
          }}
        >
          <TouchableOpacity
            onPress={(e) => {
              if (store.screen !== "Restaurant") {
                store.setSelectedRestaurantId("");
                store.setSelectedProductId("");
                RootNavigation.navigate("Tabs", {
                  screen: "HomeStack",
                  params: {
                    screen: "Home",
                  },
                });
              }
            }}
          >
            <Image
              source={{
                uri: store.logoImageUrl,
              }}
              resizeMode="contain"
              style={{
                minHeight: 60,
                width: 100, //"100%",
                maxWidth: 100,
              }}
            />
          </TouchableOpacity>
        </View>
      )}
      <View
        className="container"
        style={{
          // padding: store.screenWidth > 800 ? "0px 50px" : "0px 20px",
          // marginTop: Platform.OS === "web" ? 0 : Constants.statusBarHeight,
          display: "flex",
          // flex: 1,
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "space-between",
          height: 70,
        }}
      >
        <View
          className="left"
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",

            // maxWidth: "90%",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (store.selectedRestaurant && store.screen === "Restaurant") {
                if (store.showCloseAlert) {
                  store.setCloseAlertVisible(true);
                } else {
                  store.goBack();
                }
              } else
                RootNavigation.navigationRef.current.dispatch(
                  DrawerActions.toggleDrawer()
                );
            }}
            style={{
              // padding: 18,
              minHeight: 48,
              justifyContent: "center",
              alignItems: "center",
              minWidth: 48,
              maxWidth: 48,
              // marginTop: -10,
            }}
          >
            {store.selectedRestaurant && store.screen === "Restaurant" ? (
              <View>
                {store.selectedRestaurant &&
                  store.selectedRestaurant.showHeader && (
                    <Entypo
                      name="chevron-left"
                      size={32}
                      color={store.selectedRestaurant.primaryColor}
                    />
                  )}
              </View>
            ) : (
              <Ionicons
                name={isDrawerOpen ? "ios-close" : "ios-menu"}
                size={Platform.OS === "web" ? 32 : 32}
                color={store.primaryColor}
              />
            )}
          </TouchableOpacity>
          {((store.selectedRestaurant && store.selectedRestaurant.showHeader) ||
            store.screen !== "Restaurant") && (
            <View style={{ flexDirection: "row" }}>
              {/* {store.screenWidth < 800 &&
                                    store.selectedRestaurant &&
                                    store.selectedRestaurant.showHeader &&
                                    store.screen === "Restaurant" &&
                                    store.selectedRestaurant.rating !== "" && (
                                      <TouchableOpacity
                                        style={{

                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: 75,
                                          height: 32,
                                          position: "absolute",
                                          top: -16,
                                          left: 0,
                                        }}
                                        onPress={(e) => {
                                          store.setReviewDialogVisible(true);
                                        }}
                                      >
                                        {store.screenWidth > 320 && (
                                                                      <Text
                                                                        style={{
                                                                          fontWeight: "bold",
                                                                          color: store.selectedRestaurant.primaryColor,
                                                                          fontSize: 22,
                                                                          marginRight: 2,
                                                                        }}
                                                                        numberOfLines={2}
                                                                      >
                                                                        {store.selectedRestaurant.rating}
                                                                      </Text>
                                                                    )}
                                        <MaterialCommunityIcons
                                          name="star"
                                          size={32}
                                          color={store.selectedRestaurant.primaryColor}
                                        />
                                      </TouchableOpacity>
                                    )} */}
              {store.screenWidth < 800 &&
                store.selectedRestaurant &&
                store.selectedRestaurant.showHeader &&
                store.screen === "Restaurant" &&
                store.selectedRestaurant.paypal !== "" && (
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      // width: 65,
                      // height: 32,
                      // position: "absolute",
                      // top: -10,
                      // left: 0,
                    }}
                    onPress={(e) => {
                      store.setPaypalDialogVisible(true);
                    }}
                  >
                    <Entypo
                      name="paypal"
                      size={28}
                      color={store.selectedRestaurant.primaryColor}
                    />
                  </TouchableOpacity>
                )}
              {store.screenWidth < 800 &&
                store.selectedRestaurant &&
                store.selectedRestaurant.showHeader &&
                store.screen === "Restaurant" &&
                store.selectedRestaurant.momo !== "" && (
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 2,
                    }}
                    onPress={(e) => {
                      store.setMomoDialogVisible(true);
                    }}
                  >
                    <Image
                      source={{
                        uri: store.getImageDirectory("/foood.vn/momo.png"),
                      }}
                      resizeMode={"contain"}
                      style={{
                        width: 32,
                        minHeight: 32,
                        tintColor: store.selectedRestaurant.primaryColor,
                      }}
                    />
                  </TouchableOpacity>
                )}
              {store.screenWidth > 800 && (
                <TouchableOpacity
                  onPress={(e) => {
                    if (store.screen !== "Restaurant")
                      RootNavigation.navigate("Tabs", {
                        screen: "HomeStack",
                        params: {
                          screen: "Home",
                        },
                      });
                    else if (store.selectedRestaurant.paypal === "")
                      store.updateKey();
                    // RootNavigation.navigate("Tabs", {
                    //   screen: "RestaurantsStack",
                    //   params: {
                    //     screen: "Restaurants",
                    //     params: {
                    //       category: "all",
                    //       sort: "default",
                    //     },
                    //   },
                    // });
                  }}
                  style={{ width: 150, height: 50 }}
                >
                  <Image
                    source={{
                      uri: store.logoImageUrl,
                    }}
                    resizeMode="contain"
                    style={{
                      flex: 1,
                      height: null,
                      resizeMode: "contain",
                      width: null,
                      // minHeight: 50,
                      // maxHeight: 50,
                      // width: "100%",
                      // maxWidth: 200,
                      // marginRight: 40,
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
          )}
          {/* {store.screenWidth > 800 && (
                        <View
                          className="left"
                          style={{
                            display: "flex",
                            // flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            marginLeft: 30,
                          }}
                        >
                          <TouchableOpacity
                            onPress={(e) => {
                              if (
                                store.selectedRestaurant &&
                                store.screen === "Restaurant"
                              ) {
                                if (store.showCloseAlert) {
                                  store.setCloseAlertVisible(true);
                                } else if (store.lastScreen == "Home") {
                                  RootNavigation.navigate("Tabs", {
                                    screen: "HomeStack",
                                    params: {
                                      screen: "Home",
                                    },
                                  });
                                } else if (
                                  store.lastScreen !== "Splash" &&
                                  store.lastScreen !== "Restaurants"
                                ) {
                                  RootNavigation.navigate(store.lastScreen);
                                } else {
                                  RootNavigation.navigate("Tabs", {
                                    screen: "RestaurantsStack",
                                    params: {
                                      screen: "Restaurants",
                                      params: {
                                        category: "all",
                                        sort: "default",
                                      },
                                    },
                                  });
                                }
                              } else
                                RootNavigation.navigate("Tabs", {
                                  screen: "RestaurantsStack",
                                  params: {
                                    screen: "Restaurants",
                                  },
                                });
                            }}
                          >
                            <Text
                              style={{
                                color: store.primaryColor,
                                fontSize: 14,
                                marginRight: 20,
                                cursor: "pointer",
                              }}
                            >
                              Restaurants
                            </Text>
                          </TouchableOpacity>

                        </View>
                      )} */}
        </View>
        {store.screen === "Splash" ? (
          <View> </View>
        ) : store.selectedRestaurant &&
          store.selectedRestaurant.showHeader &&
          store.screen === "Restaurant" ? (
          <View
            style={{
              // minHeight: 70,
              // marginTop: Constants.statusBarHeight + 5,
              position: "absolute",
              right: 5,
              minWidth:
                store.screenWidth > 800
                  ? 210
                  : Math.round(store.screenWidth / 3),
              top: 2,
              // zIndex: 1000,
              // flex: 0.3,
              // backgroundColor: "blue",
              justifyContent: "center",
              alignItem: "space-around",
              flexDirection: "row",
              paddingTop: 15,
            }}
          >
            {store.screenWidth > 800 &&
              store.selectedRestaurant.rating !== "" && (
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={(e) => {
                    store.setReviewDialogVisible(true);
                  }}
                >
                  {/* <Text
                                                                                  style={{
                                                                                    fontWeight: "bold",
                                                                                    color: store.selectedRestaurant.primaryColor,
                                                                                    fontSize: 24,
                                                                                  }}
                                                                                  numberOfLines={2}
                                                                                >
                                                                                  {store.selectedRestaurant.rating}
                                                                                </Text> */}
                  <MaterialCommunityIcons
                    name="star"
                    size={28}
                    color={store.selectedRestaurant.primaryColor}
                  />
                </TouchableOpacity>
              )}
            {store.screenWidth > 800 &&
              store.selectedRestaurant.paypal !== "" && (
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={(e) => {
                    store.setPaypalDialogVisible(true);
                  }}
                >
                  <Entypo
                    name="paypal"
                    size={28}
                    color={store.selectedRestaurant.primaryColor}
                  />
                </TouchableOpacity>
              )}
            {store.screenWidth > 800 &&
              store.selectedRestaurant.momo &&
              store.selectedRestaurant.momo !== "" && (
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 2,
                  }}
                  onPress={(e) => {
                    store.setMomoDialogVisible(true);
                  }}
                >
                  <Image
                    source={{
                      uri: store.getImageDirectory("/fooodvn/momo.png"),
                    }}
                    resizeMode={"contain"}
                    style={{
                      width: 32,
                      minHeight: 32,
                      tintColor: store.selectedRestaurant.primaryColor,
                    }}
                  />
                </TouchableOpacity>
              )}
            {store.selectedRestaurant.phone !== "" && (
              <TouchableOpacity
                style={{ marginHorizontal: 2 }}
                onPress={(e) => {
                  if (Platform.OS === "web")
                    window
                      .open("tel//:" + store.selectedRestaurant.phone, "_blank")
                      .focus();
                  else
                    Linking.openURL("tel//:" + store.selectedRestaurant.phone);
                }}
              >
                <MaterialIcons
                  name="phone"
                  size={32}
                  color={store.primaryColor}
                  style={
                    {
                      //marginBottom: 20,
                      // marginRight: 20,
                    }
                  }
                />
              </TouchableOpacity>
            )}
            {store.selectedRestaurant.facebook !== "" && (
              <TouchableOpacity
                style={{ marginHorizontal: 2 }}
                onPress={(e) => {
                  if (Platform.OS === "web")
                    window
                      .open(store.selectedRestaurant.facebook, "_blank")
                      .focus();
                  else
                    Linking.openURL(
                      "fb://facewebmodal/f?href=" +
                        store.selectedRestaurant.facebook
                    );
                }}
              >
                <MaterialCommunityIcons
                  name="facebook"
                  size={32}
                  color={store.primaryColor}
                  style={
                    {
                      //marginBottom: 20,
                      // marginRight: 20,
                    }
                  }
                />
              </TouchableOpacity>
            )}
            {store.selectedRestaurant.messenger !== "" && (
              <TouchableOpacity
                style={{ marginHorizontal: 2 }}
                onPress={(e) => {
                  if (Platform.OS === "web")
                    window
                      .open(store.selectedRestaurant.messenger, "_blank")
                      .focus();
                  else
                    Linking.openURL(
                      "fb://facewebmodal/f?href=" +
                        store.selectedRestaurant.messenger
                    );
                }}
              >
                <MaterialCommunityIcons
                  name="facebook-messenger"
                  size={32}
                  color={store.primaryColor}
                  style={
                    {
                      //marginBottom: 20,
                      // marginRight: 20,
                    }
                  }
                />
              </TouchableOpacity>
            )}
          </View>
        ) : (
          <View
            style={{
              height: 70,
              marginTop: Constants.statusBarHeight + 5,
              position: "absolute",
              right: 10,
              zIndex: 1000,
              flexDirection: "row",
            }}
          >
            {/* {store.showSearch ? (
                                                                                                              <View
                                                                                                                style={{
                                                                                                                  width: width * 0.5,
                                                                                                                  flex: 1,
                                                                                                                  justifyContent: "flex-end",
                                                                                                                  alignItems: "flex-end",
                                                                                                                  flexDirection: "row",
                                                                                                                  marginBottom: 10,
                                                                                                                }}
                                                                                                              >
                                                                                                                <View
                                                                                                                  style={{
                                                                                                                    width: store.screenWidth > 800 ? width * 0.3 : width * 0.7,
                                                                                                                    borderBottomColor: store.primaryColor,
                                                                                                                    borderBottomStyle: "solid",
                                                                                                                    borderBottomWidth: 1,
                                                                                                                    borderRadius: 5,
                                                                                                                    height: 50,
                                                                                                                    justifyContent: "center",
                                                                                                                    flexDirection: "row",
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <TouchableOpacity
                                                                                                                    onPress={(e) => {
                                                                                                                      RootNavigation.navigate("RestaurantsStack", {
                                                                                                                        screen: "Restaurants",
                                                                                                                        params: {
                                                                                                                          screen: "Restaurants",
                                                                                                                        },
                                                                                                                      });
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <MaterialIcons
                                                                                                                      name="search"
                                                                                                                      size={24}
                                                                                                                      color={store.primaryColor}
                                                                                                                      style={{ marginRight: 10, marginTop: 10 }}
                                                                                                                    />
                                                                                                                  </TouchableOpacity>
                                                                                                                  <TextInput
                                                                                                                    style={{
                                                                                                                      flex: 1,
                                                                                                                      paddingLeft: 0,
                                                                                                                      height: 50,
                                                                                                                      fontSize: 18,
                                                                                                                      paddingLeft: 10,
                                                                                                                      fontFamily: "Roboto_400Regular",
                                                                                                                      color: store.primaryColor,
                                                                                                                    }}
                                                                                                                    onSubmitEditing={(e) => {
                                                                                                                      RootNavigation.navigate("RestaurantsStack", {
                                                                                                                        screen: "Restaurants",
                                                                                                                        params: {
                                                                                                                          screen: "Restaurants",
                                                                                                                        },
                                                                                                                      });
                                                                                                                    }}
                                                                                                                    autoFocus={true}
                                                                                                                    defaultValue={store.search}
                                                                                                                    onChangeText={onChangeSearch}
                                                                                                                    placeholderTextColor={store.primaryColor}
                                                                                                                    placeholder={"Search"}
                                                                                                                  ></TextInput>
                                                                                                                </View>
                                                                                                                <TouchableOpacity
                                                                                                                  onPress={(e) => {
                                                                                                                    store.setShowSearch(false);
                                                                                                                    store.setSearch("");
                                                                                                                  }}
                                                                                                                  style={{
                                                                                                                    justifyContent: "center",
                                                                                                                    alignItems: "center",
                                                                                                                    marginRight: 15,
                                                                                                                    marginBottom: 15,
                                                                                                                    maxWidth: 70,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <MaterialCommunityIcons
                                                                                                                    name="window-close"
                                                                                                                    size={24}
                                                                                                                    color={store.primaryColor}
                                                                                                                  />

                                                                                                                </TouchableOpacity>
                                                                                                              </View>
                                                                                                            ) : (
                                                                                                              <View style={{ justifyContent: "center", alignItems: "center" }}>
                                                                                                                {store.screen !== "Restaurants" &&
                                                                                                                  store.screen !== "Restaurant" && (
                                                                                                                    <TouchableOpacity
                                                                                                                      onPress={(e) => {
                                                                                                                        store.setShowSearch(true);
                                                                                                                      }}
                                                                                                                      style={{
                                                                                                                        // padding: 18,
                                                                                                                        minHeight: 48,
                                                                                                                        justifyContent: "center",
                                                                                                                        alignItems: "center",
                                                                                                                        minWidth: 48,
                                                                                                                        maxWidth: 48,
                                                                                                                        // marginTop: -10,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <MaterialIcons
                                                                                                                        name="search"
                                                                                                                        size={32}
                                                                                                                        color={store.primaryColor}
                                                                                                                        style={
                                                                                                                          {
                                                                                                                            //marginBottom: 20,
                                                                                                                            // marginRight: 20,
                                                                                                                          }
                                                                                                                        }
                                                                                                                      />
                                                                                                                    </TouchableOpacity>
                                                                                                                  )}
                                                                                                              </View>
                                                                                                            )} */}
            {store.screen === "Restaurants" && (
              <TouchableOpacity
                onPress={(e) => {
                  store.setShowSideModal(!store.showSideModal);
                }}
                style={{ justifyContent: "center" }}
              >
                <FontAwesome
                  name="sliders"
                  size={24}
                  color={store.primaryColor}
                  style={{ marginRight: 10 }}
                />
                {/* <Fontisto
                                                                                                                                                  name="filter"
                                                                                                                                                  size={24}
                                                                                                                                                  color={store.primaryColor}
                                                                                                                                                  style={{ marginRight: 10 }}
                                                                                                                                                /> */}
              </TouchableOpacity>
            )}
          </View>
        )}
        {/* <Notifications className="icon" />
                                                          <Image
                                                            source={{
                                                              uri: "https://images.pexels.com/photos/6899260/pexels-photo-6899260.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
                                                            }}
                                                            style={{
                                                              width: "30px",
                                                              minHeight: "30px",
                                                              borderRadius: "5px",
                                                              objectFit: "cover",
                                                              cursor: "pointer",
                                                            }}
                                                          />
                                                          <View className="profile">
                                                            <ArrowDropDown className="icon" />
                                                            <View
                                                              className="options"
                                                              style={{
                                                                display: "none",
                                                                backgroundColor: "var(--main-color)",
                                                                borderRadius: "5px",
                                                              }}
                                                            >
                                                              <Text>Settings</Text>
                                                              <Text>Logout</Text>
                                                            </View>
                                                          </View> */}
      </View>
    </View>
  );
});
