import { types, getParent, flow, detach } from "mobx-state-tree";
import { values, toJS, set } from "mobx";
import moment from "moment";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import { getDatabase, ref, onValue, get } from "firebase/database";
// import json from "../assets/stores.json";
// import restJson from "../assets/restaurants.json";

export const OpenHours = types.model("OpenHours", {
  id: types.optional(types.identifier, generateId()),
  day: types.optional(types.string, ""),
  startTime: types.optional(types.string, "09:00"),
  endTime: types.optional(types.string, "21:30"),
});

export const DeliveryHours = types.model("DeliveryHours", {
  id: types.optional(types.identifier, generateId()),
  day: types.optional(types.string, ""),
  startTime: types.optional(types.string, "09:00"),
  endTime: types.optional(types.string, "21:30"),
});

export const DeliveryAreas = types.model("DeliveryAreas", {
  id: types.optional(types.identifier, generateId()),
  name: types.optional(types.string, ""),
  nameL2: types.optional(types.string, ""),
  cost: types.optional(types.number, 0),
  imageUrl: types.optional(types.string, ""),
  color: types.optional(types.string, "#ccc"),
  deliveryTime: types.optional(types.number, 30),
  minimumOrderAmount: types.optional(types.number, 0),
});

export const Review = types.model("Review", {
  id: types.optional(types.identifier, generateId()),
  name: types.optional(types.string, ""),
  posted: types.optional(types.string, ""),
  // date: types.optional(types.string, ""),
  date: types.maybe(types.Date),
  description: types.optional(types.string, ""),
  profile: types.optional(types.string, ""),
  rating: types.optional(types.number, 0),
});

export const Reel = types.model("Reel", {
  id: types.optional(types.identifier, generateId()),
  title: types.optional(types.string, ""),
  text: types.optional(types.string, ""),
  link: types.optional(types.string, ""),
  hasAudio: types.optional(types.boolean, true),
  likes: types.optional(types.number, 0),
  views: types.optional(types.number, 0),
  duration: types.optional(types.number, 0),
  date: types.maybe(types.Date),
  image: types.optional(types.string, ""),
  originalImage: types.optional(types.string, ""),
  thumbnail: types.optional(types.string, ""),
  video: types.optional(types.string, ""),
  originalVideo: types.optional(types.string, ""),
});

export const Post = types.model("Post", {
  id: types.optional(types.identifier, generateId()),
  title: types.optional(types.string, ""),
  text: types.optional(types.string, ""),
  link: types.optional(types.string, ""),
  hasAudio: types.optional(types.boolean, true),
  likes: types.optional(types.number, 0),
  views: types.optional(types.number, 0),
  duration: types.optional(types.number, 0),
  date: types.maybe(types.Date),
  image: types.optional(types.string, ""),
  originalImage: types.optional(types.string, ""),
  thumbnail: types.optional(types.string, ""),
  video: types.optional(types.string, ""),
  originalVideo: types.optional(types.string, ""),
});

export const Discount = types.model("Discount", {
  id: types.optional(types.identifier, generateId()),
  code: types.optional(types.string, ""),
  store: types.optional(types.string, ""),
  storeSlug: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
  order: types.optional(types.number, 0),
  amount: types.optional(types.string, ""),
  day: types.optional(types.string, ""),
  image: types.optional(types.string, ""),
  video: types.optional(types.string, ""),
});

// export const Cuisines = types.model("cuisines", {
//    id: types.optional(types.identifier, generateId()),
//   slug: types.optional(types.string, ""),
//   title: types.optional(types.string, ""),
// });

export const Restaurant = types
  .model("Restaurant", {
    id: types.optional(types.identifier, generateId()),
    slug: types.optional(types.string, ""),
    cuisines: types.optional(types.array(types.string), ["American"]), //types.optional(types.array(Cuisines), []),
    heroImageUrl: types.optional(types.string, ""),
    latestHeroImageUrl: types.optional(types.string, ""),
    videoUrl: types.optional(types.string, ""),
    latestVideoUrl: types.optional(types.string, ""),
    languages: types.optional(types.array(types.string), ["en", "vn"]),
    title: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    locationImage: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    openHours: types.optional(types.array(OpenHours), []),
    // discounts: types.optional(types.array(Discount), []),
    // discounts: types.map(Discount),
    deliveryHours: types.optional(types.array(DeliveryHours), []),
    deliveryAreas: types.optional(types.array(DeliveryAreas), []),
    reviews: types.optional(types.array(Review), []),
    reels: types.optional(types.array(Reel), []),

    posts: types.optional(types.array(Post), []),

    street: types.optional(types.string, ""),
    ward: types.optional(types.string, ""),
    suburb: types.optional(types.string, ""),
    post_code: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    region: types.optional(types.string, ""),
    country: types.optional(types.string, ""),
    country_code: types.optional(types.string, ""),
    label_address: types.optional(types.string, ""),
    form_address: types.optional(types.string, ""),
    gps: types.optional(types.array(types.number), [0, 0]),
    logoImageUrl: types.optional(types.string, ""),
    logoImageBG: types.optional(types.string, ""),
    latestLogoImageUrl: types.optional(types.string, ""),
    primaryColor: types.optional(types.string, ""),
    secondaryColor: types.optional(types.string, ""),
    backgroundColor: types.optional(types.string, ""),
    surfaceColor: types.optional(types.string, ""),
    textColor: types.optional(types.string, ""),
    mapImageUrl: types.optional(types.string, ""),
    minimumOrderAmount: types.optional(types.number, 100000),
    totalOrders: types.optional(types.number, 0),
    rating: types.optional(types.number, 0),
    noReviews: types.optional(types.number, 0),
    priceBucket: types.optional(types.string, "₫₫"),
    types: types.optional(types.array(types.string), ["American"]),
    facebook: types.optional(types.string, ""),
    instagram: types.optional(types.string, ""),
    paypal: types.optional(types.string, ""),
    momo: types.optional(types.string, ""),
    newWindow: types.optional(types.boolean, false),
    messenger: types.optional(types.string, ""),
    website: types.optional(types.string, ""),
    domains: types.optional(types.array(types.string), []),
    orderingLink: types.optional(types.string, ""),
    onlineOrdering: types.optional(types.boolean, false),
    placeId: types.optional(types.string, ""),
    categories: types.optional(types.array(types.string), ["American"]), //types.optional(types.array(Categories), []),
    order: types.optional(types.number, 100),
    featured: types.optional(types.boolean, false),
    premium: types.optional(types.boolean, false),
    distance: types.optional(types.number, 0),
    isAvailable: true,
    openTime: types.maybe(types.Date),
    closeTime: types.maybe(types.Date),
    showHeader: true,
    showLogo: false,
    isOpen: true,
    isDeliverable: true,
  })
  .views((self) => ({
    get restStore() {
      return getParent(self);
    },
    get getGPS() {
      return self.gps.toJSON();
    },
    get lat() {
      return self.gps.toJSON()[0];
    },
    get lng() {
      return self.gps.toJSON()[1];
    },
    get heroImage() {
      var returnImage = self.heroImageUrl;

      // if (self.latestHeroImageUrl !== "" && self.latestHeroImageUrl !== "")
      //   returnImage = self.latestHeroImageUrl;

      return returnImage;
    },
    get sortedPosts() {
      return values(self.posts).sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    },
    get sortedReels() {
      return values(self.reels).sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    },
    get sortedReviews() {
      return values(self.reviews).sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    },
    get logoImage() {
      var returnImage = self.logoImageUrl;

      // if (self.latestLogoImageUrl && self.latestLogoImageUrl !== "")
      // returnImage = self.latestLogoImageUrl;

      return returnImage;
    },
  }))
  .actions((self) => ({
    setFavs(slug = "") {
      var currentCuisines = self.cuisines;
      if (currentCuisines.includes(slug))
        currentCuisines = currentCuisines.filter((val, i) => val !== slug);
      else currentCuisines.push(slug);
      self.cuisines = currentCuisines;
    },
    updateDiscounts(json) {
      detach(self.discounts);
      self.discounts.clear();
      json.forEach((discountJson, i) => {
        self.discounts.put(discountJson);
      });
    },
    updateReviews(json) {
      detach(self.reviews);
      self.reviews.clear();
      json.forEach((reviewJson, i) => {
        self.reviews.put(reviewJson);
      });
    },
    updatePosts(json) {
      detach(self.posts);
      self.posts.clear();
      json.forEach((postJson, i) => {
        self.posts.put(postJson);
      });
    },
    afterCreate() {
      // console.log(getParent(self).isLoading);
      // var discs = getParent(self).store.searchDiscounts(self.slug);
      // if (discs.length > 0) console.log(toJS(discs));
      // self.updateDiscounts(toJS(discs));
    },
  }));

export const RestaurantStore = types
  .model("RestaurantStore", {
    isLoading: true,
    restaurants: types.map(Restaurant),
  })
  .views((self) => ({
    get store() {
      return getParent(self);
    },
    get sortedAvailableRestaurants() {
      return sortRestaurants(
        values(self.restaurants),
        self.store.search,
        self.store.location,
        self.store.locationType,
        self.store.categories.toJSON(),
        self.store.sortBy,
        false,
        false
      );
    },
    get sortedFeaturedRestaurants() {
      return sortRestaurants(
        values(self.restaurants),
        "",
        self.store.location,
        self.store.locationType,
        [],
        "default",
        true,
        true
      );
    },
    get sortedRandomAvailableRestaurants() {
      return self.store.shuffleArray(
        sortRestaurants(
          values(self.restaurants),
          self.store.search,
          self.store.location,
          self.store.locationType,
          self.store.categories.toJSON(),
          "default",
          false,
          true
        )
      );
    },
  }))
  .actions((self) => ({
    markLoading(loading) {
      self.isLoading = loading;
    },
    setShowHeader(showHeader = true) {
      self.showHeader = showHeader;
    },
    setShowLogo(showLogo = true) {
      self.showLogo = showLogo;
    },
    sortedRandomFeaturedRestaurants(numberToShow = 10) {
      return self.store.shuffleArray(
        sortRestaurants(
          values(self.restaurants),
          "",
          self.store.location,
          self.store.locationType,
          [],
          "default",
          true,
          true
        ).slice(0, numberToShow)
      );
    },
    updateRestaurantsDistance() {
      var gps = self.store.gps;
      values(self.restaurants).forEach((restaurant) => {
        var distance = self.store.calculateGPSDistance(
          gps[0],
          gps[1],
          restaurant.gps[0],
          restaurant.gps[1]
        );
        restaurant.distance = distance;
      });
    },
    updateRestaurantsAvailable() {
      var isAvailable = false;
      var now = new Date();
      var day = now.toLocaleString("en-us", { weekday: "long" });

      values(self.restaurants).forEach((restaurant) => {
        var hours = restaurant.openHours.toJSON().filter((a) => a.day === day);

        if (hours.length > 0) {
          hours = hours[0].toJSON();

          var s = new Date();
          var c = new Date();

          s.setHours(hours.startTime.split(":")[0]);
          s.setMinutes(hours.startTime.split(":")[1]);

          c.setHours(hours.endTime.split(":")[0]);
          c.setMinutes(hours.endTime.split(":")[1]);

          if (now > s && now < c) isAvailable = true;

          restaurant.isAvailable = isAvailable;
        }
      });
    },
    updateRestaurants(json) {
      self.clearRestaurants();
      values(self.restaurants).forEach(
        (restaurant) => (restaurant.isAvailable = false)
      );
      var posts = [];
      var reels = [];
      json.forEach((restaurantJson, i) => {
        // Adjust Customer Favourites
        if (
          store.favs.length > 0 &&
          restaurantJson.cuisines.includes("My Favs") &&
          !store.favs.includes(restaurantJson.slug)
        ) {
          store.removeFav(restaurantJson.slug);
          restaurantJson.cuisines = restaurantJson.cuisines.filter(
            (cus, i) => cus.slug != "my-favs"
          );
        } else if (
          store.favs.length > 0 &&
          store.favs.includes(restaurantJson.slug)
        ) {
          store.addFav(restaurantJson.slug);
          restaurantJson.cuisines.push("My Favs");
        }

        if (restaurantJson.posts)
          restaurantJson.posts = restaurantJson.posts.map((post) => {
            post.id = post.link.replace("https://www.instagram.com/p/", "");
            if (!post.title) post.title = "";
            if (!post.text) post.text = "";
            post.date = new Date(post.date.replace(/-/g, "/"));
            if (!post.originalImage) post.originalImage = "";
            return post;
          });

        if (restaurantJson.reels)
          restaurantJson.reels = restaurantJson.reels.map((reel) => {
            // reel.id = reel.link.replace("https://www.instagram.com/tv/", "");
            reel.id = reel.link.replace("https://www.instagram.com/p/", "");
            if (!reel.title) reel.title = "";
            if (!reel.text) reel.text = "";
            if (!reel.originalImage) reel.originalImage = "";
            // .toLowerCase()
            // .replace(/ /g, "-")
            // .replace(/[^\w-]+/g, "");
            // console.log(reel.date, new Date(moment(reel.date).format("YYYY-MM-DD")));
            // reel.date = new Date(
            //   moment(reel.date).format("YYYY-MM-DD").toString()
            // );
            reel.date = new Date(reel.date.replace(/-/g, "/"));

            return reel;
          });

        if (restaurantJson.reviews)
          restaurantJson.reviews = restaurantJson.reviews.map((review) => {
            review.date = new Date(review.date.replace(/-/g, "/"));
            return review;
          });

        if (!restaurantJson.id)
          restaurantJson.id = restaurantJson.title
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");

        if (restaurantJson.newWindow === "") restaurantJson.newWindow = false;

        if (restaurantJson.priceBucket === "")
          restaurantJson.priceBucket = "₫₫";

        restaurantJson.rating = restaurantJson.rating
          ? parseFloat(restaurantJson.rating)
          : 4;

        restaurantJson.noReviews = restaurantJson.noReviews
          ? parseInt(restaurantJson.noReviews)
          : 0;

        // console.log(restaurantJson);

        self.restaurants.put(restaurantJson);

        // Check if open now
        var isAvailable = false;
        var now = new Date();
        var day = now.toLocaleString("en-us", { weekday: "long" });
        var hours = self.restaurants
          .get(restaurantJson.id)
          .openHours.toJSON()
          .filter((a) => a.day === day);

        if (hours.length > 0) {
          hours = hours[0].toJSON();

          var s = new Date();
          var c = new Date();

          var startHours = hours.startTime.split(":")[0];
          var startMins = hours.startTime.split(":")[1];
          if (startMins)
            startMins = startMins.replace("am", "").replace("pm", "");
          else startMins = 0;
          if (isNaN(startMins)) startMins = 0;

          if (isNaN(startHours)) {
            if (startHours.indexOf("am"))
              startHours = parseInt(startHours.replace("am", ""));
            else if (startHours.indexOf("pm"))
              startHours = parseInt(startHours.replace("pm", "")) + 12;
          }

          s.setHours(parseInt(startHours));
          s.setMinutes(parseInt(startMins));

          c.setHours(hours.endTime.split(":")[0]);
          c.setMinutes(hours.endTime.split(":")[1]);

          // if c null set to 9pm
          if (c === null) {
            c = new Date();
            c.setHours(21);
            c.setMinutes(0);
          }
          if (s === null) {
            s = new Date();
            s.setHours(9);
            s.setMinutes(0);
          }
          if (s) self.restaurants.get(restaurantJson.id).openTime = s;
          if (c) self.restaurants.get(restaurantJson.id).closeTime = c;

          if (now > s && now < c) isAvailable = true;
        }

        self.restaurants.get(restaurantJson.id).isAvailable = isAvailable;

        if (
          restaurantJson.posts &&
          restaurantJson.posts.length > 0 &&
          restaurantJson.premium
        ) {
          restaurantJson.posts.map((post, index, array) => {
            var tempPost = {
              ...post,
              // ...self.restaurants.get(restaurantJson.id).sortedPosts[0].toJSON(),
            };
            tempPost["store"] = restaurantJson.id;
            tempPost["storeSlug"] = restaurantJson.slug;
            tempPost["logoImageUrl"] = restaurantJson.logoImageUrl;
            posts.push(tempPost);
          });
        }
        if (
          restaurantJson.reels &&
          restaurantJson.reels.length > 0 &&
          restaurantJson.premium
        ) {
          restaurantJson.reels.map((post, index, array) => {
            var tempPost = {
              ...post,
              // ...self.restaurants.get(restaurantJson.id).sortedPosts[0].toJSON(),
            };
            tempPost["store"] = restaurantJson.id;
            tempPost["storeSlug"] = restaurantJson.slug;
            tempPost["logoImageUrl"] = restaurantJson.logoImageUrl;
            reels.push(tempPost);
          });
        }
      });
      if (posts.length > 0) self.store.updatePosts(posts);
      if (reels.length > 0) self.store.updateReels(reels);
    },
    searchDomain(domain = "") {
      if (!self.restaurants || self.restaurants.size == 0) return false;
      var searchDomain = values(self.restaurants).filter((rest, i) => {
        if (!rest.domains || rest.domains.toJSON().length === 0) return false;
        else return rest.domains.toJSON().indexOf(domain) > -1;
      });
      if (!searchDomain) return false;
      else {
        self.store.setSelectedRestaurantId(searchDomain[0].toJSON().slug);
        return true;
      }
    },
    clearRestaurants() {
      detach(self.restaurants);
      self.restaurants.clear();
    },
    loadRestaurants() {
      try {
        const db = getDatabase();
        const reference = ref(
          db,
          self.store.domain.replaceAll(".", "_") + "/restaurants"
        );
        onValue(reference, (snapshot) => {
          // axios.get(self.store.sheetURL + "restaurants").then((json) => {
          self.updateRestaurants(snapshot.val());
          // self.updateRestaurants(JSON.parse(self.store.csvJSON(json.data)));
          self.store.updateLastUpdate();
          self.markLoading(false);
          self.store.markLoading(false);
        });
      } catch (err) {
        console.error("Failed to load restaurants ", err);
      }
    },
  }));

function sortRestaurants(
  restaurants,
  search = "",
  location = "",
  locationType = "delivers",
  categories = ["All"],
  sortBy = "default",
  featuredOnly = false,
  onlineOrdering = false
) {
  search = search ? search.toLowerCase() : "";
  var restaurantList = restaurants
    .filter((a) => {
      var return_value = false;
      if (featuredOnly) {
        return_value = a.featured;
        if (return_value && onlineOrdering) return_value = a.onlineOrdering;
      } else {
        return_value = true;
        if (search == "") return_value = true;
        else {
          return_value =
            a.title
              .toLowerCase()
              .replace("'", "")
              .indexOf(search.replace("'", "")) > -1 || search == "";

          //   if (!return_value) return_value = a.types.includes(search);
          if (!return_value)
            return_value =
              a.types.findIndex((element) => {
                return element.toLowerCase() === search.toLowerCase();
              }) > -1;
          //   if (!return_value) return_value = a.categories.includes(search);
          if (!return_value)
            return_value =
              a.categories.findIndex((element) => {
                return element.toLowerCase() === search.toLowerCase();
              }) > -1;
          //   if (!return_value) return_value = a.cuisines.includes(search);
          if (!return_value)
            return_value =
              a.cuisines.findIndex((element) => {
                return element.toLowerCase() === search.toLowerCase();
              }) > -1;
        }

        if (return_value) {
          if (locationType === "located") {
            return_value = a.ward === location || a.suburb === location;
          } else {
            if (location === "Thao Dien" || location === "An Phu")
              location = ["Thao Dien", "An Phu", "District 2"];
            return_value =
              a.deliveryAreas.filter(
                (da) => location.includes(da.name) || da.name === location
              ).length > 0;
          }
          if (return_value && onlineOrdering) return_value = a.onlineOrdering;
        }
      }

      return return_value;
    })
    .filter(
      (b) =>
        categories.length === 0 ||
        categories.indexOf("All") > -1 ||
        b.cuisines.some((item) => categories.includes(item))
    );

  if (sortBy === "rating") {
    return (
      restaurantList
        .sort((a, b) => (a.title - b.title ? 1 : a.title === b.title ? 0 : -1))
        .sort((a, b) => parseInt(a.order) - parseInt(b.order))
        // .sort((a, b) => parseInt(a.order) - parseInt(b.order) ? 1 : a.order === b.order ? 0 : -1)
        .sort((a, b) => (a.featured === b.featured ? 0 : a.featured ? -1 : 1))
        .sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating))
        .sort((a, b) =>
          a.isAvailable === b.isAvailable ? 0 : a.isAvailable ? -1 : 1
        )
    );
  } else if (sortBy === "distance") {
    return (
      restaurantList
        .sort((a, b) => parseInt(a.order) - parseInt(b.order))
        .sort((a, b) => (a.featured === b.featured ? 0 : a.featured ? -1 : 1))
        .sort((a, b) =>
          a.onlineOrdering === b.onlineOrdering ? 0 : a.onlineOrdering ? -1 : 1
        )
        // .sort((a, b) => (a.newWindow === b.newWindow ? 0 : a.newWindow ? 1 : -1))
        .sort((a, b) => parseInt(b.distance) - parseInt(a.distance))
        .sort((a, b) =>
          a.isAvailable === b.isAvailable ? 0 : a.isAvailable ? -1 : 1
        )
    );
  } else if (sortBy === "price") {
    return (
      restaurantList
        .sort((a, b) => parseInt(a.order) - parseInt(b.order))
        .sort((a, b) => (a.featured === b.featured ? 0 : a.featured ? -1 : 1))
        .sort((a, b) =>
          a.onlineOrdering === b.onlineOrdering ? 0 : a.onlineOrdering ? -1 : 1
        )
        .sort(
          (a, b) =>
            parseInt(a.priceBucket.length) - parseInt(b.priceBucket.length)
        )
        // .sort((a, b) =>
        // a.newWindow === b.newWindow ? 0 : a.newWindow ? 1 : -1
        // )
        .sort((a, b) =>
          a.isAvailable === b.isAvailable ? 0 : a.isAvailable ? -1 : 1
        )
    );
  } else {
    return restaurantList
      .sort((a, b) => (a.title - b.title ? 1 : a.title === b.title ? 0 : -1))
      .sort((a, b) => parseInt(a.order) - parseInt(b.order))
      .sort((a, b) => (a.featured === b.featured ? 0 : a.featured ? -1 : 1))
      .sort((a, b) =>
        a.onlineOrdering === b.onlineOrdering ? 0 : a.onlineOrdering ? -1 : 1
      )
      .sort((a, b) => (a.newWindow === b.newWindow ? 0 : a.newWindow ? 1 : -1))
      .sort((a, b) =>
        a.isAvailable === b.isAvailable ? 0 : a.isAvailable ? -1 : 1
      );
  }
}

function generateId() {
  return uuidv4();
}
