import {
  StyleSheet,
  Button,
  Platform,
  Image,
  ImageBackground,
} from "react-native";
import { useState, useEffect, useRef } from "react";

import { Text, View } from "../components/Themed";
import { RootTabScreenProps } from "../types";

import Constants from "expo-constants";
import { Video } from "expo-av";
import Swiper from "react-native-swiper/src";
import { TouchableOpacity } from "react-native-gesture-handler";
import * as RootNavigation from "../navigation/RootNavigation";

export function SplashScreen({ navigation }: RootTabScreenProps<"Splash">) {
  useEffect(() => {
    if (store.splashShown)
      setTimeout(() => {
        RootNavigation.navigate("Tabs", {
          screen: "HomeStack",
          params: {
            screen: "Home",
          },
        });
      }, 500);
    // setTimeout(() => {
    //   store.setSplashShown(true);
    //   RootNavigation.navigate("Tabs", {
    //     screen: "HomeStack",
    //     params: {
    //       screen: "Home",
    //     },
    //   });
    // }, 6000);
  }, []);

  return (
    <TouchableOpacity
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        minHeight: store.screenHeight,
      }}
      onPress={(e) => {
        RootNavigation.navigate("Tabs", {
          screen: "HomeStack",
          params: {
            screen: "Home",
          },
        });
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: store.backgroundColor,
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          // zIndex: -1,
        }}
      >
        {Platform.OS !== "web" ? (
          <Video
            source={{
              uri: store.getImageDirectory(store.splashVideo),
            }}
            isLooping
            isMuted
            positionMillis={500}
            resizeMode="cover"
            shouldPlay
            style={{
              flex: 1,
              minWidth: store.screenWidth,
              minHeight: store.screenHeight,
            }}
          />
        ) : (
          <video
            height="100%"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              zIndex: 1,
            }}
            controls={false}
            autoPlay
            loop
            muted
            playsInline
          >
            <source
              src={store.getImageDirectory(
                store.screenWidth > 800
                  ? store.splashVideo.replace(".mp4", "_desktop.mp4")
                  : store.splashVideo
              )}
              type="video/mp4"
            />
          </video>
        )}
      </View>
      <View
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%",
          zIndex: 2,
          flex: 1,
          backgroundColor:
            "rgba(0,0,0, " + parseFloat(store.splashBackgroundOpacity) + ")",
          justifyContent: "center",
          alignItems: "center",
          padding: 30,
        }}
      >
        {/* <Image
          source={{
            uri: store.logoImageUrl,
          }}
          resizeMode="contain"
          style={{
            width: 250,
            height: 150,
            resizeMode: "contain",
            tintColor: "#fff",
          }}
        /> */}
        <View>
          <Text
            style={{
              color: store.splashTextColor,
              fontSize: 42,
              fontFamily: store.font,
              fontWeight: "bold",
              alignSelf: "center",
              textAlign: "center",
              zIndex: 3,
            }}
          >
            {store.splashTitle && store.splashTitle !== "" && store.splashTitle}
          </Text>
        </View>

        <Text
          style={{
            color: store.splashTextColor,
            fontSize: 24,
            fontFamily: store.font,
            fontWeight: "bold",
            alignSelf: "center",
            textAlign: "center",
            zIndex: 3,
          }}
        >
          {store.splashSubtitle &&
            store.splashSubtitle !== "" &&
            store.splashSubtitle}
        </Text>
      </View>
    </TouchableOpacity>
  );
}
