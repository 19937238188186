// Formik x React Native example
import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  TextInput,
  View,
  Text,
  StyleSheet,
  Image,
  ImageBackground,
} from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { observer } from "mobx-react";
import Swiper from "react-native-deck-swiper";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import * as Linking from "expo-linking";
import * as RootNavigation from "../navigation/RootNavigation";

import { CategoriesScroll } from "../components/RestaurantComponents";

export const WhatsForDinnerScreen = observer(({ route, navigation }) => {
  var itemWidth = store.screenWidth > 800 ? 500 : store.screenWidth - 50;
  var itemHeight = itemWidth * 0.85;
  // useEffect(() => {
  //   if (store.getSlides[0].backgroundVideo !== "") videoRef.current.play();
  // }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", function (e) {
      switch (e.keyCode) {
        case 37:
          handleOnSwipedLeft();
          break;
        case 38:
          handleOnSwipedTop();
          break;
        case 39:
          handleOnSwipedRight();
          break;
        case 40:
          handleOnSwipedBottom();
          break;
      }
    });
  }, []);

  const delayTime = 1150;
  const [activeVideo, setActiveVideo] = useState(null);
  const [hasCards, setHasCards] = useState(true);
  const [choosing, setChoosing] = useState(false);
  const [meal, setMeal] = useState("Dinner");
  const [count, setCount] = useState(5);
  const [counter, setCounter] = useState(1);
  const [totalAvailable, setTotalAvailable] = useState(
    store.sortedRandomAvailableRestaurants.length + 1
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const useSwiper = useRef(null);

  useEffect(() => {
    setCurrentIndex(1);
    setTotalAvailable(store.sortedRandomAvailableRestaurants.length + 1);
    setCount(totalAvailable > 5 ? 5 : totalAvailable - 1);
  }, [store.categories.toString()]);

  const handleOnSwipedLeft = () => useSwiper.current.swipeLeft();
  const handleOnSwipedTop = () => useSwiper.current.swipeTop();
  const handleOnSwipedRight = () => useSwiper.current.swipeRight();
  const handleOnSwipedBottom = () => useSwiper.current.swipeBottom();

  const renderCard = (card, index) => {
    const item = card;
    setCounter(parseInt(counter) + 1);
    setActiveVideo(item.videoUrl);

    var cost = item.deliveryAreas.filter((da) => da.name === store.location);
    if (cost.length > 0) cost = cost[0].cost;
    else cost = -1;

    return (
      <TouchableOpacity
        style={{
          width: itemWidth,
          height: itemHeight,
          borderWidth: 2,
          backgroundColor: "#fff",
          borderColor: "#fff", // store.primaryColor,
          width: itemWidth,
          height: itemHeight,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 15,
          margin: 5,
          // padding: 1,
          opacity: 1,
          // opacity: !item.isAvailable ? 0.4 : 1,
        }}
        activeOpacity={1}
        onPress={(e) => {
          // RootNavigation.navigate("Restaurant", {
          if (item.orderingLink !== "")
            RootNavigation.navigate("Tabs", {
              screen: "RestaurantsStack",
              params: {
                screen: "Restaurant",
                params: { id: item.id },
              },
            });
        }}
      >
        <ImageBackground
          style={{
            width: "100%", //itemWidth,
            height: itemHeight - 4,
            borderRadius: 15,
            justifyContent: "flex-end",
          }}
          imageStyle={{
            borderRadius: 15,
            resizeMode: "cover",
            alignSelf: "flex-start",
          }}
          resizeMode="cover"
          source={{ uri: store.getImageDirectory(item.heroImage) }}
        >
          {item.videoUrl && item.videoUrl !== "" && index === currentIndex && (
            <video
              // paused={index !== currentIndex}
              poster={store.getImageDirectory(item.heroImage)}
              height="100%"
              style={{
                position: "absolute",
                objectFit: "cover",
                width: "100%",
                height: "100%",
                zIndex: 0,
                borderRadius: 15,
              }}
              controls={false}
              autoPlay //={index === currentIndex}
              loop
              muted
              playsInline
            >
              <source
                src={store.getImageDirectory(item.videoUrl)}
                type="video/mp4"
              />
            </video>
          )}
          <View
            style={{
              flex: 1,
              backgroundColor: "rgba(0,0,0, 0.25)",
              borderRadius: 15,
              justifyContent: "flex-end",
              position: "relative",
              zIndex: 1,
            }}
          >
            {item.showLogo && (
              <View
                style={{
                  position: "absolute",
                  top: 5,
                  left: 5,
                }}
              >
                <TouchableOpacity
                  onPress={(e) => {
                    // RootNavigation.navigate("Restaurant", {
                    if (item.orderingLink !== "")
                      RootNavigation.navigate("Tabs", {
                        screen: "RestaurantsStack",
                        params: {
                          screen: "Restaurant",
                          params: { id: item.id },
                        },
                      });
                  }}
                >
                  <Image
                    source={{ uri: store.getImageDirectory(item.logoImageUrl) }}
                    style={{
                      width: "100%",
                      maxWidth: 100,
                      height: 80,
                      // borderRadius: 25,
                    }}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
            )}
            <View
              style={{
                borderRadius: 15,
                backgroundColor: "#efefef",
                padding: 5,
                position: "absolute",
                top: 7,
                right: 5,
              }}
            >
              <Text
                style={{
                  fontWeight: "100",
                  fontSize: 10,
                  fontFamily: "Roboto_400Regular",
                  color: store.hexToRGB("#000", 1),
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                numberOfLines={1}
              >
                {item.suburb}
              </Text>
            </View>
            <TouchableOpacity
              activeOpacity={1.0}
              onPress={(e) => {
                // RootNavigation.navigate("Restaurant", {
                if (item.orderingLink !== "" && item.newWindow) {
                  if (Platform.OS === "web")
                    window.open(item.orderingLink, "_blank").focus();
                  else Linking.openURL(item.orderingLink);
                } else if (item.orderingLink)
                  RootNavigation.navigate("Tabs", {
                    screen: "RestaurantsStack",
                    params: {
                      screen: "Restaurant",
                      params: { id: item.id },
                    },
                  });
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  marginTop: 2,
                  padding: 4,
                  marginBottom: 10,
                  marginLeft: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    // minHeight: 25,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 32,
                      fontFamily: store.font,
                      color: "#fff",
                    }}
                    numberOfLines={1}
                  >
                    {item.title}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <MaterialCommunityIcons name="star" size={18} color="#fff" />
                  <Text
                    style={{
                      marginLeft: 2,
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    {item.rating}
                  </Text>
                  <Text
                    style={{
                      marginLeft: 2,
                      fontSize: 14,
                      marginTop: 0,
                      color: "#fff",
                    }}
                  >
                    {"(" + item.noReviews + " Ratings)"}
                  </Text>
                  <Text
                    style={{
                      marginLeft: 5,
                      fontSize: 16,
                      marginTop: 0,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    {item.priceBucket}
                  </Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Roboto_400Regular",
                      color: "#fff",
                      width: "100%",
                      marginTop: 2,
                      marginBottom: 0,
                    }}
                    numberOfLines={2}
                  >
                    {item.cuisines.toString()}
                  </Text>

                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Roboto_400Regular",
                      color: store.hexToRGB("#fff", 0.7),
                      flex: 1,
                      marginTop: 5,
                    }}
                    numberOfLines={3}
                  >
                    {store.location}
                    {" - "}
                    {cost === -1
                      ? "Delivery not available"
                      : cost === 0
                      ? "Free Delivery"
                      : cost / 1000 + "k"}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </ImageBackground>
      </TouchableOpacity>
    );
  };

  const changeCard = () => {
    if (choosing && hasCards) {
      var ind = Math.floor(Math.random() * 3);
      if (ind === 0) useSwiper.current.swipeLeft();
      else if (ind === 1) useSwiper.current.swipeRight();
      else if (ind === 2) useSwiper.current.swipeTop();
      else useSwiper.current.swipeBottom();

      if (count <= 0) {
        handleChoose(false);
        setCount(totalAvailable > 5 ? 5 : totalAvailable - 1);
      } else if (count >= 1) {
        setCount((prev) => (prev - 1 > 0 ? prev - 1 : 0));
      }
    }
  };

  const handleChoose = (value = false) => {
    setChoosing(value);
  };

  const lastInterval = useRef();

  useEffect(() => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 11) {
      setMeal("Breakfast");
    } else if (curHr < 16) {
      setMeal("Lunch");
    } else {
      setMeal("Dinner");
    }
  }, []);

  useEffect(() => {
    if (lastInterval.current) clearInterval(lastInterval.current);
    if (open) {
      lastInterval.current = setInterval(() => {
        changeCard();
      }, delayTime);
    }
  }, [choosing]);

  // useEffect(() => {
  //   setInterval(() => {
  //     if (choosing && hasCards) {
  //       console.log("choosing:", choosing);
  //       changeCard();
  //     }
  //   }, 2000);
  // }, [choosing, hasCards]);

  var swiperRef = useRef();

  return (
    <View style={{ flex: 1, backgroundColor: store.primaryColor }}>
      <View style={{ flex: 1, marginTop: 100, justifyContent: "flex-start" }}>
        <Text
          style={{
            fontSize: 32,
            color: store.surfaceColor,
            fontFamily: store.font,
            margin: 20,
          }}
        >
          Whats For {meal}
        </Text>
        <CategoriesScroll disableNav={true} color={store.primaryColor} />
        <View
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={{
            maxHeight: itemHeight + 100,
            marginTop: store.screenWidth > 800 ? -20 : -20,
            marginBottom: store.screenWidth > 800 ? 20 : 20,
          }}
          contentContainerStyle={{
            // flex: 1,

            justifyContent: "center",
            alignItems: "center ",
            maxHeight: itemHeight,
          }}
        >
          <Swiper
            key={store.categories.toString()}
            ref={useSwiper}
            // goBackToPreviousCardOnSwipeLeft={true}
            // animateCardOpacity
            cardIndex={0}
            // swipeBackCard={true}
            // infinite
            showSecondCard
            // animateOverlayLabelsOpacity
            cards={store.sortedRandomAvailableRestaurants}
            // cards={["DO", "MORE", "OF", "WHAT", "MAKES", "YOU", "HAPPY"]}
            renderCard={renderCard}
            onSwiped={(cardIndex) => {
              setCurrentIndex((prev) => parseInt(cardIndex) + 1);

              if (cardIndex === totalAvailable - 1) setHasCards(false);
            }}
            onSwipedAll={() => {
              setHasCards(false);
            }}
            backgroundColor={store.primaryColor}
            stackSize={3}
          ></Swiper>
        </View>
      </View>
      {/* <View style={{ width: store.screenWidth - 50, flexDirection: "row" }}> */}
      <TouchableOpacity
        style={{
          backgroundColor: store.surfaceColor,
          borderRadius: 15,
          padding: 5,
          justifyContent: "center",
          alignItems: "center",
          minHeight: 55,
          width: "70%",
          marginLeft: "15%",
          marginTop: store.screenWidth > 800 ? 20 : 0,
          marginBottom: store.screenWidth > 800 ? 20 : 0,
        }}
        onPress={(e) => {
          setCount(totalAvailable > 5 ? 5 : totalAvailable - 1);
          if (!choosing) {
            setChoosing(true);
          } else {
            setChoosing(false);
          }
          setTimeout(() => {
            setChoosing(false);
          }, 5 * delayTime);
          // chooseForMe(choosing);
        }}
      >
        <Text
          style={{
            color: store.primaryColor,
            fontSize: 18,
            fontFamily: store.font,
          }}
        >
          {choosing
            ? "Choosing      (" + count + ")"
            : "I'm Feeling Luck!    (" + (totalAvailable - currentIndex) + ")"}
        </Text>
      </TouchableOpacity>
    </View>
  );
});
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F5FCFF",
  },
  card: {
    flex: 1,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: "#E8E8E8",
    justifyContent: "center",
    backgroundColor: "white",
  },
  text: {
    textAlign: "center",
    fontSize: 50,
    backgroundColor: "transparent",
  },
});
