import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  StyleSheet,
  FlatList,
  Platform,
  View,
  Text,
  Image,
  ImageBackground,
  ScrollView,
  SafeAreaView,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Animated,
} from "react-native";
import LottieView from "lottie-react-native";
import * as Linking from "expo-linking";

// import MapView from "react-native-maps";

// import Animated from "react-native-reanimated";

import RNPickerSelect from "react-native-picker-select";
import {
  AntDesign,
  Octicons,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  Zocial,
  Fontisto,
} from "@expo/vector-icons";

import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

import { StatusBar } from "expo-status-bar";

import { inject, observer, Observer } from "mobx-react";

// import { TouchableOpacity } from "react-native-gesture-handler";

import * as RootNavigation from "../navigation/RootNavigation";

import RefreshControl from "../utils/refreshcontrol";

import {
  CategoriesScroll,
  SortModal,
  CategoriesPanel,
  SelectLocation,
  SelectSort,
  SearchBar,
  FlickDisplay,
  ReviewDialog,
  RestaurantMap,
} from "../components/RestaurantComponents";
import { transparent } from "react-native-paper/lib/typescript/styles/colors";

const { diffClamp } = Animated;
const headerHeight = 100 * 2; //58 * 2;

export const RestaurantsScreen = observer(({ route, navigation }) => {
  const [restaurantList, setRestaurantList] = useState([]);
  const [noPosts, setNoPosts] = useState(5);
  const [noItems, setNoItems] = useState(0);
  const [showTotalOrders, setShowTotalOrders] = useState(false);

  useEffect(() => {
    store.restaurantStore.updateRestaurantsAvailable();
  }, []);

  // useEffect(() => {
  //   console.log("clear");
  //   setNoItems(0);
  //   updateRestaurantList();
  // }, [store.search, store.categories.toString(), store.sortBy.toString()]);

  const updateRestaurantList = () => {
    // console.log("updating updateRestaurantList");
    // var tempPosts = noItems > 0 ? restaurantList : [];
    // var tempPostsNew = store.getSortedAvailableRestaurants(
    //   noItems,
    //   restaurantList.length + noPosts
    // );
    // console.log(noItems, restaurantList.length + noPosts);
    // tempPosts = tempPosts.concat(tempPostsNew);
    // setNoPosts((prev) => noItems);
    // setNoItems((prev) => prev + 20);
    // setRestaurantList((prev) => tempPosts);
  };

  useEffect(() => {
    if (route && route.params) {
      if (route.params.category && route.params.category !== "all") {
        store.setCategories([route.params.category.replace("%20", " ")]);
      }
      if (route.params.search && route.params.search !== "")
        store.setSearch(route.params.search);
      if (route.params.sort && route.params.sort !== "")
        store.setSortBy(route.params.sort);
    }
    updateRestaurantList();
  }, [route.params]);

  const [numColumns, setNumColumns] = useState(
    Math.floor(store.screenWidth / 300)
  );

  var itemWidth =
    store.sortedAvailableRestaurants.length === 1
      ? store.screenWidth - 10
      : store.screenWidth / numColumns - 10;
  var itemHeight = 250;

  const Header = (props) => {
    return (
      <View style={{ padding: 2, justifyContent: "flex-start" }}>
        <View
          style={{
            width: "100%",
            minHeight: 30,
            maxHeight: 30,
            justifyContent: "center",
            margin: 5,
            flexDirection: "row",
          }}
        >
          <View style={{ flex: 0.4, alignItems: "flex-start" }}>
            <SelectLocation />
          </View>
          <View style={{ flex: 0.2 }}></View>
          <View style={{ flex: 0.4, alignItems: "flex-end" }}>
            <SelectSort
              showButtons={true}
              setShowTotalOrders={props.setShowTotalOrders}
              showTotalOrders={props.showTotalOrders}
            />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            minHeight: 75,
            maxHeight: 75,
            justifyContent: "center",
            margin: 5,
          }}
        >
          <CategoriesScroll />
        </View>
        <View
          style={{
            width: "100%",
            minHeight: 60,
            maxHeight: 60,
            justifyContent: "center",
            margin: 2,
          }}
        >
          <SearchBar />
        </View>
      </View>
    );
  };

  const ref = useRef(null);
  const scrollY = useRef(new Animated.Value(0));
  const scrollYClamped = diffClamp(scrollY.current, 0, headerHeight);
  const translateY = scrollYClamped.interpolate({
    inputRange: [0, headerHeight],
    outputRange: [0, -(headerHeight / 1.5)],
  });

  const translateYNumber = useRef();

  translateY.addListener(({ value }) => {
    translateYNumber.current = value;
  });

  const handleScroll = Animated.event(
    [
      {
        nativeEvent: {
          contentOffset: { y: scrollY.current },
        },
      },
    ],
    {
      useNativeDriver: true,
    }
  );

  const handleSnap = ({ nativeEvent }) => {
    const offsetY = nativeEvent.contentOffset.y;
    if (
      !(
        translateYNumber.current === 0 ||
        translateYNumber.current === -headerHeight / 2
      )
    ) {
      if (ref.current) {
        ref.current.scrollToOffset({
          offset:
            getCloser(translateYNumber.current, -headerHeight / 2, 0) ===
            -headerHeight / 2
              ? offsetY + headerHeight / 2
              : offsetY - headerHeight / 2,
        });
      }
    }
  };

  const getItemLayout = (data, index) => ({
    length: itemHeight,
    offset: itemHeight * data.length,
    index,
  });

  const renderItem = ({ item, index }) => (
    <RenderedItem
      key={index}
      item={item}
      index={index}
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      showTotalOrders={showTotalOrders}
    />
  );
  const keyExtractor = (item, index) => item.id + "_" + index;

  const preparedData = store.sortedAvailableRestaurants.map((item) => {
    var cost = item.deliveryAreas.filter((da) => da.name === store.location);
    if (cost.length > 0) cost = cost[0].cost;
    else cost = -1;

    var closingIn = 0;
    var opensAt = 0;
    var now = new Date();
    closingIn = Math.round((item.closeTime - now) / 1000 / 60);
    // console.log(now, item.openTime);
    if (!item.isAvailable && now < item.openTime)
      opensAt = item.openTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

    return {
      item: item,
      cost: cost,
      closingIn: closingIn,
      opensAt: opensAt,
    };
  });

  // const ViewTypes = {
  //   FULL: 0,
  //   HALF_LEFT: 1,
  //   HALF_RIGHT: 2,
  // };

  // let containerCount = 0;
  // const layoutProvider = new LayoutProvider(
  //   () => 0,
  //   (type, dim) => {
  //     dim.width = itemWidth;
  //     dim.height = itemHeight;
  //   }
  // );

  // const dataProvider = new DataProvider((r1, r2) => {
  //   return r1 !== r2;
  // }).cloneWithRows(store.sortedAvailableRestaurants);

  return (
    <SafeAreaView style={styles.container}>
      <ReviewDialog restaurant={store.selectedRestaurant} />
      <StatusBar backgroundColor="#1c1c1c" style="light" />
      <Animated.View
        style={[
          styles.header,
          { backgroundColor: store.surfaceColor },
          { transform: [{ translateY }] },
        ]}
      >
        <Header
          {...{ headerHeight }}
          showTotalOrders={showTotalOrders}
          setShowTotalOrders={setShowTotalOrders}
        />
      </Animated.View>
      {store.restaurantsDisplay === "map" ? (
        <View
          style={{
            flex: 1,
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
          key={store.categories.toString()}
        >
          <RestaurantMap
            itemWidth={
              store.screenWidth > 800
                ? parseInt(store.screenHeight - 270)
                : parseInt(store.screenWidth * 0.65)
            }
            data={preparedData}
          />
        </View>
      ) : store.restaurantsDisplay === "flick" ? (
        <View
          showsHorizontalScrollIndicator={false}
          style={{
            flex: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: store.screenWidth > 800 ? 180 : 10,
          }}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ justifyContent: "center" }}
        >
          <View
            style={{
              width:
                store.screenWidth > 800
                  ? parseInt(store.screenHeight - 280)
                  : parseInt(store.screenWidth * 0.9),
              height:
                store.screenWidth > 800
                  ? parseInt(store.screenHeight - 200)
                  : parseInt(store.screenWidth * 0.9),
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FlickDisplay
              itemWidth={
                store.screenWidth > 800
                  ? parseInt(store.screenHeight - 270)
                  : parseInt(store.screenWidth * 0.9)
              }
              data={preparedData}
            />
          </View>
        </View>
      ) : (
        <Animated.FlatList
          disableVirtualization
          removeClippedSubviews={Platform.OS !== "web"}
          useNativeDriver={true}
          key={
            itemWidth.toString() +
            store.sortBy.toString() +
            store.search +
            store.categories.toString()
          }
          numColumns={Math.round(store.screenWidth / itemWidth)}
          // getItemLayout={getItemLayout}
          initialNumToRender={store.screenWidth > 800 ? 12 : 10}
          maxToRenderPerBatch={store.screenWidth > 800 ? 12 : 10}
          windowSize={10}
          scrollEventThrottle={16}
          contentContainerStyle={{
            paddingTop: headerHeight,
            justifyContent: "flex-start",
          }}
          onScroll={handleScroll}
          ref={ref}
          // refreshing={store.isLoading}
          // onRefresh={(e) => {
          //   store.clearReload();
          // }}
          refreshControl={
            <RefreshControl
              refreshing={store.isLoading}
              onRefresh={(e) => {
                store.clearReload();
              }}
            />
          }
          onMomentumScrollEnd={handleSnap}
          data={preparedData}
          renderItem={renderItem}
          ItemSeparatorComponent={RenderSeparator}
          keyExtractor={keyExtractor}
          // onEndReachedThreshold={0.7}
          onEndReachedThreshold={1}
          onEndReached={(e) => {
            // console.log("End reached");
            // updateRestaurantList();
          }}
        />
      )}
      <SortModal />
    </SafeAreaView>
  );
});

class RenderedItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fav: props.item.item.cuisines.includes("My Favs"),
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  onLogoPress = (item) => {
    if (item.orderingLink !== "") {
      if (item.newWindow) window.open(item.orderingLink, "_blank").focus();
      else
        RootNavigation.navigate("Tabs", {
          screen: "RestaurantsStack",
          params: {
            screen: "Restaurant",
            params: { id: item.id },
          },
        });
    } else if (Platform.OS === "web")
      if (store.screenWidth > 800)
        window.open(item.messenger, "_blank").focus();
      else window.open(item.messenger);
    else Linking.openURL("fb://facewebmodal/f?href=" + item.messenger);
  };

  onPress = (item) => {
    if (item.orderingLink !== "") {
      if (item.newWindow) window.open(item.orderingLink, "_blank").focus();
      else
        RootNavigation.navigate("Tabs", {
          screen: "RestaurantsStack",
          params: {
            screen: "Restaurant",
            params: { id: item.id },
          },
        });
    } else if (Platform.OS === "web") window.open(item.messenger);
    else Linking.openURL("fb://facewebmodal/f?href=" + item.messenger);
  };

  render() {
    var { item, index, itemWidth, itemHeight, showTotalOrders } = this.props;
    var { item, cost, opensAt, closingIn } = item;
    const bgColor = item.onlineOrdering ? "#fff" : "#efefef";
    const borderColor = store.backgroundColor;
    const opacity = !item.isAvailable ? 0.4 : 1;
    return (
      <View
        key={index}
        style={{
          width: itemWidth,
          height: itemHeight,
          borderWidth: 0,
          backgroundColor: bgColor,
          borderColor: borderColor,
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: 10,
          margin: 5,
          padding: 1,
          opacity: 1,

          // opacity: !item.isAvailable ? 0.4 : 1,
        }}
      >
        {opensAt !== 0 && (
          <View
            style={{
              borderRadius: 15,
              // backgroundColor: "#fff",
              padding: 5,
              marginLeft: "auto",
              marginRight: " auto",
              left: 0,
              right: 0,
              position: "absolute",
              alignSelf: "center",
              top: "25%",
              // left: "25%",
            }}
          >
            <Text
              style={{
                borderRadius: 15,
                backgroundColor: "#000",
                padding: 5,
                fontWeight: "100",
                fontSize: 14,
                fontFamily: store.font,
                color: "#fff",
                alignSelf: "center",
                textAlign: "center",
                // marginTop: -20,
              }}
              numberOfLines={1}
            >
              {" Opens at " + opensAt + " "}
            </Text>
          </View>
        )}
        <TouchableOpacity
          activeOpacity={1.0}
          onPress={(e) => {
            this.onPress(item);
          }}
          style={{
            width: itemWidth,
            borderWidth: 0,
            backgroundColor: bgColor,
            borderColor: borderColor,
            justifyContent: "flex-start",
            alignItems: "center",
            opacity: opacity,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <ImageBackground
            style={{
              width: "100%", //itemWidth,
              height: itemHeight * 0.6,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            imageStyle={{
              resizeMode: "cover",
              alignSelf: "flex-start",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            resizeMode="cover"
            source={{
              uri: store.getImageDirectory(item.heroImage),
            }}
          >
            <View
              style={{
                flex: 1,
                backgroundColor:
                  item.orderingLink !== "" && item.newWindow
                    ? store.hexToRGB(item.backgroundColor, 0.7)
                    : item.orderingLink !== ""
                    ? "rgba(0,0,0, 0.1)"
                    : "rgba(2, 132, 255, 0.8)",
              }}
            >
              {!!item.showLogo && (
                <TouchableOpacity
                  activeOpacity={1.0}
                  onPress={(e) => {
                    // RootNavigation.navigate("Restaurant", {
                    this.onLogoPress(item);
                  }}
                >
                  <View
                    style={{
                      width: 70,
                      height: 70,
                      borderRadius: 100,
                      backgroundColor:
                        item.logoImageBG !== ""
                          ? item.logoImageBG
                          : "transparent",
                      margin: 10,
                    }}
                  >
                    <Image
                      source={store.getImageDirectory(item.logoImageUrl)}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "cover",
                        borderRadius: 100,
                      }}
                      resizeMode="contain"
                    />
                  </View>
                </TouchableOpacity>
              )}
              {!!item.orderingLink !== "" && item.newWindow ? (
                <TouchableOpacity
                  activeOpacity={1.0}
                  onPress={(e) => {
                    // RootNavigation.navigate("Restaurant", {
                    if (Platform.OS === "web")
                      window.open(item.orderingLink, "_blank").focus();
                    else Linking.openURL(item.orderingLink);
                  }}
                  style={{
                    position: "absolute",
                    alignSelf: "center",
                    top: "5%",
                    zIndex: 100,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Feather
                    name="external-link"
                    size={48}
                    color={"#fff"}
                    style={
                      {
                        //marginBottom: 20,
                        // marginRight: 20,
                      }
                    }
                  />
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 18,
                      font: store.fontFamily,
                      textAlign: "center",
                    }}
                  >
                    Open in New Tab
                  </Text>
                </TouchableOpacity>
              ) : item.orderingLink === "" ? (
                <TouchableOpacity
                  activeOpacity={1.0}
                  onPress={(e) => {
                    // RootNavigation.navigate("Restaurant", {
                    if (Platform.OS === "web")
                      if (store.screenWidth > 800)
                        window.open(item.messenger, "_blank").focus();
                      else window.open(item.messenger);
                    else
                      Linking.openURL(
                        "fb://facewebmodal/f?href=" + item.messenger
                      );
                  }}
                  style={{
                    position: "absolute",
                    alignSelf: "center",
                    top: "5%",
                    zIndex: 100,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name="facebook-messenger"
                    size={64}
                    color={"#fff"}
                    style={
                      {
                        //marginBottom: 20,
                        // marginRight: 20,
                      }
                    }
                  />
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 18,
                      font: store.fontFamily,
                      textAlign: "center",
                    }}
                  >
                    Order via Messenger
                  </Text>
                </TouchableOpacity>
              ) : (
                <View></View>
              )}
              <View
                style={{
                  borderRadius: 15,
                  backgroundColor: "#efefef",
                  padding: 5,
                  position: "absolute",
                  top: 7,
                  right: 5,
                }}
              >
                <Text
                  style={{
                    fontWeight: "100",
                    fontSize: 10,
                    fontFamily: "Roboto_400Regular",
                    color: store.hexToRGB(store.textColor, 1),
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  numberOfLines={1}
                >
                  {item.suburb}
                </Text>
              </View>
              {closingIn > 0 && closingIn < 60 && (
                <View
                  style={{
                    borderRadius: 15,
                    backgroundColor: store.primaryColor,
                    padding: 5,
                    position: "absolute",
                    bottom: 7,
                    right: 5,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "100",
                      fontSize: 12,
                      fontFamily: "Roboto_400Regular",
                      color: "#fff", //store.hexToRGB(store.textColor, 0.6),
                      alignSelf: "flex-end",
                      // marginTop: -10,
                    }}
                    numberOfLines={1}
                  >
                    {" closes in " + closingIn + " minutes"}
                  </Text>
                </View>
              )}
              <TouchableOpacity
                onPress={() => {
                  item.setFavs("My Favs");
                  store.setFavs(item.title);
                  this.setState({ fav: !this.state.fav });
                }}
                style={{
                  position: "absolute",
                  top: 35,
                  right: 5,
                  width: 50,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MaterialCommunityIcons
                  name={this.state.fav ? "heart" : "heart-outline"}
                  style={{
                    color: "white",
                    fontSize: 25,
                  }}
                />
              </TouchableOpacity>
            </View>
          </ImageBackground>
        </TouchableOpacity>
        <View
          style={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            marginTop: 2,
            padding: 4,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              // minHeight: 25,
            }}
          >
            <TouchableOpacity
              activeOpacity={1.0}
              style={{
                flex: 1,
                alignItems: "flex-start",
                justifyContent: "center",
              }}
              onPress={(e) => {
                // RootNavigation.navigate("Restaurant", {
                if (item.orderingLink !== "") {
                  if (item.newWindow)
                    window.open(item.orderingLink, "_blank").focus();
                  else
                    RootNavigation.navigate("Tabs", {
                      screen: "RestaurantsStack",
                      params: {
                        screen: "Restaurant",
                        params: { id: item.id },
                      },
                    });
                } else if (Platform.OS === "web")
                  if (store.screenWidth > 800)
                    window.open(item.messenger, "_blank").focus();
                  else window.open(item.messenger);
                else
                  Linking.openURL("fb://facewebmodal/f?href=" + item.messenger);
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 24,
                  fontFamily: store.font,
                  color: store.textColor,
                }}
                numberOfLines={1}
              >
                {item.title}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flex: 0.8,
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <TouchableOpacity
                onPress={(e) => {
                  store.setReviewDialogVisible(!store.reviewDialogVisible);
                  store.setSelectedRestaurantId(item.id);
                }}
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <MaterialCommunityIcons name="star" size={16} color="black" />
                <Text
                  style={{
                    marginLeft: 2,
                    marginTop: -1,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {item.rating}
                </Text>
                <Text style={{ marginLeft: 2, fontSize: 12, marginTop: 1 }}>
                  {"(" + item.noReviews + " Ratings)"}
                </Text>
              </TouchableOpacity>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 14,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
              >
                {!!item.priceBucket.replaceAll("$", store.currencyCode)}
              </Text>
              {store.selectedRestaurant.facebook !== "" && (
                <TouchableOpacity
                  style={{ marginLeft: 5, marginTop: -2 }}
                  activeOpacity={1.0}
                  onPress={(e) => {
                    // RootNavigation.navigate("Restaurant", {
                    if (Platform.OS === "web")
                      if (store.screenWidth > 800)
                        window.open(item.facebook, "_blank").focus();
                      else window.open(item.facebook);
                    else
                      Linking.openURL(
                        "fb://facewebmodal/f?href=" + item.facebook
                      );
                  }}
                >
                  <Fontisto
                    name="facebook"
                    size={16}
                    color={"#000"}
                    style={
                      {
                        //marginBottom: 20,
                        // marginRight: 20,
                      }
                    }
                  />
                </TouchableOpacity>
              )}
              {store.selectedRestaurant.phone !== "" && (
                <TouchableOpacity
                  style={{ marginLeft: 10 }}
                  onPress={(e) => {
                    if (Platform.OS === "web")
                      window
                        .open(
                          "tel//:" + store.selectedRestaurant.phone,
                          "_blank"
                        )
                        .focus();
                    else
                      Linking.openURL(
                        "tel//:" + store.selectedRestaurant.phone
                      );
                  }}
                >
                  <MaterialIcons
                    name="phone"
                    size={20}
                    color={"#000"}
                    style={
                      {
                        //marginBottom: 20,
                        // marginRight: 20,
                      }
                    }
                  />
                </TouchableOpacity>
              )}
              {store.selectedRestaurant.messenger !== "" && (
                <TouchableOpacity
                  style={{ marginLeft: 5 }}
                  activeOpacity={1.0}
                  onPress={(e) => {
                    // RootNavigation.navigate("Restaurant", {
                    if (Platform.OS === "web")
                      if (store.screenWidth > 800)
                        window.open(item.messenger, "_blank").focus();
                      else window.open(item.messenger);
                    else
                      Linking.openURL(
                        "fb://facewebmodal/f?href=" + item.messenger
                      );
                  }}
                >
                  <MaterialCommunityIcons
                    name="facebook-messenger"
                    size={24}
                    color={"#0284FF"}
                    style={
                      {
                        //marginBottom: 20,
                        // marginRight: 20,
                      }
                    }
                  />
                </TouchableOpacity>
              )}
            </View>
            <View
              style={{
                flex: 0.2,
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
              }}
            >
              <TouchableOpacity
                onPress={(e) => {
                  if (Platform.OS === "web")
                    window.open(
                      // "https://www.google.com/maps?saddr=My+Location&daddr=" +
                      //   item.gps[0] +
                      //   "," +
                      //   item.gps[1]
                      "https://www.google.com/maps/place/?q=place_id:" +
                        item.placeId
                    );
                  else
                    Linking.openURL(
                      // "https://www.google.com/maps?saddr=My+Location&daddr=" +
                      //   item.gps[0] +
                      //   "," +
                      //   item.gps[1]
                      "https://www.google.com/maps/place/?q=place_id:" +
                        item.placeId
                    );
                }}
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <MaterialCommunityIcons
                  name="map-marker-outline"
                  size={24}
                  color="black"
                />
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{
              flex: 1,
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontFamily: "Roboto_400Regular",
                color: store.textColor,
                width: "100%",
                marginTop: 2,
                marginBottom: 0,
              }}
              numberOfLines={2}
            >
              {item.cuisines.toString()}
            </Text>

            <Text
              style={{
                fontSize: 10,
                fontFamily: "Roboto_400Regular",
                color: store.hexToRGB(store.textColor, 0.7),
                flex: 1,
                marginTop: 5,
                marginBottom: 5,
              }}
              numberOfLines={3}
            >
              {store.location}
              {" - "}
              {cost == null || cost === -1
                ? "Delivery not available"
                : cost === 0
                ? "Free Delivery"
                : cost}
              {showTotalOrders && (
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Roboto_400Regular",
                    color: store.hexToRGB(store.textColor, 0.7),
                    marginLeft: 10,
                  }}
                >
                  {item.totalOrders > 0 && "   -  " && item.totalOrders}
                </Text>
              )}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

class RenderSeparator extends React.PureComponent {
  render() {
    return <View />;
  }
}

function wait(timeout) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}

const styles = StyleSheet.create({
  header: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "100%",
    zIndex: 1,
  },
  subHeader: {
    height: headerHeight / 2,
    width: "100%",
    paddingHorizontal: 10,
  },
  container: {
    marginTop: 70,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  container: {
    marginTop: 70,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});

function getIcon(icon_type, icon, color, size = 20) {
  if (icon.indexOf(".png") > 0 || icon.indexOf(".svg") > 0) {
    return (
      <Image
        source={{ uri: icon }}
        style={{ width: size * 1.2, height: size * 1.2, tintColor: color }}
        resizeMode="contain"
      />
    );
  } else if (icon_type.indexOf("AntDesign") > -1) {
    return <AntDesign name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Octicons") > -1) {
    return <Octicons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Entypo") > -1) {
    return <Entypo name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("EvilIcons") > -1) {
    return <EvilIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Feather") > -1) {
    return <Feather name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesomeIcon") > -1) {
    return <FontAwesomeIcon icon={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesome5") > -1) {
    return <FontAwesome5 name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesome") > -1) {
    return <FontAwesome name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Foundation") > -1) {
    return <Foundation name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Ionicons") > -1) {
    return <Ionicons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("MaterialCommunityIcons") > -1) {
    return <MaterialCommunityIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("MaterialIcons") > -1) {
    return <MaterialIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("SimpleLineIcons") > -1) {
    return <SimpleLineIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Zocial") > -1) {
    return <Zocial name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Fontisto") > -1) {
    return <Fontisto name={icon} color={color} size={size} />;
  }
}
