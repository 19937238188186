// Formik x React Native example
import React from "react";
import { Button, TextInput, View, Text } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your name"),
  phone: Yup.string()
    .min(2, "Too Short!")
    .max(15, "Too Long!")
    .required("Please enter your phone"),
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
  message: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your message"),
});

import { TouchableOpacity } from "react-native-gesture-handler";

export function ContactScreen({ navigation }) {
  // useEffect(() => {
  //   if (store.getSlides[0].backgroundVideo !== "") videoRef.current.play();
  // }, []);

  const [sent, setSent] = React.useState(false);

  const inputStyle = {
    borderColor: store.primaryColor,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 10,
    margin: 5,
    minHeight: 40,
    borderRadius: 15,
  };

  return (
    <View style={{ flex: 1, marginTop: 100, justifyContent: "flex-start" }}>
      <Text
        style={{
          fontSize: 32,
          color: store.primaryColor,
          fontFamily: store.font,
          margin: 10,
        }}
      >
        Contact Us
      </Text>
      {sent ? (
        <View
          style={{
            height: 300,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              width: "80%",
              textAlign: "center",
              fontFamily: "Roboto_300Light_Italic",
              color: store.hexToRGB(store.primaryColor, 0.75),
              fontSize: 18,
            }}
          >
            {"Thanks for your Message \n\n We'll get back to you soon."}
          </Text>
        </View>
      ) : (
        <Formik
          initialValues={{ name: "", email: "", phone: "", message: "" }}
          validationSchema={ContactSchema}
          onSubmit={(values, resetForm) => {
            store.submitContactForm(values).then((value) => {
              setSent(true);
              setTimeout(() => {
                setSent(false);
                resetForm();
              }, 10000);
            });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            values,
          }) => (
            <View>
              <TextInput
                required
                placeholder="Please enter your name"
                placeholderTextColor={store.hexToRGB(store.primaryColor, 0.4)}
                onChangeText={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
                style={inputStyle}
              />
              {errors.name && touched.name ? (
                <Text style={{ color: store.primaryColor, marginLeft: 10 }}>
                  {errors.name}
                </Text>
              ) : null}
              {errors.email && touched.email ? (
                <Text style={{ color: store.primaryColor, marginLeft: 10 }}>
                  {errors.email}
                </Text>
              ) : null}
              <TextInput
                required
                placeholder="Please enter your email"
                placeholderTextColor={store.hexToRGB(store.primaryColor, 0.4)}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                value={values.email}
                style={inputStyle}
              />
              <TextInput
                required
                placeholder="Please enter your phone"
                placeholderTextColor={store.hexToRGB(store.primaryColor, 0.4)}
                onChangeText={handleChange("phone")}
                onBlur={handleBlur("phone")}
                value={values.phone}
                style={inputStyle}
              />
              {errors.phone && touched.phone ? (
                <Text style={{ color: store.primaryColor, marginLeft: 10 }}>
                  {errors.phone}
                </Text>
              ) : null}
              <TextInput
                required
                placeholder="Please enter your message"
                placeholderTextColor={store.hexToRGB(store.primaryColor, 0.4)}
                multiline
                numberOfLines={3}
                onChangeText={handleChange("message")}
                onBlur={handleBlur("message")}
                value={values.message}
                style={inputStyle}
              />
              {errors.message && touched.message ? (
                <Text style={{ color: store.primaryColor, marginLeft: 10 }}>
                  {errors.message}
                </Text>
              ) : null}
              <TouchableOpacity
                style={{
                  backgroundColor: store.primaryColor,
                  borderRadius: 15,
                  flex: 1,
                  paddingHorizontal: 5,
                  minHeight: 35,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onPress={handleSubmit}
              >
                <Text
                  style={{
                    color: store.buttonTextColor,
                    width: "100%",
                    textAlign: "center",
                    fontFamily: store.font,
                    fontSize: 14,
                    justifyContent: "center",
                  }}
                >
                  Submit
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </Formik>
      )}
    </View>
  );
}
