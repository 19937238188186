import {
  StyleSheet,
  Button,
  View,
  Text,
  ActivityIndicator,
  Platform,
  ImageBackground,
  TouchableOpacity,
} from "react-native";

import { debounce } from "lodash";
import { Searchbar } from "react-native-paper";

import { MaterialIcons } from "@expo/vector-icons";

import { useState, useEffect, useRef, useCallback } from "react";
import { inject, observer, Observer } from "mobx-react";

import { Video } from "expo-av";
import Swiper from "react-native-swiper/src";

import * as RootNavigation from "../navigation/RootNavigation";

import Constants from "expo-constants";

export const HomeSlider = observer((props) => {
  return (
    <View>
      {store.getSlides.length > 0 && (
        <View
          style={{
            marginTop: 35,
            zIndex: 0,
            height: store.screenWidth > 800 ? 500 : store.screenHeight * 0.5,
          }}
        >
          <Swiper
            autoplayTimeout={store.slideDelay}
            style={[{ height: "100%" }]}
            showsPagination={store.showSlideDots}
            // Platform.OS === "web" && store.screenWidth > 800 ? true : false
            // }
            s
            dotColor={store.buttonTextColor}
            activeDotColor={store.primaryColor}
            autoplay={store.slidesAutoPlay}
            buttonWrapperStyle={{ color: store.primaryColor }}
            nextButton={
              <Text
                style={{ color: store.getSlides[0].titleColor, fontSize: 64 }}
              >
                ›
              </Text>
            }
            prevButton={
              <Text
                style={{ color: store.getSlides[0].titleColor, fontSize: 64 }}
              >
                ‹
              </Text>
            }
            showsButtons={store.showSlideArrows}
          >
            {store.getSlides.map((slide, i) => (
              <View
                key={i}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: slide.backgroundColor,
                }}
              >
                {slide.backgroundVideo && slide.backgroundVideo !== "" ? (
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: slide.backgroundColor,
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      // zIndex: -1,
                    }}
                  >
                    {Platform.OS !== "web" ? (
                      <Video
                        source={{
                          uri:
                            slide.backgroundVideo.indexOf("http") > -1
                              ? slide.backgroundVideo
                              : store.getImageDirectory(slide.backgroundVideo),
                        }}
                        isLooping
                        isMuted
                        positionMillis={500}
                        resizeMode="cover"
                        shouldPlay
                        style={{
                          flex: 1,
                          minWidth: store.screenWidth,
                          minHeight: store.screenHeight,
                        }}
                      />
                    ) : (
                      <video
                        height="100%"
                        style={{
                          objectFit: "fill",
                          width: "100%",
                          height: "100%",
                          zIndex: 1,
                        }}
                        controls={false}
                        autoPlay
                        loop
                        muted
                        playsInline
                      >
                        <source
                          src={
                            store.screenWidth > 800
                              ? slide.backgroundVideo.replace(
                                  ".mp4",
                                  "_desktop.mp4"
                                )
                              : slide.backgroundVideo
                          }
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </View>
                ) : slide.backgroundImage && slide.backgroundImage !== "" ? (
                  <View
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: -1,
                    }}
                  >
                    <ImageBackground
                      source={{
                        uri: store.getImageDirectory(slide.backgroundImage),
                      }}
                      style={{
                        flex: 1,
                        width: "100%",
                        height: "100%",
                      }}
                      resizeMode={"cover"}
                    ></ImageBackground>
                  </View>
                ) : (
                  <View></View>
                )}
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    botom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                    flex: 1,
                    // backgroundColor:
                    //   "rgba(0,0,0, " +
                    //   parseFloat(slide.backgroundOpacity) +
                    //   ")",
                    justifyContent: "center",
                    alignItems: "center",
                    // flex: 1,
                    // backgroundColor:
                    //   "rgba(0,0,0, " +
                    //   parseFloat(slide.backgroundOpacity) +
                    //   ")",
                  }}
                >
                  <Text
                    style={{
                      color: slide.titleColor,
                      fontSize: store.screenWidth > 800 ? 64 : 48,
                      fontFamily: store.font,
                      fontWeight: "bold",
                      alignSelf: "center",
                      textAlign: "center",
                      zIndex: 3,
                    }}
                  >
                    {slide.title && slide.title}
                  </Text>

                  <Text
                    style={{
                      color: slide.subtitleColor,
                      fontSize: store.screenWidth > 800 ? 32 : 32,
                      fontFamily: store.font,
                      fontWeight: "bold",
                      alignSelf: "center",
                      textAlign: "center",
                      zIndex: 3,
                    }}
                  >
                    {slide.subtitle && slide.subtitle}
                  </Text>
                </View>
              </View>
            ))}
          </Swiper>
        </View>
      )}
    </View>
  );
});

export const SearchBar = observer((props) => {
  const [search, setSearch] = useState(store.search);
  const [typing, setTyping] = useState(false);
  const [category, setCategory] = useState(
    store.categories.length > 0 ? store.categories[0] : ""
  );
  const handler = useCallback(debounce(updateSearch, 1500), []);

  function updateSearch(e) {
    store.setSearch(e);
    if (e !== "")
      RootNavigation.navigate("Tabs", {
        screen: "RestaurantsStack",
        params: {
          screen: "Restaurants",
          params: {
            category: "all",
            sort: "default",
            search: e,
          },
        },
      });

    setTimeout(() => {
      setTyping(false);
    }, 500);
  }

  const onChange = (e) => {
    setTyping(true);
    handler(e);
  };

  // const onChange = (query) => {
  //   setTyping(true);
  //   store.setSearch(query);
  //   setSearch(query);
  //   wait(2500).then(() => {
  //     if (query === "") store.setSearch("");
  //   });

  //   setTimeout(() => {
  //     setTyping(false);
  //   }, 2500);
  // };
  return (
    <View
      style={{
        width: "100%",
        minHeight: 60,
        maxHeight: 60,
        justifyContent: "center",
        margin: 2,
      }}
    >
      <Observer>
        {() => (
          <View>
            <Searchbar
              platform="android"
              placeholder={
                store.isLoading
                  ? "Searching.."
                  : "Search your favourite restaurants"
              }
              onSubmitEditing={(e) => {
                // wait(250).then(() => {
                store.isLoading ? null : store.setSearch(search);
                // });
              }}
              onChangeText={(e) => {
                setSearch(e);
                onChange(e);
                wait(2500).then(() => {
                  if (e === "") store.setSearch("");
                });
              }}
              icon={
                store.restaurantStore.isLoading
                  ? () => (
                      <ActivityIndicator
                        animating={true}
                        color={store.primaryColor}
                      />
                    )
                  : () => (
                      <MaterialIcons
                        name={"search"}
                        size={24}
                        color={store.primaryColor}
                      />
                    )
              }
              placeholderTextColor={"#aaa"}
              bounceinputStyle={{ fontSize: 17 }}
              style={{
                backgroundColor: "#fff",
                marginBottom: 0,
                marginTop: 0,
              }}
              value={search}
            />
          </View>
        )}
      </Observer>
    </View>
  );
});

function wait(timeout) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}
