import React, { Component, useState, useRef, useEffect } from "react";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";

import Constants from "expo-constants";

import * as Updates from "expo-updates";
import { useInaccurateTimestamp } from "react-native-use-timestamp";

import { Provider, observer, inject, Observer } from "mobx-react";

import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Host } from "react-native-portalize";
import PWAPrompt from "react-ios-pwa-prompt";
import Toast from "react-native-toast-message";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import Helmet from "react-helmet";
import BouncingPreloader from "react-native-bouncing-preloaders";

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

import * as Sentry from "sentry-expo";

Sentry.init({
  dsn: "https://79ca4786bd1742189aa13cab7feb071e@o211368.ingest.sentry.io/6546242",
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

// // Access any @sentry/react-native exports via:
// Sentry.Native.*

// // Access any @sentry/browser exports via:
// Sentry.Browser.*

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

import {
  View,
  Dimensions,
  ActivityIndicator,
  SafeAreaView,
  Image,
  Text,
  Platform,
  TouchableOpacity,
} from "react-native";

import {
  Provider as PaperProvider,
  DefaultTheme,
  Button,
  Snackbar,
  Portal,
} from "react-native-paper";

import {
  useFonts,
  // Roboto_100Thin,
  // Roboto_100Thin_Italic,
  // Roboto_300Light,
  // Roboto_300Light_Italic,
  Roboto_400Regular,
  // Roboto_400Regular_Italic,
  // Roboto_500Medium,
  // Roboto_500Medium_Italic,
  Roboto_700Bold,
  // Roboto_700Bold_Italic,
  // Roboto_900Black,
  // Roboto_900Black_Italic,
} from "@expo-google-fonts/roboto";

import { ErrorBoundary } from "react-error-boundary";

import { Navbar } from "./components/Navbar";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import * as RootNavigation from "../navigation/RootNavigation";

import * as storeRef from "./utils/service";
import { initStore } from "./stores/rootStore";

import "react-native-gesture-handler";

const { width, height } = Dimensions.get("window");

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC6pKZdi1HL_PO3GXFX6oEXvaQyxVwQypg",
  authDomain: "foood-vn.firebaseapp.com",
  databaseURL:
    "https://foood-vn-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "foood-vn",
  storageBucket: "foood-vn.appspot.com",
  messagingSenderId: "927720083482",
  appId: "1:927720083482:web:050fe1903579d200fd01a3",
  measurementId: "G-ZLSGML4TVE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null));

let store = initStore();
storeRef.setStore(store);

if (typeof window !== "undefined") window.store = store;

const errorTextStyle = {
  color: "#FFFFFF",
  fontFamily: store.font,
  fontSize: 24,
  marginTop: 10,
  textAlign: "center",
  alignItems: "center",
  marginTop: 10,
};

const ErrorObject = (props) => {
  var { store, error, resetErrorBoundary } = props;

  useEffect(() => {
    if (window.location.href.indexOf("127.0.0.1") === -1)
      setTimeout(() => {
        store.restartAppClear();
        resetErrorBoundary();
      }, 3500);

    if (
      Platform.OS === "web" &&
      window &&
      window.navigator.userAgent.toLowerCase().indexOf("safari") === -1
    )
      store.prompt();
  }, []);

  return (
    <View>
      {store && (
        <View
          style={{
            paddingTop: 100,
            flex: 1,
            minHeight: height,
            backgroundColor: store.backgroundColor,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <BouncingPreloader
            icons={[store.logoImageUrl]}
            leftRotation="-680deg"
            rightRotation="360deg"
            leftDistance={-180}
            rightDistance={-250}
            size={200}
            speed={2500}
          />

          <Text style={errorTextStyle}>Ooops..</Text>
          <Text style={errorTextStyle}>Houston we have a problem.</Text>
          <Text style={errorTextStyle}>App is restarting..</Text>
        </View>
      )}
      <StatusBar backgroundColor={store.backgroundColor} style="light" />
    </View>
  );
};

export default function App() {
  let [fontsLoaded] = useFonts({
    // Roboto_100Thin,
    // Roboto_100Thin_Italic,
    // Roboto_300Light,
    // Roboto_300Light_Italic,
    Roboto_400Regular,
    // Roboto_400Regular_Italic,
    // Roboto_500Medium,
    // Roboto_500Medium_Italic,
    Roboto_700Bold,
    // Roboto_700Bold_Italic,
    // Roboto_900Black,
    // Roboto_900Black_Italic,
  });

  const initGa = () => {
    if (Platform.OS === "'web" && !window.GA_INITIALIZED) {
      // ReactGA.initialize(store.analytics);
      // var pageView = store.screen;
      // if (Platform.OS === "'web") pageView = window.location.pathname;
      // if (pageView === "") pageView = "/";
      // ReactGA.pageview(pageView);
      // window.GA_INITIALIZED = true;
    }
  };

  setTimeout(() => {
    if (Platform.OS === "web") extractCSStoHTML();
  }, 2000);

  var iOSSafari;
  if (Platform.OS === "web") {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  }

  return (
    <SafeAreaProvider
      initialMetrics={{
        insets: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      }}
    >
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorObject
            store={store}
            error={error}
            resetErrorBoundary={resetErrorBoundary}
          />
        )}
      >
        <View
          onLayout={(e) => {
            if (
              e.nativeEvent.layout &&
              e.nativeEvent.layout.width !== store.screenWidth
            ) {
              store.setScreenWidth(e.nativeEvent.layout.width);
              store.setScreenHeight(e.nativeEvent.layout.height);
              // store.restartApp();
            }
          }}
        >
          <InternetConnectionAlert
            onChange={(connectionState) => {
              // store.setOffline(!connectionState.isInternetReachable ? true : false);
            }}
          >
            <Provider store={store}>
              <Observer>
                {() =>
                  !fontsLoaded || store.isLoading ? (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: store.backgroundColor,
                        minHeight:
                          Platform.OS !== "web"
                            ? height + Constants.statusBarHeight
                            : height,
                      }}
                    >
                      <Header />
                      <BouncingPreloader
                        icons={[store.logoImageUrl]}
                        leftRotation="-680deg"
                        rightRotation="360deg"
                        leftDistance={-180}
                        rightDistance={-250}
                        speed={1200}
                      />
                    </View>
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        minHeight:
                          Platform.OS !== "web"
                            ? height + Constants.statusBarHeight
                            : height,
                        paddingTop:
                          Platform.OS !== "web" ? Constants.statusBarHeight : 0,
                      }}
                    >
                      <PaperProvider>
                        {Platform.OS === "web" && <OtaUpdater />}
                        {Platform.OS === "web" && iOSSafari && (
                          <PWAPrompt
                            debug={store.forceShowInstallPrompt}
                            promptOnVisit={1}
                            timesToShow={3}
                            copyClosePrompt="Close"
                            permanentlyHideOnDismiss={false}
                          />
                        )}
                        <Host>
                          {store.screen !== "Splash" &&
                            store.screen !== "Feed" &&
                            store.screen !== "Reels" &&
                            ((store.screen !== "Product" &&
                              store.screen !== "Restaurant") ||
                              store.selectedRestaurant.showHeader) && (
                              <Navbar store={store} />
                            )}
                          <Navigation colorScheme={useColorScheme} />
                        </Host>
                        <Portal>
                          <MessageToast />
                        </Portal>
                      </PaperProvider>
                    </View>
                  )
                }
              </Observer>
            </Provider>
          </InternetConnectionAlert>
        </View>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
}
const toastConfig = {
  // cart: (internalState) => (
  //   <BaseToast
  //     style={{ borderLeftColor: store.backgroundColor }}
  //     contentContainerStyle={{ paddingHorizontal: 15 }}
  //     text1Style={{
  //       fontSize: 15,
  //       fontWeight: '400',
  //     }}
  //     // leadingIcon={{ uri: store.logoImageUrl }}
  //     text1={internalState.text1}
  //     text2={internalState.text2}
  //   />
  // ),
  messages: (internalState) => (
    <View
      style={{
        // position: 'absolute',
        // left: 0,
        // top: 0,
        minHeight: 90,
        maxHeight: 90,
        width: "98%",
        minWidth: 140,
        maxWidth: 140,
        backgroundColor: internalState.props.backgroundColor,
        borderRadius: 5,
        opacity: 0.9,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={{ padding: 10 }}>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            margin: 5,
            fontFamily: store.font,
            color: store.buttonColor,
            textAlign: "center",
          }}
        >
          {internalState.text1}
        </Text>
        <Text
          style={{
            fontWeight: "normal",
            fontSize: 12,
            margin: 5,
            marginTop: 10,
            marginTop: 20,
            fontFamily: store.font,
            color: store.buttonColor,
            textAlign: "center",
          }}
        >
          {internalState.text2}
        </Text>
      </View>
    </View>
  ),
  cart: (internalState) => (
    <View
      style={{
        // position: 'absolute',
        // left: 0,
        // top: 0,
        minHeight: 70,
        width: "98%",
        minWidth: 140,
        backgroundColor: internalState.props.backgroundColor,
        borderRadius: 5,
        opacity: 0.95,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text style={{ color: store.buttonColor, padding: 10 }}>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            margin: 5,
            fontFamily: store.font,
          }}
        >
          {internalState.text1}
        </Text>
        {"\n"}
        <Text
          style={{
            fontWeight: "normal",
            fontSize: 12,
            margin: 5,
            marginTop: 10,
            marginTop: 20,
            fontFamily: store.font,
          }}
        >
          {internalState.text2}
        </Text>
      </Text>
    </View>
  ),
  error: () => {},
  info: () => {},
};

const MessageToast = React.forwardRef((props, ref) => (
  <Toast config={toastConfig} ref={ref} />
));

const Header = observer(({ props }) => {
  return (
    <Helmet>
      {/* <title>{store.getTitle()}</title> */}
      <meta name="application-name" content={store.metaTitle} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={store.metaTitle} />
      <meta name="description" content={store.description} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content={store.backgroundColor} />
      <meta name="og:site_name" content={store.metaTitle} />
      <meta name="og:title" content={store.metaTitle} />
      <meta name="og:description" content={store.description} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={store.domain} />
      <meta
        name="og:image"
        content={store.getImageDirectory(store.metaImage)}
      />
      <meta
        http-equiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={store.getImageDirectory(store.favIconUrl)}
      />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="shortcut icon"
        href={store.getImageDirectory(store.favIconUrl)}
      />
    </Helmet>
  );
});

export const extractCSStoHTML = () => {
  if (
    typeof navigator != "undefined" &&
    (navigator.userAgent.includes("HeadlessChrome") ||
      navigator.userAgent.includes("headlesschrome") ||
      navigator.userAgent.includes("Prerender") ||
      navigator.userAgent.includes("prerender") ||
      navigator.userAgent.includes("Pixel 2 XL Build"))
  ) {
    var cssStyles = "";
    for (let i = 0; i < document.styleSheets.length - 1; i++) {
      let style = null;
      let styleSheet = document.styleSheets[i];
      if (styleSheet.href == null && styleSheet.ownerNode.textContent == "") {
        style = styleSheet.rules;
      }
      for (let item in style) {
        if (style[item].cssText != undefined) {
          cssStyles += style[item].cssText;
        }
      }
    }
    var head = document.head || document.getElementsByTagName("head")[0];
    var style = document.getElementById("styles-for-prerender");
    if (style) {
      style.setAttribute(
        "iteration",
        parseInt(style.getAttribute("iteration")) + 1
      );
      while (style.firstChild) {
        style.removeChild(style.firstChild);
      }
    } else {
      style = document.createElement("style");
      style.setAttribute("iteration", "1");
      head.appendChild(style);
      style.id = "styles-for-prerender";
      style.type = "text/css";
    }
    style.appendChild(document.createTextNode(cssStyles));
  }
  window.prerenderReady = true;
};

// How often do we want to render?
const INTERVAL_RENDER = 1000 * (__DEV__ ? 10 : 60);

// How often should it actually check for an update?
const INTERVAL_OTA_CHECK = 1000 * 60 * 15;

export function OtaUpdater() {
  const now = useInaccurateTimestamp({ every: INTERVAL_RENDER });
  const isMounted = useRef(true);
  const [updateIsAvailable, setUpdateAvailable] = useState(false);

  // Setting this to initially zero means there will _always_ be a check on
  // mount, which is nice, because that means a check when the app starts.
  const lastUpdate = useRef(0);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (updateIsAvailable) {
      return;
    }

    if (now - lastUpdate.current < INTERVAL_OTA_CHECK) {
      return;
    }

    lastUpdate.current = now;

    checkForUpdates()
      .then(() => {
        isMounted.current && setUpdateAvailable(true);
      })
      .catch((_reason) => {
        /* you can inspect _reason */
      });
  }, [now]);

  return (
    <Portal>
      <Snackbar
        visible={updateIsAvailable}
        onDismiss={() => {}}
        action={{
          label: "Apply update",
          onPress: () => {
            Updates.reloadAsync();
          },
        }}
      >
        Hey there! We got an update for you 🥳🎉.
      </Snackbar>
    </Portal>
  );
}

async function checkForUpdates(): Promise<string> {
  const update = await Updates.checkForUpdateAsync();
  if (!value.isAvailable) {
    throw new Error("No updates available");
  }

  const result = await Updates.fetchUpdateAsync();
  if (!result.isNew) {
    throw new Error("Fetched update is not new");
  }

  // Using the "extra": { "update-message": "..." } from app.json
  return result.manifest.extra["update-message"];
}
if (Platform.OS === "web") serviceWorkerRegistration.register();
