import { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  FlatList,
  Platform,
  View,
  Text,
  Image,
  ImageBackground,
  ScrollView,
  SafeAreaView,
} from "react-native";

import {
  AntDesign,
  Octicons,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  Zocial,
  Fontisto,
} from "@expo/vector-icons";

import { inject, observer } from "mobx-react";
import { WebView } from "react-native-webview";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Paragraph, Dialog, Portal, useTheme } from "react-native-paper";

import * as RootNavigation from "../navigation/RootNavigation";

import { CloseDialog, ReviewDialog } from "../components/RestaurantComponents";

export const ProductScreen = observer(({ route, navigation }) => {
  const [product, setProduct] = useState();

  useEffect(() => {
    if (route && route.params && store.selectedProductId !== route.params.id) {
      store.setSelectedProductId(route.params.id);
      setProduct(store.selectedProduct);
      if (store.selectedProduct) {
        store.setDescription(store.selectedProduct.description);
        store.setMetaImage(store.selectedProduct.heroImage);
      }
    }
  }, [route.params]);

  useEffect(() => {
    store.setShowCloseAlert(false);
    setTimeout(() => {
      store.setShowCloseAlert(true);
    }, 30000);
  }, []);

  if (!product) return <View></View>;
  return <ProductDetails rest={product} />;
});

export const ProductDetails = observer((props) => {
  const [product, setProduct] = useState(props.product ? props.product : null);
  const [restaurant, setRestaurant] = useState(store.selectedRestaurant);
  useEffect(() => {
    if (!product) {
      setRestaurant(store.selectedRestaurant);
      setProduct(store.selectedProduct);
      if (store.selectedProduct) {
        store.setDescription(store.selectedProduct.description);
        store.setMetaImage(store.selectedProduct.heroImage);
      }
    }
  }, []);
  if (!product) return <View></View>;
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: product.showHeader ? 70 : 0,
      }}
    >
      <ReviewDialog restaurant={restaurant} />
      <CloseDialog restaurant={restaurant} />
      {/* <Image
        source={{ uri: store.getImageDirectory(product.logoImageUrl) }}
        style={{ width: 50, height: 50, borderRadius: 25 }}
      />
      <Text style={[styles.title, { color: product.primaryColor }]}>
        {product.title}
      </Text> */}

      {(!restaurant.showHeader || props.inline) && (
        <View
          style={{
            width: "100%",
            backgroundColor: restaurant.backgroundColor,
            height: 30,
          }}
        >
          <TouchableOpacity
            onLongPress={(e) => {
              RootNavigation.navigate("Tabs", {
                screen: "HomeStack",
                params: {
                  screen: "Home",
                },
              });
            }}
            onPress={() => {
              if (props.inline) props.closeRestModal();
              else store.goBack();
            }}
            style={{ position: "absolute", top: 0, left: 5 }}
          >
            <MaterialCommunityIcons
              name="chevron-left"
              size={Platform.OS === "web" ? 24 : 28}
              color={restaurant.primaryColor}
            />
          </TouchableOpacity>
          {props.inline && (
            <TouchableOpacity
              onPress={() => {
                if (props.inline) props.closeRestModal();
                else store.goBack();
              }}
              style={{ position: "absolute", top: 0, right: 5 }}
            >
              <Ionicons
                name={"close"}
                size={Platform.OS === "web" ? 24 : 28}
                color={restaurant.primaryColor}
              />
            </TouchableOpacity>
          )}
        </View>
      )}

      {Platform.OS === "web" ? (
        <iframe
          key={store.key}
          src={product.directLink}
          title={product.title}
          width="100%"
          height="100%"
          frameBorder={0}
          style={{ borderWidth: 0 }}
          id="product_orderingLink"
          // thirdPartyCookiesEnabled={true}
        ></iframe>
      ) : (
        <WebView
          source={{ uri: product.directLink }}
          style={{
            marginTop: 20,
            minHeight: store.screenHeight,
            minWidth: store.screenWidth,
            height: "100%",
            width: "100%",
          }}
          thirdPartyCookiesEnabled={true}
        />
      )}
    </View>
  );
});

// import { useState, useEffect, useRef } from "react";
// import {
//   StyleSheet,
//   FlatList,
//   Platform,
//   View,
//   Text,
//   Image,
//   ImageBackground,
//   ScrollView,
//   SafeAreaView,
// } from "react-native";

// import {
//   AntDesign,
//   Octicons,
//   Entypo,
//   EvilIcons,
//   Feather,
//   FontAwesome,
//   FontAwesome5,
//   Foundation,
//   Ionicons,
//   MaterialIcons,
//   MaterialCommunityIcons,
//   SimpleLineIcons,
//   Zocial,
//   Fontisto,
// } from "@expo/vector-icons";

// import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// import { inject, observer } from "mobx-react";
// import { WebView } from "react-native-webview";
// import { TouchableOpacity } from "react-native-gesture-handler";
// import { Paragraph, Dialog, Portal, useTheme } from "react-native-paper";

// import * as RootNavigation from "../navigation/RootNavigation";

// export const ProductScreen = observer(({ route, navigation }) => {
//   const [product, setProduct] = useState();

//   useEffect(() => {
//     if (route && route.params && store.selectedProductId !== route.params.id) {
//       store.setSelectedProductId(route.params.id);
//       setProduct(store.selectedProduct);
//       if (store.selectedProduct) {
//         store.setSelectedRestaurantId(store.selectedProduct.storeSlug);
//         store.setDescription(store.selectedProduct.description);
//         store.setMetaImage(store.selectedProduct.image);
//       }
//     }
//   }, [route.params]);

//   useEffect(() => {
//     store.setShowCloseAlert(false);
//     setTimeout(() => {
//       store.setShowCloseAlert(true);
//     }, 30000);
//   }, []);

//   if (!product) return <View></View>;
//   return (
//     <View
//       style={{
//         flex: 1,
//         alignItems: "center",
//         justifyContent: "flex-start",
//         marginTop: product.showHeader ? 70 : 0,
//       }}
//     >
//       <CloseDialog product={product} />
//       {/* <Image
//         source={{ uri: store.getImageDirectory(product.logoImageUrl) }}
//         style={{ width: 50, height: 50, borderRadius: 25 }}
//       />
//       <Text style={[styles.title, { color: product.primaryColor }]}>
//         {product.title}
//       </Text> */}

//       {!product.showHeader && (
//         <View
//           style={{
//             width: "100%",
//             backgroundColor: product.backgroundColor,
//             height: 30,
//           }}
//         >
//           <TouchableOpacity
//             onPress={(e) => {
//               if (store.showCloseAlert) store.setCloseAlertVisible(true);
//               else
//                 RootNavigation.navigate("Tabs", {
//                   screen: "HomeStack",
//                   params: {
//                     screen: "Home",
//                   },
//                 });
//             }}
//             style={{ position: "absolute", top: 0, left: 5 }}
//           >
//             <Ionicons
//               name={"ios-arrow-back"}
//               size={Platform.OS === "web" ? 24 : 28}
//               color={store.primaryColor}
//             />
//           </TouchableOpacity>
//         </View>
//       )}

//       {Platform.OS === "web" ? (
//         <iframe
//           key={store.key}
//           src={product.directLink}
//           title={product.title}
//           width="100%"
//           height="100%"
//           frameBorder={0}
//           style={{ borderWidth: 0 }}
//           id="product_orderingLink"
//         ></iframe>
//       ) : (
//         <WebView
//           source={{ uri: product.directLink }}
//           style={{
//             marginTop: 20,
//             minHeight: store.screenHeight,
//             minWidth: store.screenWidth,
//             height: "100%",
//             width: "100%",
//           }}
//         />
//       )}
//     </View>
//   );
// });

// export const CloseDialog = observer((props) => {
//   var { product } = props;

//   return (
//     <Portal>
//       <Dialog
//         visible={store.closeAlertVisible}
//         onDismiss={(e) => {
//           store.setCloseAlertVisible(false);
//         }}
//         style={{
//           maxWidth: 500,
//           alignSelf: "center",
//           height: 250,
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Dialog.Title>Leave {product.title} Order</Dialog.Title>
//         <Dialog.Content>
//           <Paragraph>
//             Are you sure want to leave your order with {product.title}
//           </Paragraph>
//         </Dialog.Content>
//         <Dialog.Actions>
//           <TouchableOpacity
//             onPress={(e) => {
//               store.setCloseAlertVisible(false);
//             }}
//             style={{
//               borderRadius: 5,
//               backgroundColor: store.backgroundColor,
//               padding: 10,
//               width: 150,
//               height: 50,
//               margin: 5,
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Text
//               style={{
//                 color: store.primaryColor,
//                 fontSize: 12,
//                 textAlign: "center",
//               }}
//             >
//               Continue
//             </Text>
//           </TouchableOpacity>
//           <TouchableOpacity
//             onPress={(e) => {
//               store.setCloseAlertVisible(false);
//             }}
//             style={{
//               borderRadius: 5,
//               backgroundColor: store.backgroundColor,
//               padding: 10,
//               width: 150,
//               height: 50,
//               margin: 5,
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//             onPress={(e) => {
//               store.setCloseAlertVisible(false);
//               RootNavigation.navigate("Tabs", {
//                 screen: "RestaurantsStack",
//                 params: {
//                   screen: "Restaurants",
//                   params: {
//                     category: "all",
//                     sort: "default",
//                   },
//                 },
//               });
//             }}
//           >
//             <Text
//               style={{
//                 color: store.primaryColor,
//                 fontSize: 12,
//                 textAlign: "center",
//               }}
//             >
//               Go Back to Restaurants
//             </Text>
//           </TouchableOpacity>
//         </Dialog.Actions>
//       </Dialog>
//     </Portal>
//   );
// });
