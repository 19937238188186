// module.exports = async function () {
//   // This service needs to be registered for the module to work
//   // but it will be used later in the "Receiving Events" section
// };

var _store;

exports.setStore = function (store) {
  _store = store;
};

exports.getStore = function () {
  //validate the name...
  return _store;
};
