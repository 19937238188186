import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
  Image,
  Dimensions,
  ScrollView,
  Alert,
} from "react-native";

import Constants from "expo-constants";
import {
  AntDesign,
  Octicons,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  Zocial,
  Fontisto,
} from "@expo/vector-icons";

import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

import { inject, observer } from "mobx-react";

import {
  DrawerContentComponentProps,
  DrawerContentOptions,
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import RNPickerSelect from "react-native-picker-select";
import {
  Avatar,
  Menu,
  Divider,
  Caption,
  Drawer,
  Chip,
  Paragraph,
  Text,
  Title,
  Button,
  TouchableRipple,
  Switch,
} from "react-native-paper";

import * as RootNavigation from "../navigation/RootNavigation";
import Animated from "react-native-reanimated";
// import { ScrollView } from 'react-native-gesture-handler';

// const {width: store.screenWidth, height: store.screenHeight } = Dimensions.get('window');

export const DrawerContent = observer((props) => {
  const translateX = Animated.interpolateNode(props.progress, {
    inputRange: [0, 0.5, 0.7, 0.8, 1],
    outputRange: [0, -85, -70, -45, 0],
  });

  var languages = store.languages;

  const [lang, setLang] = React.useState(store.lang);
  const [location, setLocation] = React.useState(store.location);
  const [meal, setMeal] = useState("Dinner");

  useEffect(() => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 11) {
      setMeal("Breakfast");
    } else if (curHr < 16) {
      setMeal("Lunch");
    } else {
      setMeal("Dinner");
    }
  }, []);

  const closeDrawer = () => {
    closeMenu();
    props.navigation.closeDrawer();
  };

  const [visible, setVisible] = useState(false);

  const [locationType, setLocationType] = useState(store.locationType);
  const [showSuburbs, setShowSuburbs] = useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  var locationItems = store.getLocations.map((suburb, i) => ({
    value: suburb.name,
    label: suburb.name,
  }));

  var panelBackground = store.primaryColor;
  var buttonBackgroundActive = store.hexToRGB("#FFF", 1);
  var buttonBackgroundInActive =
    store.buttonColor !== "#fff" ? store.buttonColor : store.primaryColor;
  var buttonTextActive = buttonBackgroundInActive; //'#fff';
  var buttonTextInActive = "#fff";

  const pickerSelectStyles = {
    inputIOS: {
      fontSize: 14,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: buttonBackgroundActive,
      borderRadius: 4,
      color: buttonTextInActive,
      backgroundColor: buttonBackgroundInActive,
    },
    inputWeb: {
      fontSize: 14,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: buttonBackgroundActive,
      borderRadius: 4,
      color: buttonTextInActive,
      backgroundColor: buttonBackgroundInActive,
      // paddingRight: 30,
    },
    inputAndroid: {
      minWidth: 175,
      fontSize: 14,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: buttonBackgroundActive,
      borderStyle: "solid",
      borderRadius: 4,
      color: buttonTextInActive,
      backgroundColor: buttonBackgroundInActive,
    },
  };

  return (
    <DrawerContentScrollView
      key={store.theme}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      {...props}
      style={{
        flex: 1,
        backgroundColor: store.backgroundColor,
        // position: "absolute",
        width: "100%",
        height: store.screenHeight + Constants.statusBarHeight,
        // top: 0,

        paddingBottom: 50,
        // top: Platform.OS === "web" ? 0 : -20,
        // left: 0,
      }}
    >
      <ScrollView
        style={{ height: store.screenHeight - 50, marginTop: 80 }}
        showsVerticalScrollIndicator={false}
      >
        <DrawerItem
          style={{
            backgroundColor: buttonBackgroundInActive,
          }}
          labelStyle={{ marginLeft: -7 }}
          activeTintColor={buttonTextActive}
          inactiveTintColor={buttonTextInActive}
          icon={({ color, size }) => (
            <TouchableOpacity>
              <MaterialIcons
                name={"home"}
                color={buttonTextInActive}
                size={28}
              />
            </TouchableOpacity>
          )}
          label={"Home"}
          onPress={() => {
            RootNavigation.navigate("Tabs", {
              screen: "HomeStack",
              params: {
                screen: "Home",
              },
            });
          }}
        />
        <DrawerItem
          style={{
            backgroundColor: buttonBackgroundInActive,
          }}
          labelStyle={{ marginLeft: -7 }}
          activeTintColor={buttonTextActive}
          inactiveTintColor={buttonTextInActive}
          icon={({ color, size }) => (
            <TouchableOpacity>
              <MaterialIcons
                name={"restaurant"}
                color={buttonTextInActive}
                size={28}
              />
            </TouchableOpacity>
          )}
          label={"Restaurants"}
          onPress={() => {
            RootNavigation.navigate("RestaurantsStack", {
              screen: "Restaurants",
            });
          }}
        />
        {store.posts.size > 0 && (
          <DrawerItem
            icon={({ color, size }) => (
              <Entypo name="news" size={28} color={buttonTextInActive} />
            )}
            label={"Feed"}
            style={{
              backgroundColor: buttonBackgroundInActive,
            }}
            labelStyle={{ marginLeft: -7 }}
            activeTintColor={buttonTextActive}
            inactiveTintColor={buttonTextInActive}
            onPress={() => {
              RootNavigation.navigate("Feed");
            }}
          />
        )}
        {/* <DrawerItem
                      icon={({ color, size }) => (
                        <AntDesign name="search1" size={28} color={buttonTextInActive} />
                      )}
                      label={"Whats For " + meal}
                      style={{
                        backgroundColor: buttonBackgroundInActive,
                      }}
                      labelStyle={{ marginLeft: -7 }}
                      activeTintColor={buttonTextActive}
                      inactiveTintColor={buttonTextInActive}
                      onPress={() => {
                        store.setCategories(["All"]);
                        RootNavigation.navigate("Whats_For_Dinner");
                      }}
                    /> */}
        {store.reels.size > 0 && (
          <DrawerItem
            icon={({ color, size }) => (
              <Entypo name="video" size={28} color={buttonTextInActive} />
            )}
            label={"Reels"}
            style={{
              backgroundColor: buttonBackgroundInActive,
            }}
            labelStyle={{ marginLeft: -7 }}
            activeTintColor={buttonTextActive}
            inactiveTintColor={buttonTextInActive}
            onPress={() => {
              RootNavigation.navigate("Reels");
            }}
          />
        )}
        {/* <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        backgroundColor: buttonBackgroundInActive,
                        borderRadius: 5,
                        margin: 10,
                        marginBottom: 0,
                        marginTop: 4,
                        padding: 10,
                        paddingTop: 13,
                      }}
                    >
                      <View>
                        <FontAwesome name="language" size={24} color={buttonTextInActive} />
                      </View>
                      <View
                        style={{
                          marginLeft: 30,
                          marginTop: -3,
                          justifyContent: "flex-end",
                        }}
                      >
                        <RNPickerSelect
                          placeholder={{}}
                          //   label: "Select a number or add another...",
                          //   value: null,
                          //   color: "red",
                          // }}
                          onValueChange={(value) => {
                            setLang(value);
                            store.setLang(value, true);
                          }}
                          value={lang}
                          // onValueChange={(value) => console.log(value)}
                          items={languages.map((lang, i) => {
                            return {
                              value: lang,
                              label: lang.toUpperCase(),
                            };
                          })}
                          style={{
                            ...pickerSelectStyles,
                            ...{
                              minWidth: 30,
                              maxWidth: 160,
                              minHeight: 30,
                              maxHeight: 40,
                            },
                          }}
                        />
                      </View>
                    </View> */}
        <DrawerItem
          icon={({ color, size }) => (
            <MaterialCommunityIcons
              name="email"
              size={28}
              color={buttonTextInActive}
            />
          )}
          label="Contact"
          style={{
            backgroundColor: buttonBackgroundInActive,
          }}
          labelStyle={{ marginLeft: -7 }}
          activeTintColor={buttonTextActive}
          inactiveTintColor={buttonTextInActive}
          onPress={() => {
            RootNavigation.navigate("Contact");
          }}
        />
        {Platform.OS === "web" && (
          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="open-in-app"
                size={28}
                color={buttonTextInActive}
              />
            )}
            label="Download App"
            style={{
              backgroundColor: buttonBackgroundInActive,
            }}
            labelStyle={{ marginLeft: -7 }}
            activeTintColor={buttonTextActive}
            inactiveTintColor={buttonTextInActive}
            onPress={() => {
              var ua = window.navigator.userAgent;
              var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
              var webkit = !!ua.match(/WebKit/i);
              var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

              if (iOSSafari) store.setForceShowInstallPrompt(true);
              else store.prompt();
            }}
          />
        )}
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
            backgroundColor: buttonBackgroundInActive,
            borderRadius: 5,
            margin: 10,
            marginBottom: 0,
            marginTop: 4,
            padding: 10,
            paddingTop: 13,
          }}
        >
          <View style={{ paddingLeft: 4 }}>
            <FontAwesome
              name="map-marker"
              size={24}
              color={buttonTextInActive}
            />
          </View>
          <View
            style={{ marginLeft: 30, marginTop: -3, justifyContent: "center" }}
          >
            <RNPickerSelect
              placeholder={{}}
              //   label: "Select a number or add another...",
              //   value: null,
              //   color: "red",
              // }}
              onValueChange={(value) => {
                setLocation(value);
                store.setLocation(value, true);
              }}
              value={location}
              // onValueChange={(value) => console.log(value)}
              items={locationItems}
              style={{
                ...pickerSelectStyles,
                ...{
                  minWidth: 30,
                  maxWidth: 160,
                  minHeight: 30,
                  maxHeight: 40,
                },
              }}
            />
          </View>
        </View>
        <DrawerItem
          icon={({ color, size }) => (
            <Ionicons name="ios-reload" size={28} color={buttonTextInActive} />
          )}
          label="Restart App"
          style={{
            backgroundColor: buttonBackgroundInActive,
          }}
          labelStyle={{ marginLeft: -7 }}
          activeTintColor={buttonTextActive}
          inactiveTintColor={buttonTextInActive}
          onPress={() => store.restartAppClear(true)}
        />
        {/* <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        backgroundColor: buttonBackgroundInActive,
                        borderRadius: 5,
                        margin: 10,
                        marginBottom: 0,
                        marginTop: 4,
                        padding: 10,
                        paddingTop: 13,
                      }}
                    >
                      <View style={{ marginLeft: -10 }}>
                        <Image
                          source={{
                            uri:
                              store.theme === "1"
                                ? store.getImageDirectory(store.logoImageUrl2)
                                : store.getImageDirectory(store.logoImageUrl1),
                          }}
                          style={[
                            { width: 30, height: 30, marginLeft: 5 },
                            store.theme !== "1" && { tintColor: "#fff", marginLeft: 7 },
                          ]}
                          resizeMode="contain"
                        />
                      </View>
                      <View
                        style={{
                          marginLeft: 30,
                          flexGrow: 1,
                          justifyContent: "flex-start",
                        }}
                      >
                        <Text
                          style={{
                            marginTop: 4,
                            fontSize: 14,
                            fontWeight: "500",
                            color: buttonTextInActive,
                          }}
                        >
                          {store.theme === "1" ? "The Good Old Days" : "The Future"}
                        </Text>
                      </View>
                      <View
                        style={{
                          marginTop: -3,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Switch
                          color="#fff"
                          style={{ borderColor: store.surfaceColor }}
                          trackColor={{
                            false: store.backgroundColor1,
                            true: store.backgroundColor2,
                          }}
                          thumbColor={"#fff"}
                          ios_backgroundColor={
                            store.theme ? store.primaryColor2 : store.primaryColor1
                          }
                          onValueChange={(e) => {
                            if (store.theme === "2") {
                              store.setTheme("1");
                            } else {
                              store.setTheme("2");
                            }
                          }}
                          value={store.theme === "2"}
                        />
                      </View>
                    </View> */}
      </ScrollView>
    </DrawerContentScrollView>
  );
});

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
  userInfoSection: {
    paddingLeft: 20,
  },
  title: {
    marginTop: 20,
    fontSize: 17,
    fontWeight: "bold",
  },
  caption: {
    fontSize: 14,
    lineHeight: 14,
  },
  row: {
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
  paragraph: {
    fontWeight: "bold",
    marginRight: 3,
  },
  drawerSection: {
    marginTop: 5,
    // height: 300,
  },
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});

function getIcon(icon_type, icon, color, size = 20) {
  if (icon.indexOf(".png") > 0 || icon.indexOf(".svg") > 0) {
    return (
      <Image
        source={{ uri: icon }}
        style={{ width: size * 1.2, height: size * 1.2, tintColor: color }}
        resizeMode="contain"
      />
    );
  } else if (icon_type.indexOf("AntDesign") > -1) {
    return <AntDesign name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Octicons") > -1) {
    return <Octicons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Entypo") > -1) {
    return <Entypo name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("EvilIcons") > -1) {
    return <EvilIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Feather") > -1) {
    return <Feather name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesomeIcon") > -1) {
    return <FontAwesomeIcon icon={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesome5") > -1) {
    return <FontAwesome5 name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesome") > -1) {
    return <FontAwesome name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Foundation") > -1) {
    return <Foundation name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Ionicons") > -1) {
    return <Ionicons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("MaterialCommunityIcons") > -1) {
    return <MaterialCommunityIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("MaterialIcons") > -1) {
    return <MaterialIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("SimpleLineIcons") > -1) {
    return <SimpleLineIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Zocial") > -1) {
    return <Zocial name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Fontisto") > -1) {
    return <Fontisto name={icon} color={color} size={size} />;
  }
}
