import React, { useRef, useState, useEffect } from "react";
import {
  StatusBar,
  setStatusBarHidden,
  setStatusBarTranslucent,
} from "expo-status-bar";
import {
  View,
  Text,
  TouchableOpacity,
  Platform,
  Image,
  Dimensions,
  Alert,
} from "react-native";
import Helmet from "react-helmet";
import Constants from "expo-constants";
import {
  useLinking,
  NavigationContainer,
  DrawerActions,
  Link,
  StackActions,
  useIsFocused,
} from "@react-navigation/native";
import { SafeAreaView } from "react-native-safe-area-context";

import {
  createStackNavigator,
  CardStyleInterpolators,
} from "@react-navigation/stack";
import { inject, Observer, observer } from "mobx-react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
// import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';

import * as Linking from "expo-linking";
import {
  AntDesign,
  Octicons,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  Zocial,
  Fontisto,
} from "@expo/vector-icons";

import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

import SvgUri from "react-native-svg";
import color from "color";
import AsyncStorage from "@react-native-async-storage/async-storage";
const PERSISTENCE_KEY = "NAVIGATION_STATE_TASTY";
import { navigationRef } from "../navigation/RootNavigation";

import Lightbox from "react-native-lightbox";

import {
  Appbar,
  Avatar,
  useTheme,
  Portal,
  FAB,
  Button,
  Snackbar,
} from "react-native-paper";

import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
  useDrawerStatus,
} from "@react-navigation/drawer";

import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";

import { SplashScreen } from "../screens/SplashScreen";
import { HomeScreen } from "../screens/HomeScreen";
import { RestaurantsScreen } from "../screens/RestaurantsScreen";

import { RestaurantScreen } from "../screens/RestaurantScreen";
import { ProductScreen } from "../screens/ProductScreen";

import { DrawerContent } from "../navigation/drawerContent";
import { ContactScreen } from "../screens/ContactScreen";
import { WhatsForDinnerScreen } from "../screens/WhatsForDinnerScreen";
import { PostsScreen } from "../screens/PostsScreen";
import { VideosScreen } from "../screens/VideosScreen";

var prefix = Linking.makeUrl("/");
if (Platform.OS === "web")
  prefix = prefix.replace("exp:", location.protocol).replace();

const config = {
  screens: {
    ContentNav: {
      // path: '',
      screens: {
        initialRouteName: "Tabs",
        Tabs: {
          // path: '',
          initialRouteName: "HomeStack",
          screens: {
            HomeStack: {
              // path: '',
              initialRouteName: "Splash",
              screens: {
                Splash: "splash",
                Home: "home",
              },
            },
            RestaurantsStack: {
              initialRouteName: "Restaurants",
              screens: {
                Restaurants: {
                  path: "/restaurants/:category?/:sort?/:search?",
                },
                Restaurant: {
                  path: "/restaurant/:id",
                },
                Product: {
                  path: "/product/:id",
                },
              },
            },
          },
        },
        Whats_For_Dinner: {
          path: "/whatsfordinner",
        },
        Feed: {
          path: "/feed/:id?",
        },

        Reels: {
          path: "/reels",
        },
        Contact: {
          path: "/contact",
        },
        // PostStack: {
        //   path: "/post",
        //   initialRouteName: "Post",
        //   screens: {
        //     Post: {
        //       path: ":slug",
        //     },
        //   },
        // },
      },
    },
  },
};

const Navigation = observer((props) => {
  if (Platform.OS === "web") window.navigation = navigationRef;

  // const [lastTrackedPath, setLastTrackedPath] = useState(store.screen);

  // const trackPageview = (path = store) => {
  //   if (path !== lastTrackedPath) {
  //     // ReactGA.pageview(path);
  //     setLastTrackedPath(path);
  //   }
  // };

  const [isReady, setIsReady] = useState(false);
  const [initialState, setInitialState] = useState();

  const Stack = createStackNavigator();

  useEffect(() => {
    const restoreState = async () => {
      try {
        // console.log("restoreState");
        const initialUrl = await Linking.getInitialURL();
        if (Platform.OS !== "web" && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  const HomeStack = () => {
    var initialRoute = "Splash"; // store.splashShown ? "Home" : "Splash";
    if (store.splashShown) initialRoute = "Home";
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: true,
          gestureDirection: "horizontal",
          cardStyle: { flex: 1 },
          headerStyle: {
            elevation: 0,
            shadowOpacity: 0,
          },
        }}
        initialRouteName={"Splash"}
      >
        <Stack.Group>
          <Stack.Screen
            name="Home"
            options={({ route }) => ({
              headerShown: false,
              title: getTitle(route),
              headerTransparent: true,
              headerStyle: { borderBottomWidth: 0 },
              // navigationOptions,
            })}
            component={HomeScreen}
          />
        </Stack.Group>

        <Stack.Group screenOptions={{ presentation: "modal" }}>
          <Stack.Screen
            name="Splash"
            options={({ route }) => ({
              headerShown: false,
              title: getTitle(route),
              headerTransparent: true,
              headerStyle: {
                borderBottomWidth: 0,
              },
              // navigationOptions,
            })}
            component={SplashScreen}
          />
        </Stack.Group>
      </Stack.Navigator>
    );
  };

  const RestaurantsStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: true,
          gestureDirection: "horizontal",
          cardStyle: { flex: 1 },
          headerStyle: {
            elevation: 0,
            shadowOpacity: 0,
          },
        }}
      >
        <Stack.Group>
          <Stack.Screen
            name="Restaurants"
            options={({ route }) => ({
              headerShown: false,
              title: getTitle(route),
              headerTransparent: true,
              headerStyle: { borderBottomWidth: 0 },
              // navigationOptions,
            })}
            component={RestaurantsScreen}
          />
        </Stack.Group>
        <Stack.Group screenOptions={{ presentation: "modal" }}>
          <Stack.Screen
            name="Restaurant"
            options={({ route }) => ({
              headerShown: false,
              title: !store.selectedRestaurant
                ? getTitle(route)
                : store.getTitle() + " | " + store.selectedRestaurant.title, //,
              headerTransparent: true,
              headerStyle: {
                borderBottomWidth: 0,
              },
              // navigationOptions,
            })}
            component={RestaurantScreen}
          />
          <Stack.Screen
            name="Product"
            options={({ route }) => ({
              headerShown: false,
              title: !store.selectedProduct
                ? getTitle(route)
                : store.getTitle() + " | " + store.selectedProduct.title, //,
              headerTransparent: true,
              headerStyle: {
                borderBottomWidth: 0,
              },
              // navigationOptions,
            })}
            component={ProductScreen}
          />
        </Stack.Group>
      </Stack.Navigator>
    );
  };

  const BlogStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          cardStyle: { flex: 1 },
        }}
      >
        <Stack.Screen
          name="Blog"
          options={({ route }) => ({
            headerShown: false,
            title: getTitle(route),
            headerTransparent: true,
            headerStyle: { borderBottomWidth: 0 },
          })}
          component={BlogScreen}
        />
        <Stack.Screen
          name="Post"
          options={({ route }) => ({
            headerShown: false,
            title: getTitle(route),
            headerTransparent: true,
            headerStyle: { borderBottomWidth: 0 },
          })}
          component={PostScreen}
        />
      </Stack.Navigator>
    );
  };

  const TabsStack = () => {
    var BottomTab = createBottomTabNavigator();

    return (
      <SafeAreaView style={{ flex: 1 }}>
        <BottomTab.Navigator
          key={store.theme + "" + store.screen + " " + store.backgroundColor}
          shifting={true}
          activeColor={store.primaryColor}
          inactiveColor={color(store.primaryColor).alpha(0.6).rgb().string()}
          // style={{
          //   backgroundColor: store.backgroundColor,
          //   fontFamily: store.font,
          // }}
          barStyle={{
            backgroundColor: store.backgroundColor,
            fontFamily: store.font,
          }}
          sceneAnimationEnabled={true}
          initialRouteName={"Home"}
          screenOptions={{
            headerShown: false,
            tabBarActiveBackgroundColor: store.backgroundColor,
            tabBarActiveTintColor: store.buttonColor,
            tabBarInactiveTintColor: store.hexToRGB(store.buttonColor, 0.5),
            tabBarInactiveBackgroundColor: store.backgroundColor,
            tabBarVisible:
              //   store.screen !== "Video" &&
              store.screen !== "Splash" &&
              store.screen !== "Product" &&
              store.screen !== "Restaurant",
            tabBarStyle: [
              // store.screen !== "Video" ||
              store.screen === "Splash" ||
              store.screen === "Product" ||
              store.screen === "Restaurant"
                ? {
                    display: "none",
                  }
                : {},
            ],
          }}
        >
          {/* <BottomTab.Screen
          name={"HomeStack"}
          component={HomeStack}
          listeners={({ navigation, route }) => ({
            tabPress: (e) => {
              e.preventDefault();
              navigation.navigate("Tabs", {
                screen: "HomeStack",
                params: {
                  screen: "Home",
                },
              });
            },
          })}
          options={({ route }) => ({
            headerShown: false,
            title: getTitle(route),
            tabBarLabel: "Home",
            animationEnabled: true,
            tabBarVisible: false, // getTabBarVisible(route),
            tabBarIcon: ({ color, size }) => (
              <MaterialIcons name="home" size={24} color={color} />
            ),
          })}
        /> */}
          <BottomTab.Screen
            name={"HomeStack"}
            component={HomeStack}
            listeners={({ navigation, route }) => ({
              tabPress: (e) => {
                e.preventDefault();
                navigation.navigate("Tabs", {
                  screen: "HomeStack",
                  params: {
                    screen: "Home",
                  },
                });
              },
            })}
            options={({ route }) => ({
              headerShown: false,
              title: getTitle(route),
              tabBarLabel: "Home",
              animationEnabled: true,
              tabBarVisible: false, // getTabBarVisible(route),
              tabBarIcon: ({ color, size }) => (
                <MaterialIcons name="home" size={24} color={color} />
              ),
            })}
          />
          <BottomTab.Screen
            name={"RestaurantsStack"}
            component={RestaurantsStack}
            listeners={({ navigation, route }) => ({
              tabPress: (e) => {
                e.preventDefault();
                navigation.navigate("Tabs", {
                  screen: "RestaurantsStack",
                  params: {
                    screen: "Restaurants",
                    params: {
                      category: "all",
                      sort: "default",
                    },
                  },
                });
              },
            })}
            options={({ route }) => ({
              headerShown: false,
              title: getTitle(route),
              tabBarLabel: "Restaurants",
              animationEnabled: true,
              tabBarVisible: false, // getTabBarVisible(route),
              tabBarIcon: ({ color, size }) => (
                <MaterialIcons name="restaurant" size={24} color={color} />
              ),
            })}
          />
        </BottomTab.Navigator>
      </SafeAreaView>
    );
  };

  const getTabBarVisible = (route) => {
    if (store.screenType === "desktop") return false;
    else return true;
    const params = route.params;
    if (params) {
      if (params.tabBarVisible === false) {
        return false;
      }
    }
    return true;
  };

  const updateScreen = (new_screen) => {
    store.setScreen(new_screen);
    // trackPageview(new_screen);
  };

  const ContentNavigator = observer((props) => {
    // function ContentNavigator(props) {
    var search = "";

    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          headerMode: "float",
          headerTransparent: true,
          headerStyle: {
            elevation: 0,
            shadowOpacity: 0,
            borderBottomWidth: 0, //for ios
          },
          // header: ({ navigation, route, options, back }) => {
          //   // const { options } = scene.descriptor;
          //   const title =
          //     options.headerTitle !== undefined
          //       ? options.headerTitle
          //       : options.title !== undefined
          //       ? options.title
          //       : route.name;
          //   var currentScreen;
          //   if (navigationRef.current)
          //     currentScreen = navigationRef.current.getCurrentRoute().name;
          //   if (
          //     store.screenType === "mobile" &&
          //     store.marketplace() &&
          //     store.layoutType !== "pos"
          //   )
          //     return (
          //       <AppBarHeader
          //         navigation={navigation}
          //         currentScreen={currentScreen}
          //         forceHeader={Platform.OS !== "web"}
          //       />
          //     );
          // },
        }}
      >
        <Stack.Screen
          name="Tabs"
          component={TabsStack}
          // options={{ animationEnabled: true, title: "" }}
        />
        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
        <Stack.Screen
          name="Whats_For_Dinner"
          component={WhatsForDinnerScreen}
          options={({ route }) => ({
            headerShown: false,
            title: getTitle(route),
            headerTransparent: true,
            headerStyle: { borderBottomWidth: 0 },
            // navigationOptions,
          })}
        />
        <Stack.Screen
          name="Reels"
          component={VideosScreen}
          options={({ route }) => ({
            headerShown: false,
            title: getTitle(route),
            headerTransparent: true,
            headerStyle: { borderBottomWidth: 0 },
            // navigationOptions,
          })}
        />
        <Stack.Screen
          name="Feed"
          component={PostsScreen}
          options={({ route }) => ({
            headerShown: false,
            title: getTitle(route),
            headerTransparent: true,
            headerStyle: { borderBottomWidth: 0 },
            // navigationOptions,
          })}
        />
        <Stack.Screen
          name="Contact"
          component={ContactScreen}
          options={({ route }) => ({
            headerShown: false,
            title: getTitle(route),
            headerTransparent: true,
            headerStyle: { borderBottomWidth: 0 },
            // navigationOptions,
          })}
        />
      </Stack.Navigator>
    );
  });

  const Drawer = createDrawerNavigator();
  const routeNameRef = React.createRef();
  const linking = {
    prefixes: prefix,
    config: config,
  };

  return (
    <View style={{ flex: 1 }}>
      {Platform.OS === "web" && <Header />}
      <View style={{ flexGrow: 1 }}>
        <NavigationContainer
          linking={linking}
          initialState={initialState}
          onReady={() => {
            routeNameRef.current = navigationRef.current.getCurrentRoute().name;
            var currentScreen = navigationRef.current.getCurrentRoute().name;
            // console.log("onReady", currentScreen);
            if (
              Platform.OS === "web" &&
              location &&
              location.href.indexOf("https://") > -1
            ) {
              var url = location.href;
              var slug = url
                .split("/")[2]
                .replace("foood.vn", "")
                .replace("foood.au", "")
                .replace("sweets.vn", "");
              if (
                url.indexOf("https://") > -1 &&
                url.indexOf("foood.au") === -1 &&
                url.indexOf("foood.vn") === -1 &&
                url.indexOf("foood") === -1 &&
                url.indexOf("sweets.vn") === -1
              ) {
                // console.log("slug", slug);
                if (store.searchDomain(slug)) {
                  navigationRef.current.navigate("Tabs", {
                    screen: "RestaurantsStack",
                    params: {
                      screen: "Restaurant",
                      params: { id: store.selectedRestaurant.slug },
                    },
                  });
                  updateScreen(currentScreen);
                }
                // Search stores for domain and return slug and redirect.
              } else if (slug !== "www." && slug !== "app." && slug !== "") {
                slug = slug.replace(".", "");
                navigationRef.current.navigate("Tabs", {
                  screen: "RestaurantsStack",
                  params: {
                    screen: "Restaurant",
                    params: { id: slug },
                  },
                });
                updateScreen(currentScreen);
              } else updateScreen(currentScreen);
            } else updateScreen(currentScreen);
            // if (currentScreen === "Restaurant") {
            //   var {
            //     id,
            //     slug,
            //     storeSlug,
            //   } = navigationRef.current.getCurrentRoute().params;
            //   store.loadRestaurant(slug, storeSlug);
            // }
          }}
          onStateChange={() => {
            const previousRouteName = routeNameRef.current;
            const currentRouteName =
              navigationRef.current.getCurrentRoute().name;

            if (previousRouteName !== currentRouteName) {
              // Do something here with it
            }

            // Save the current route name for later comparision
            // console.log('currentRouteName:', currentRouteName);
            // console.log("onStateChange", currentRouteName);
            updateScreen(currentRouteName);
            store.setMetaTitle(store.title);
            routeNameRef.current = currentRouteName;
          }}
          ref={navigationRef}
        >
          <Drawer.Navigator
            useLegacyImplementation
            // drawerContent={(props) => <CustomDrawerContent {...props} />}
            initialRouteName={"Home"}
            drawerContent={(props) => <DrawerContent {...props} />}
          >
            <Drawer.Screen
              name="ContentNav"
              component={ContentNavigator}
              activeTintColor={store.primaryColor}
              inactiveTintColor={store.primaryColor}
              activeBackgroundColor={store.primaryColor}
              options={({ route }) => ({
                headerShown: false,
                // store.getScreen() === 'Restaurant' || store.screenType === 'desktop'
                //   ? false
                //   : 'float',
                headerTransparent: true,
                headerLeft: () => (
                  <TouchableOpacity
                    onPress={() =>
                      navigationRef.current.dispatch(
                        DrawerActions.toggleDrawer()
                      )
                    }
                    style={{ padding: 10 }}
                  >
                    <Ionicons name="ios-menu" size={24} color="#fff" />
                  </TouchableOpacity>
                ),
                // headerLeft: () => <NavigationDrawerStructure navigationProps={navigation} />,
                headerStyle: {
                  backgroundColor: "transparent", //Set Header color
                },
                headerTintColor: "transparent", //Set Header text color
                headerTitleStyle: {
                  opacity: 0,
                  // fontWeight: 'bold', //Set Header text style
                },
                // headerStyle: {
                //   borderBottomWidth: 0,
                //   backgroundColor: "#FF9000",
                // },
                // navigationOptions
                drawerActiveTintColor: "#fff",
                drawerInactiveTintColor: "#333",
                title: "Home",
                drawerIcon: ({ focused, size }) => (
                  <MaterialCommunityIcons
                    name="home"
                    size={size}
                    color={focused ? "#fff" : "#ccc"}
                  />
                ),
              })}
            />
          </Drawer.Navigator>
        </NavigationContainer>
      </View>
    </View>
  );
});

export default Navigation;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function getTitle(screen) {
  var title = screen.name.replace("Stack", "");
  if (screen.params && typeof screen.params.slug !== "undefined")
    title = screen.params.slug
      .split("-")
      .join(" ")
      .split("_")
      .join(" ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

  if (title === "Splash") title = "Order Direct & Support Local Restaurants";

  title = Platform.select({
    web: `${store.getTitle()} | ${title
      .split("-")
      .join(" ")
      .split("_")
      .join(" ")}`,
    default: title,
  });

  return title;
}
function getTitleReal(screen) {
  var title = screen.name.replace("Stack", "");
  if (screen.params && typeof screen.params.slug !== "undefined") {
    title = screen.params.slug
      .split("-")
      .join(" ")
      .split("_")
      .join(" ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    // const words = screen.params.slug.split('-');

    // for (let i = 0; i < words.length; i++) {
    //   words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    // }

    // words.join(' ');
    // title = words;
  }

  title = Platform.select({
    web: `${store.getTitle()} | ${title
      .split("-")
      .join(" ")
      .split("_")
      .join(" ")}`,
    default: title,
  });
  store.setMetaTitle(title);

  return title;
  // return Platform.select({
  //   web: `${store.getTitle()} | ${title}`,
  //   default: title,
  // });
  // console.log(store.getScreen());
  // store.setSubtitle(screen.name);
  // return store.getTitle() + ' ' + store.getScreen();
}

const Header = observer(({ props, route, navigation }) => {
  return (
    <Helmet>
      {/* <title>{store.getTitle()}</title> */}
      <meta name="application-name" content={store.metaTitle} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={store.metaTitle} />
      <meta name="description" content={store.description} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content={store.backgroundColor} />
      <meta name="og:site_name" content={store.metaTitle} />
      <meta name="og:title" content={store.metaTitle} />
      <meta name="og:description" content={store.description} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={store.domain} />
      <meta
        name="og:image"
        content={
          store.metaImage &&
          store.metaImage != "" &&
          store.metaImage !== store.imageDirectory
            ? store.getImageDirectory(store.metaImage)
            : store.getImageDirectory(store.logoImageUrl)
        }
      />
      <meta
        http-equiv="Content-Security-Policy"
        content="upgrade-insecure-requests"
      />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={store.getImageDirectory(store.favIconUrl)}
      />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="shortcut icon"
        href={store.getImageDirectory(store.favIconUrl)}
      />
    </Helmet>
  );
});

function getIcon(icon_type, icon, color, size = 20) {
  if (icon.indexOf(".png") > 0 || icon.indexOf(".svg") > 0) {
    return (
      <Image
        source={{ uri: icon }}
        style={{ width: size * 1.2, height: size * 1.2, tintColor: color }}
        resizeMode="contain"
      />
    );
  } else if (icon_type.indexOf("AntDesign") > -1) {
    return <AntDesign name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Octicons") > -1) {
    return <Octicons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Entypo") > -1) {
    return <Entypo name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("EvilIcons") > -1) {
    return <EvilIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Feather") > -1) {
    return <Feather name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesomeIcon") > -1) {
    return <FontAwesomeIcon icon={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesome5") > -1) {
    return <FontAwesome5 name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesome") > -1) {
    return <FontAwesome name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Foundation") > -1) {
    return <Foundation name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Ionicons") > -1) {
    return <Ionicons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("MaterialCommunityIcons") > -1) {
    return <MaterialCommunityIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("MaterialIcons") > -1) {
    return <MaterialIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("SimpleLineIcons") > -1) {
    return <SimpleLineIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Zocial") > -1) {
    return <Zocial name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Fontisto") > -1) {
    return <Fontisto name={icon} color={color} size={size} />;
  }
}
