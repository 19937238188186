// Formik x React Native example
import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  TextInput,
  View,
  Text,
  StyleSheet,
  Image,
  ImageBackground,
  FlatList,
  Platform,
} from "react-native";
import moment from "moment";
import { Modalize } from "react-native-modalize";
import { Host, Portal } from "react-native-portalize";

import {
  MaterialCommunityIcons,
  Entypo,
  Ionicons as Ionic,
  AntDesign,
} from "@expo/vector-icons";
import { observer } from "mobx-react";
import Swiper from "react-native-deck-swiper";
import {
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native-gesture-handler";
import ReadMore from "react-native-read-more-text";
import * as Linking from "expo-linking";

import { RestaurantDetails } from "../screens/RestaurantScreen";
import * as RootNavigation from "../navigation/RootNavigation";
import { Store, ViewAgenda } from "@material-ui/icons";

export const PostsScreen = observer(({ route, navigation }) => {
  var itemWidth = store.screenWidth > 800 ? 800 : store.screenWidth - 40;
  var itemHeight =
    store.screenWidth > 800
      ? store.screenHeight - 150
      : store.screenHeight - 180;

  const [postFeed, setPostFeed] = useState([]);
  const [noItems, setNoItems] = useState(0);

  const [display, setDisplay] = useState("list");

  const restaurentRef = useRef<Modalize>(null);
  const flatListRef = useRef(null);

  const openRestModal = () => {
    restaurentRef.current?.open();
  };
  const closeRestModal = () => {
    restaurentRef.current?.close();
  };

  const updatePostFeed = () => {
    var noPosts = display === "flick" ? 50 : 20;
    var tempPosts = postFeed;
    var tempPostsNew = store.getSortedPosts(
      postFeed.length,
      postFeed.length + noPosts
    );

    tempPosts = tempPosts.concat(tempPostsNew);
    setNoItems((prev) => tempPosts.length);
    setPostFeed((prev) => tempPosts);
  };

  const scrollToIndex = (index) => {
    flatListRef.current.scrollToIndex({ animated: true, index: index });
  };

  useEffect(() => {
    if (route && route.params && route.params.id) {
      setTimeout(() => {
        scrollToIndex(parseInt(route.params.id));
      }, 1500);
    }
  }, [route.params]);

  React.useEffect(() => {
    document.addEventListener("keydown", function (e) {
      switch (e.keyCode) {
        case 37:
          handleOnSwipedLeft();
          break;
        case 38:
          handleOnSwipedTop();
          break;
        case 39:
          handleOnSwipedRight();
          break;
        case 40:
          handleOnSwipedBottom();
          break;
      }
    });
    updatePostFeed();
  }, []);

  const useSwiper = useRef(null);

  const handleOnSwipedLeft = () => useSwiper.current.swipeLeft();
  const handleOnSwipedTop = () => useSwiper.current.swipeTop();
  const handleOnSwipedRight = () => useSwiper.current.swipeRight();
  const handleOnSwipedBottom = () => useSwiper.current.swipeBottom();

  const renderCard = (card, index) => {
    const item = card;
    const rest = store.restaurants.get(item.store);
    return (
      <View key={index}>
        <PostObject
          itemHeight={itemHeight}
          itemWidth={itemWidth}
          item={item}
          openRestModal={openRestModal}
          closeRestModal={closeRestModal}
        />
      </View>
    );
  };
  const renderItem = ({ item, index }) => {
    return (
      <View key={index}>
        <PostObject
          itemHeight={itemHeight}
          itemWidth={itemWidth}
          item={item}
          openRestModal={openRestModal}
          closeRestModal={closeRestModal}
        />
      </View>
    );
  };
  var swiperRef = useRef();

  if (!postFeed || postFeed.length === 0) return <View></View>;

  return (
    <View style={{ flex: 1, backgroundColor: store.primaryColor }}>
      <View
        style={{
          flex: 1,
          marginTop: 15,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            maxHeight: 70,
            marginBottom: 10,
          }}
        >
          <View style={{ flex: 0.5, alignItems: "flex-start" }}>
            <TouchableOpacity
              onPress={() => {
                store.goBack();
              }}
              style={{ paddingLeft: 10 }}
              // style={{ position: "absolute", top: 10, left: 10, zIndex: 1000 }}
            >
              <Entypo name="chevron-left" size={44} color={"#fff"} />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 0.5,
              padding: 5,
              paddingRight: 10,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              onPress={(e) => {
                setDisplay(display === "flick" ? "list" : "flick");
              }}
              style={{ width: 40 }}
            >
              <MaterialCommunityIcons
                name={display === "list" ? "gesture-swipe" : "cards-variant"}
                size={32}
                color="#fff"
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={(e) => {
                store.updateKey();
              }}
              style={{ width: 40 }}
            >
              <MaterialCommunityIcons name="restart" size={36} color="#fff" />
            </TouchableOpacity>
          </View>
        </View>
        {display == "list" ? (
          <FlatList
            ref={flatListRef}
            showsVerticalScrollIndicator={false}
            useNativeDriver={true}
            key={Store.key}
            numColumns={1}
            initialNumToRender={store.screenWidth > 800 ? 10 : 10}
            maxToRenderPerBatch={10}
            windowSize={10}
            scrollEventThrottle={16}
            // data={postFeed}
            data={store.sortedFeed}
            renderItem={renderItem}
            keyExtractor={(item, index) => `list-item-${index}-${item.color}`}
            onEndReachedThreshold={1}
            onEndReached={(e) => {
              // updatePostFeed();
            }}
          />
        ) : (
          <ScrollView
            style={{
              maxHeight:
                store.screenWidth > 800 ? itemHeight : store.screenHeight - 120,
              minHeight:
                store.screenWidth > 800 ? itemHeight : store.screenHeight - 120,
              width: store.screenWidth,
            }}
            contentContainerStyle={{
              flex: 1,
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "center ",
            }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <Swiper
              key={store.key}
              ref={useSwiper}
              // goBackToPreviousCardOnSwipeLeft={true}
              // animateCardOpacity
              cardIndex={0}
              // swipeBackCard={true}
              // infinite
              showSecondCard
              // animateOverlayLabelsOpacity
              cards={postFeed}
              // cards={["DO", "MORE", "OF", "WHAT", "MAKES", "YOU", "HAPPY"]}
              renderCard={renderCard}
              onSwiped={(cardIndex) => {
                console.log(noItems, cardIndex, noItems - cardIndex);
                if (noItems - cardIndex <= 5) {
                  updatePostFeed();
                }
              }}
              onSwipedAll={() => {}}
              backgroundColor={store.primaryColor}
              stackSize={5}
            ></Swiper>
          </ScrollView>
        )}
      </View>
      <Portal>
        <Modalize
          ref={restaurentRef}
          modalTopOffset={20}
          onClose={(e) => {
            store.setSelectedRestaurantId("");
            store.setSelectedProductId("");
          }}
          modalStyle={{
            backgroundColor: store.selectedRestaurant.backgroundColor,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <View
            style={{
              height: store.screenHeight - 10, //"100%",
              width: "100%",
              marginTop: 10,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              backgroundColor: store.selectedRestaurant.backgroundColor,
            }}
          >
            <RestaurantDetails
              inline={true}
              openRestModal={openRestModal}
              closeRestModal={closeRestModal}
            />
          </View>
        </Modalize>
      </Portal>
    </View>
  );
});

const PostObject = observer(
  ({ itemHeight, itemWidth, openRestModal, item }) => {
    const [postImage, setPostImage] = useState();
    const [showPost, setShowPost] = useState(true);
    const [showVideo, setShowVideo] = useState(false);
    const [mute, setMute] = useState(false);
    const [autoPlay, setAutoPlay] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
      // if (item.originalImage && item.originalImage !== "") {
      //   store.getCorsImage(item.originalImage).then((myBase64) => {
      //     setPostImage(myBase64);
      //   });
      // } else {
      setPostImage(store.getImageDirectory(item.image));
      // }
    }, []);

    const rest = store.restaurants.get(item.storeSlug);
    const _renderTruncatedFooter = (handlePress) => {
      return (
        <Text style={{ color: "#666", marginTop: 5 }} onPress={handlePress}>
          Read more
        </Text>
      );
    };

    const _renderRevealedFooter = (handlePress) => {
      return (
        <Text style={{ color: "#666", marginTop: 5 }} onPress={handlePress}>
          Show less
        </Text>
      );
    };

    function openRestaurant() {
      if (rest.orderingLink !== "") {
        if (rest.newWindow) window.open(rest.orderingLink, "_blank").focus();
        else {
          store.setSelectedRestaurantId(rest.id);
          openRestModal();
        }
      } else if (Platform.OS === "web") window.open(rest.messenger);
      else Linking.openURL("fb://facewebmodal/f?href=" + rest.messenger);
    }

    if (!postImage || postImage == "" || !showPost) return <View></View>;

    // console.log(postImage);

    return (
      <View
        style={{
          // width: itemWidth,
          // minHeight: itemHeight,

          borderWidth: 2,
          backgroundColor: "#fff",
          borderColor: "#fff", // store.primaryColor,
          width: store.screenWidth > 800 ? 600 : itemWidth,
          // minHeight: itemHeight,
          maxHeight: itemHeight,
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: 15,
          margin: 5,
          opacity: 1,
          flexGrow: 1,
          // opacity: !item.isAvailable ? 0.4 : 1,
        }}
      >
        <TouchableWithoutFeedback
          onPress={(e) => {
            if (item.video !== "" && item.originalVideo !== "") {
              if (showVideo) {
                setMute(!mute);
              } else {
                setShowVideo(true);
              }
              setAutoPlay(true);
              videoRef.current.play();
            }
          }}
          style={{
            flex: 1,
            width: store.screenWidth > 800 ? 600 : itemWidth - 4,
            minHeight: itemHeight * 0.67,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            justifyContent: "flex-end",
          }}
        >
          {(item.originalImage == "" && item.originalVideo !== "") ||
          showVideo ? (
            <View
              style={{
                width: "100%", //itemWidth,
                height: itemHeight * 0.67,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                justifyContent: "flex-end",
              }}
            >
              <video
                ref={videoRef}
                height="100%"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  zIndex: 0,
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                }}
                onEnded={(e) => {
                  setShowVideo(false);
                }}
                controls={false}
                autoPlay={autoPlay}
                loop={false}
                muted={mute}
                playsInline
                crossOrigin="anonymous"
                poster={
                  "https://cors.foood.vn/" + store.getImageDirectory(item.image)
                }
              >
                <source
                  // src={postVideo}
                  src={
                    //   "https://cors.foood.vn/" +
                    //   "https://images.foood.vn/restaurants/cheesecake-ngon/posts/cheesecake-ngon-1659667597.mp4"
                    "https://cors.foood.vn/" +
                    store.getImageDirectory(item.video)
                  }
                  type="video/mp4"
                />
              </video>
              {item.originalVideo !== "" && !autoPlay && (
                <TouchableOpacity
                  style={{
                    position: "absolute",
                    alignSelf: "center",
                    top: -(itemHeight * 0.45),
                    zIndex: 100,
                    // backgroundColor: "rgba(52,52,52,0.6)",
                    // borderRadius: "50%",
                    padding: 10,
                  }}
                  onPress={(e) => {
                    setShowVideo(true);
                    setAutoPlay(true);
                    videoRef.current.play();
                  }}
                >
                  <Ionic
                    name="ios-play-circle"
                    style={{
                      fontSize: 100,
                      color: "rgba(200,200,200,0.8)",
                      padding: 0,
                      // marginLeft: -20,
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
          ) : (
            <ImageBackground
              style={{
                width: "100%", //itemWidth,
                height: itemHeight * 0.67,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                justifyContent: "flex-end",
              }}
              imageStyle={{
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                resizeMode: "cover",
                alignSelf: "flex-start",
              }}
              resizeMode="cover"
              source={{
                // uri:
                // "https://cors.foood.vn/" +
                // store.getImageDirectory(item.image),
                uri: postImage,
              }}
              onError={(e) => {
                if (e.nativeEvent.error.indexOf("/restaurants/") == -1)
                  console.log(postImage);
                // setPostImage(
                //   "https://cors.foood.vn/" +
                //     store.getImageDirectory(item.image)
                // );
                else {
                  // console.log("hide post", item.storeSlug, e);
                  // setShowPost(false);
                }
              }}
            >
              {item.originalVideo !== "" && !showVideo && (
                <TouchableOpacity
                  style={{
                    position: "absolute",
                    alignSelf: "center",
                    top: -(itemHeight * 0.45),
                    zIndex: 100,
                    // backgroundColor: "rgba(52,52,52,0.6)",
                    // borderRadius: "50%",
                    padding: 10,
                  }}
                  onPress={(e) => {
                    setShowVideo(true);
                  }}
                >
                  <Ionic
                    name="ios-play-circle"
                    style={{
                      fontSize: 100,
                      color: "rgba(255,255,255,0.8)",
                      padding: 0,
                      // marginLeft: -20,
                    }}
                  />
                </TouchableOpacity>
              )}
              {item.logoImageUrl && (
                <View
                  style={{
                    position: "absolute",
                    top: -5,
                    left: 5,
                    zIndex: 2,
                  }}
                >
                  <TouchableOpacity
                    onPress={(e) => {
                      if (rest.newWindow)
                        if (Platform.OS === "web")
                          window.open(item.link, "_blank").focus();
                        else Linking.openURL(item.link);
                      else openRestaurant();
                    }}
                  >
                    <View
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: 100,
                        backgroundColor:
                          item.logoImageBG !== ""
                            ? item.logoImageBG
                            : "transparent",
                        margin: 10,
                      }}
                    >
                      <Image
                        source={store.getImageDirectory(item.logoImageUrl)}
                        style={{
                          width: "100%",
                          height: "100%",
                          resizeMode: "cover",
                          borderRadius: 100,
                        }}
                        resizeMode="contain"
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              )}
              <View
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  zIndex: 2,
                }}
              >
                <TouchableOpacity
                  onPress={(e) => {
                    if (Platform.OS === "web")
                      window.open(item.link, "_blank").focus();
                    else Linking.openURL(item.link);
                  }}
                >
                  <AntDesign
                    name={"instagram"}
                    style={{ color: "#fff", fontSize: 35 }}
                  />
                </TouchableOpacity>
              </View>
            </ImageBackground>
          )}
        </TouchableWithoutFeedback>
        <View
          style={{
            // flex: 1,
            width: "100%",
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            justifyContent: "flex-end",
            position: "relative",
            zIndex: 1,
            paddingBottom: 5,
          }}
        >
          <View
            style={{
              maxHeight: itemHeight * 0.33,
              justifyContent: "flex-end",
            }}
          >
            <View
              style={
                {
                  // width: "100%",
                  // alignSelf: "flex-end",
                }
              }
              contentContainerStyle={{
                flexGrow: 1,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: "rgba(255,255,255, 1)",
                justifyContent: "flex-end",
                padding: 20,
              }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={true}
            >
              <ScrollView
                style={{ flex: 1, maxHeight: itemHeight * 0.3, margin: 10 }}
              >
                <Text
                  style={{
                    // fontWeight: "bold",
                    fontSize: 10,
                    fontFamily: "Roboto_400Regular",
                    color: "#999",
                    textTransform: "uppercase",
                    marginBottom: 3,
                  }}
                >
                  {moment(item.date).fromNow()}
                </Text>
                <TouchableWithoutFeedback
                  onPress={(e) => {
                    if (rest.newWindow)
                      if (Platform.OS === "web")
                        window.open(item.link, "_blank").focus();
                      else Linking.openURL(item.link);
                    else openRestaurant();
                  }}
                >
                  <Text
                    style={{
                      // fontWeight: "bold",
                      fontSize: 16,
                      fontFamily: store.font,
                      color: "#333",
                      marginBottom: 2,
                    }}
                    numberOfLines={1}
                  >
                    {item.title ? item.title : rest.title}
                  </Text>
                </TouchableWithoutFeedback>
                <ReadMore
                  numberOfLines={store.screenWidth > 800 ? 7 : 4}
                  renderTruncatedFooter={_renderTruncatedFooter}
                  renderRevealedFooter={_renderRevealedFooter}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: "Roboto_400Regular",
                      color: "#333",
                      width: "100%",
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    {item.text}
                  </Text>
                </ReadMore>
              </ScrollView>
            </View>
          </View>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F5FCFF",
  },
  card: {
    flex: 1,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: "#E8E8E8",
    justifyContent: "center",
    backgroundColor: "white",
  },
  text: {
    textAlign: "center",
    fontSize: 50,
    backgroundColor: "transparent",
  },
});
