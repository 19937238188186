import React, { useRef, useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Image,
  Platform,
} from "react-native";
import { Modalize } from "react-native-modalize";
import { Host, Portal } from "react-native-portalize";
import { inject, observer, Observer } from "mobx-react";
import {
  AntDesign,
  Octicons,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons as Ionic,
  MaterialIcons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  Zocial,
  Fontisto,
} from "@expo/vector-icons";
import ReadMore from "react-native-read-more-text";
import BouncingPreloader from "react-native-bouncing-preloaders";
import * as Linking from "expo-linking";
import moment from "moment";
import { ReviewDialog } from "../components/RestaurantComponents";
import { RestaurantDetails } from "../screens/RestaurantScreen";
import * as RootNavigation from "../navigation/RootNavigation";

// import Ionic from "react-native-vector-icons/Ionicons";

import { SwiperFlatList } from "react-native-swiper-flatlist";

import { Video, AVPlaybackStatus } from "expo-av";

export const VideosScreen = observer(({ route, navigation }) => {
  const [videoReel, setVideoReel] = useState([]);
  const [mute, setMute] = useState(false);
  const restaurentRef = useRef<Modalize>(null);
  const swipeRef = useRef<Modalize>(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = () => {
    restaurentRef.current?.open();
  };
  const closeRestModal = () => {
    restaurentRef.current?.close();
  };

  const updateReel = () => {
    var tempReel = videoReel;
    var tempReelNew = store.getSortedReels(currentIndex, currentIndex + 10);
    tempReel = tempReel.concat(tempReelNew);

    setVideoReel((prev) => tempReel);
  };

  const handleChangeIndexValue = ({ index }) => {
    setCurrentIndex(index);
    // setVideoReel(store.sortedReels);
  };
  useEffect(() => {
    updateReel();
    setTimeout(() => {
      window.swipe = swipeRef.current;
      //   swipeRef.current.goToFirstIndex();
    }, 1500);
  }, []);

  if (!videoReel || videoReel.length === 0) return <View></View>;
  return (
    <View style={{ flex: 1 }}>
      <SwiperFlatList
        ref={swipeRef}
        disableVirtualization={false}
        initialNumToRender={5}
        initialScrollIndex={0}
        index={0}
        maxToRenderPerBatch={5}
        numColumns={1}
        pagingEnabled={true}
        removeClippedSubviews={false}
        scrollEnabled={true}
        scrollEventThrottle={50}
        windowSize={5}
        vertical={true}
        onChangeIndex={handleChangeIndexValue}
        data={store.sortedReels} //{videoReel}
        renderItem={({ item, index }) => (
          <SingleReel
            item={item}
            index={index}
            currentIndex={currentIndex}
            openModal={openModal}
            closeRestModal={closeRestModal}
            mute={mute}
            setMute={setMute}
          />
        )}
        onEndReachedThreshold={1}
        onEndReached={(e) => {
          updateReel();
        }}
        keyExtractor={(item, index) => index}
        style={{ zIndex: 1 }}
      />
      <TouchableOpacity
        onPress={() => {
          setVideoReel([]);
          store.goBack();
        }}
        style={{ position: "absolute", top: 10, left: 10, zIndex: 1000 }}
      >
        <Entypo name="chevron-left" size={48} color={"#fff"} />
      </TouchableOpacity>
      {/* <View
        style={{
          position: "absolute",
          alignSelf: "center",
          top: 0,
          zIndex: 100,
        }}
      >
        <TouchableWithoutFeedback
          onPress={(e) => {
            if (store.screen !== "Restaurant") {
              store.setSelectedRestaurantId("");
              store.setSelectedProductId("");
              RootNavigation.navigate("Tabs", {
                screen: "HomeStack",
                params: {
                  screen: "Home",
                },
              });
            }
          }}
        >
          <Image
            source={{
              uri: store.logoImageUrl,
            }}
            resizeMode="contain"
            style={{
              tintColor: "#fff",
              minHeight: 60,
              width: 100, //"100%",
              maxWidth: 100,
            }}
          />
        </TouchableWithoutFeedback>
      </View> */}
      {store.selectedRestaurant && (
        <ReviewDialog restaurant={store.selectedRestaurant} />
      )}
      <Portal>
        <Modalize
          ref={restaurentRef}
          modalTopOffset={20}
          onClose={(e) => {
            store.setSelectedRestaurantId("");
            store.setSelectedProductId("");
          }}
          modalStyle={{
            backgroundColor: store.selectedRestaurant.backgroundColor,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <View
            style={{
              height: store.screenHeight - 10, //"100%",
              width: "100%",
              marginTop: 10,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              backgroundColor: store.selectedRestaurant.backgroundColor,
            }}
          >
            <RestaurantDetails
              inline={true}
              openModal={openModal}
              closeRestModal={closeRestModal}
            />
          </View>
        </Modalize>
      </Portal>
    </View>
  );
});

const SingleReel = observer(
  ({ item, index, currentIndex, openModal, mute, setMute }) => {
    const [postVideo, setPostVideo] = useState("");
    const [postImage, setPostImage] = useState("");
    const windowWidth = Dimensions.get("window").width;
    const windowHeight = Dimensions.get("window").height;

    const rest = store.restaurants.get(item.store);
    const videoRef = useRef(null);

    const onBuffer = (buffer) => {
      console.log("buffering", buffer);
    };
    const onError = (error) => {
      console.log("error", error);
    };

    useEffect(() => {
      if (!postImage || postImage === "") {
        if (item.originalImage && item.originalImage !== "") {
          store.getCorsImage(item.originalImage).then((myBase64) => {
            if (myBase64.length < 100)
              setPostImage(
                "https://cors.foood.vn/" + store.getImageDirectory(item.image)
              );
            else setPostImage(myBase64);
          });
        } else {
          console.log(store.getImageDirectory(item.image));
          setPostImage(
            "https://cors.foood.vn/" + store.getImageDirectory(item.image)
          );
        }

        if (currentIndex === index && (!postVideo || postVideo == "")) {
          if (item.originalVideo && item.originalVideo !== "") {
            store.getCorsVideo(item.originalVideo).then((myBase64) => {
              if (myBase64.length < 100)
                setPostVideo(
                  "https://cors.foood.vn/" + store.getImageDirectory(item.video)
                );
              else setPostVideo(myBase64);
            });
          } else {
            setPostVideo(
              "https://cors.foood.vn/" + store.getImageDirectory(item.video)
            );
          }
        }
      }
    }, []);

    function updateVideoState() {
      // console.log("updateVideoState:");

      if (
        (Platform.OS == "web" || status.isPlaying) &&
        currentIndex != index
        //  &&
        // postVideo &&
        // postVideo !== ""
      ) {
        if (Platform.OS !== "web") videoRef.current.pauseAsync();
        else videoRef.current.pause();
      } else if (
        (Platform.OS == "web" || !status.isPlaying) &&
        currentIndex + 2 >= index &&
        (!postVideo || postVideo == "")
      ) {
        if (Platform.OS !== "web") videoRef.current.playAsync();
        else {
          if (item.originalVideo && item.originalVideo !== "") {
            store.getCorsVideo(item.originalVideo).then((myBase64) => {
              if (myBase64.length < 100)
                setPostVideo(
                  "https://cors.foood.vn/" + store.getImageDirectory(item.video)
                );
              else setPostVideo(myBase64);
            });
          } else {
            setPostVideo(
              "https://cors.foood.vn/" + store.getImageDirectory(item.video)
            );
          }
        }
      }

      if (
        (Platform.OS == "web" || !status.isPlaying) &&
        currentIndex === index &&
        postVideo &&
        postVideo !== ""
      ) {
        if (Platform.OS !== "web") videoRef.current.playAsync();
        else {
          setTimeout(() => {
            videoRef.current.play();
          }, 500);
        }
      }
    }

    function openRestaurant() {
      videoRef.current.pause();
      if (rest.orderingLink !== "") {
        if (rest.newWindow) window.open(rest.orderingLink, "_blank").focus();
        else {
          store.setSelectedRestaurantId(rest.id);
          openModal();
        }
      } else if (Platform.OS === "web") window.open(rest.messenger);
      else Linking.openURL("fb://facewebmodal/f?href=" + rest.messenger);
    }

    const _renderTruncatedFooter = (handlePress) => {
      return (
        <Text
          style={{ color: "#fff", marginTop: 5, fontSize: 12 }}
          onPress={handlePress}
        >
          Read more
        </Text>
      );
    };

    const _renderRevealedFooter = (handlePress) => {
      return (
        <Text
          style={{ color: "#fff", marginTop: 5, fontSize: 12 }}
          onPress={handlePress}
        >
          Show less
        </Text>
      );
    };

    useEffect(() => {
      updateVideoState();
    }, [currentIndex]);

    const [showPost, setShowPost] = useState(true);
    const [status, setStatus] = React.useState({});
    const [like, setLike] = useState(item.isLike);

    // if ((!postImage && !postVideo) || (postImage === "" && postVideo == ""))
    //   return (
    //     <BouncingPreloader
    //       icons={[store.logoImageUrl]}
    //       leftRotation="-680deg"
    //       rightRotation="360deg"
    //       leftDistance={-180}
    //       rightDistance={-250}
    //       size={200}
    //       speed={2500}
    //     />
    //   );

    if (!showPost) return <View></View>;

    return (
      <View
        style={{
          width: windowWidth,
          height: windowHeight,
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
        }}
        key={
          (postImage ? postImage.substring(0, 20) : "") +
          (postVideo ? postVideo.substring(0, 20) : "")
        }
      >
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={() => {
            setMute((prev) => !mute);
            if (Platform.OS !== "web") videoRef.current.playAsync();
            else videoRef.current.play();
          }}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        >
          {Platform.OS !== "web" ? (
            <Video
              ref={videoRef}
              onBuffer={onBuffer}
              onError={onError}
              isLooping
              repeat={true}
              resizeMode="cover"
              shouldPlay={currentIndex == index ? true : false}
              source={store.getImageDirectory(
                "https://cors.foood.vn/" + item.originalVideo
              )}
              isMuted={mute}
              onPlaybackStatusUpdate={(status) => {
                console.log("onPlaybackStatusUpdate:");
                setStatus(() => status);
                if (status.isPlaying && currentIndex !== index)
                  updateVideoState();
                // if (status.isPlaying && currentIndex != index) {
                //   videoRef.current.pauseAsync();
                //   console.log("pausing");
                // }
                // console.log(status, currentIndex, index);
                // if (currentIndex == index ? false : true)
                //   videoRef.current.pauseAsync();
                // else videoRef.current.playAsync();
              }}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            />
          ) : (
            <video
              onError={(e) => {
                if (postVideo && postVideo.length > 1000)
                  setPostVideo(
                    "https://cors.foood.vn/" +
                      store.getImageDirectory(item.video)
                  );
                else if (postVideo.length > 50) setShowPost(false);
              }}
              // poster={
              //   "https://cors.foood.vn/" + store.getImageDirectory(item.image)
              // }
              poster={postImage}
              // key={postImage.substring(0, 20).replace(" ", "")}
              ref={videoRef}
              height="100%"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                zIndex: 1,
              }}
              controls={false}
              autoPlay={currentIndex == index ? true : false}
              loop
              muted={mute}
              playsInline
              crossOrigin="anonymous"
            >
              <source
                src={postVideo}
                // src={
                //   "https://cors.foood.vn/" + store.getImageDirectory(item.video)
                // }
                type="video/mp4"
              />
            </video>
          )}
        </TouchableOpacity>
        <TouchableOpacity
          onPress={(e) => {
            setMute((prev) => false);
          }}
        >
          <Ionic
            name="volume-mute"
            style={{
              fontSize: mute ? 20 : 0,
              color: "white",
              position: "absolute",
              backgroundColor: "rgba(52,52,52,0.6)",
              borderRadius: 100,
              padding: mute ? 20 : 0,
              marginLeft: -20,
            }}
          />
        </TouchableOpacity>
        <View
          style={{
            position: "absolute",
            width: windowWidth,
            zIndex: 1,
            bottom: 0, //edited
            padding: 1,
          }}
        >
          <View
            style={{
              backgroundColor: "rgba(0,0,0,0.3)",
              borderRadius: 5,
              padding: 3,
              margin: 2,
              paddingHorizontal: 12,
            }}
          >
            <TouchableOpacity
              style={{ width: "90%" }}
              onPress={(e) => {
                openRestaurant();
              }}
            >
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {/* <View
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: 100,
                    backgroundColor:
                      rest.logoImageBG !== ""
                        ? rest.logoImageBG
                        : "transparent",
                    marginVertical: 6,
                  }}
                >
                  <Image
                    source={store.getImageDirectory(rest.logoImageUrl)}
                    style={{
                      width: "100%",
                      height: "100%",
                      resizeMode: "cover",
                      borderRadius: 100,
                    }}
                    resizeMode="contain"
                  />
                </View> */}
                <Text
                  style={{
                    color: "white",
                    fontSize: 16,
                    fontFamily: store.font,
                  }}
                  numberOfLines={1}
                >
                  {item.title}
                </Text>
              </View>
            </TouchableOpacity>
            <View style={{ flexDirection: "row" }}>
              <TouchableWithoutFeedback
                onPress={(e) => {
                  // RootNavigation.navigate("Restaurant", {
                  RootNavigation.navigate("Tabs", {
                    screen: "RestaurantsStack",
                    params: {
                      screen: "Restaurant",
                      params: { id: item.store },
                    },
                  });
                }}
              >
                <Text
                  style={{
                    // fontWeight: "bold",
                    fontSize: 16,
                    fontFamily: store.font,
                    color: "#fff",
                    marginBottom: 4,
                  }}
                  numberOfLines={1}
                >
                  {item.title ? item.title : item.title}
                </Text>
              </TouchableWithoutFeedback>
              <Text
                style={{
                  // fontWeight: "bold",
                  fontSize: 10,
                  fontFamily: "Roboto_400Regular",
                  color: "#efefef",
                  textTransform: "uppercase",
                  marginBottom: 0,
                  marginTop: 4,
                  marginLeft: 5,
                }}
              >
                {"|  " + moment(item.date).fromNow()}
              </Text>
            </View>
            <ReadMore
              numberOfLines={3}
              renderTruncatedFooter={_renderTruncatedFooter}
              renderRevealedFooter={_renderRevealedFooter}
              style={{ color: "#fff" }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 12,
                  fontFamily: "Roboto_400Regular",
                  marginTop: 5,
                }}
              >
                {/* {item.text && decodeURIComponent(item.text)} */}
                {item.text &&
                  (() => {
                    try {
                      return decodeURIComponent(item.text);
                    } catch (error) {
                      return null;
                    }
                  })()}
              </Text>
            </ReadMore>
            <View style={{ flexDirection: "row", padding: 10 }}>
              <TouchableOpacity
                onPress={(e) => {
                  setMute(true);
                  if (Platform.OS === "web")
                    window.open(
                      // "https://www.google.com/maps?saddr=My+Location&daddr=" +
                      //   rest.gps[0] +
                      //   "," +
                      //   rest.gps[1]
                      "https://www.google.com/maps/place/?q=place_id:" +
                        item.placeId
                    );
                  else
                    Linking.openURL(
                      // "https://www.google.com/maps?saddr=My+Location&daddr=" +
                      //   rest.gps[0] +
                      //   "," +
                      //   rest.gps[1]
                      "https://www.google.com/maps/place/?q=place_id:" +
                        item.placeId
                    );
                }}
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Foundation
                  name="marker"
                  style={{ color: "white", fontSize: 16 }}
                />
                <Text style={{ color: "white", marginLeft: 5, fontSize: 12 }}>
                  {rest.label_address}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 140, //edited
            right: 0,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS === "web")
                window.open(item.link, "_blank").focus();
              else Linking.openURL(item.link);
              // setLike(!like);
              // setMute(true);
              // store.setSelectedRestaurantId(rest.id);
              // store.setReviewDialogVisible(!store.reviewDialogVisible);
            }}
            style={{ padding: 10 }}
          >
            <AntDesign
              name={"instagram"}
              style={{ color: like ? "#FCBE01" : "white", fontSize: 25 }}
            />
            <Text
              style={{
                color: "white",
                fontSize: 12,
                textAlign: "center",
                width: 24,
              }}
            >
              {item.views > 1000
                ? Math.round(item.views / 1000) + "k"
                : item.views}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ padding: 10, marginBottom: 5 }}
            onPress={(e) => {
              if (Platform.OS === "web") window.open(rest.messenger);
              else
                Linking.openURL("fb://facewebmodal/f?href=" + rest.messenger);
            }}
          >
            <Fontisto
              name="messenger"
              size={25}
              //<Ionic
              // name="ios-chatbubble-outline"
              style={{ color: "white", fontSize: 25 }}
            />
          </TouchableOpacity>
          {/* <TouchableOpacity
            style={{ padding: 10 }}
            onPress={(e) => {
              openRestaurant();
            }}
          >
            <MaterialCommunityIcons
              name="silverware-fork-knife"
              size={25}
              color="#fff"
            />
          </TouchableOpacity> */}
          {/* <TouchableOpacity style={{ padding: 10 }}>
            <Feather
              name="more-vertical"
              style={{ color: "white", fontSize: 25 }}
            />
          </TouchableOpacity> */}
          <TouchableOpacity
            onPress={(e) => {
              openRestaurant();
            }}
            style={{
              width: 30,
              height: 30,
              borderRadius: 10,
              borderWidth: 2,
              borderColor: "white",
              margin: 10,
              backgroundColor: rest.backgroundColor
                ? rest.backgroundColor
                : "transparent",
            }}
          >
            <Image
              source={store.getImageDirectory(rest.logoImageUrl)}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 10,
                resizeMode: "cover",
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
);
