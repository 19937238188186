import {
  types,
  applySnapshot,
  getEnv,
  destroy,
  getRoot,
  detach,
} from "mobx-state-tree";

import persist from "mst-persist";

import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Updates from "expo-updates";
import { values, toJS } from "mobx";
import Toast from "react-native-toast-message";
import axios from "axios";
import "react-native-get-random-values";
import { Platform, Alert, Vibration, Dimensions } from "react-native";
import { v4 as uuidv4 } from "uuid";
// import { getDatabase, ref, onValue, set } from "firebase/database";
// import firebase from "firebase/app";
import * as RootNavigation from "../navigation/RootNavigation";
import * as storeRef from "../utils/service";
import { RestaurantStore } from "./restaurantStore";

import { getDatabase, ref, onValue, set, get } from "firebase/database";
// import database from '@react-native-firebase/database';

let deferredPrompt;
if (Platform.OS === "web")
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    deferredPrompt = e;
  });
// import cuisinesJSON from "../assets/cuisines.json";

function generateId() {
  return uuidv4();
}

// import { categories, posts } from "../json/videos.js";
// console.log("Constants.manifest.extra", Constants.manifest.extra);

let store = null;
const { width, height } = Dimensions.get("window");

export const Cuisine = types.model("Cuisine", {
  id: types.optional(types.identifier, generateId()),
  slug: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  order: types.optional(types.number, 20),
  color: types.optional(types.string, ""),
  icon: types.optional(types.string, "silverware-fork-knife"),
  iconType: types.optional(types.string, "MaterialCommunityIcons"),
  image: types.optional(types.string, ""),
  desc: types.optional(types.string, ""),
});

export const Product = types.model("Product", {
  id: types.optional(types.identifier, generateId()),
  store: types.optional(types.string, ""),
  storeSlug: types.optional(types.string, ""),
  logoImageUrl: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
  order: types.optional(types.number, 0),
  price: types.optional(types.number, 0),
  directLink: types.optional(types.string, ""),
  category: types.optional(types.array(types.string), [""]),
  image: types.optional(types.string, ""),
  video: types.optional(types.string, ""),
});

export const Reel = types.model("Reel", {
  id: types.optional(types.identifier, generateId()),
  title: types.optional(types.string, ""),
  text: types.optional(types.string, ""),
  link: types.optional(types.string, ""),
  hasAudio: types.optional(types.boolean, true),
  likes: types.optional(types.number, 0),
  views: types.optional(types.number, 0),
  duration: types.optional(types.number, 0),
  date: types.maybe(types.Date),
  image: types.optional(types.string, ""),
  originalImage: types.optional(types.string, ""),
  thumbnail: types.optional(types.string, ""),
  video: types.optional(types.string, ""),
  originalVideo: types.optional(types.string, ""),
  store: types.optional(types.string, ""),
  storeSlug: types.optional(types.string, ""),
  logoImageUrl: types.optional(types.string, ""),
});

export const Post = types.model("Post", {
  id: types.optional(types.identifier, generateId()),
  title: types.optional(types.string, ""),
  text: types.optional(types.string, ""),
  link: types.optional(types.string, ""),
  hasAudio: types.optional(types.boolean, true),
  likes: types.optional(types.number, 0),
  views: types.optional(types.number, 0),
  duration: types.optional(types.number, 0),
  date: types.maybe(types.Date),
  image: types.optional(types.string, ""),
  originalImage: types.optional(types.string, ""),
  thumbnail: types.optional(types.string, ""),
  video: types.optional(types.string, ""),
  originalVideo: types.optional(types.string, ""),
  store: types.optional(types.string, ""),
  storeSlug: types.optional(types.string, ""),
  logoImageUrl: types.optional(types.string, ""),
});

export const Discount = types.model("Discount", {
  id: types.optional(types.identifier, generateId()),
  code: types.optional(types.string, ""),
  store: types.optional(types.string, ""),
  storeSlug: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
  order: types.optional(types.number, 0),
  amount: types.optional(types.string, ""),
  day: types.optional(types.string, ""),
  imageBackgroundColor: types.optional(types.string, "#fff"),
  image: types.optional(types.string, ""),
  video: types.optional(types.string, ""),
});

export const Slide = types.model("Slide", {
  id: types.optional(types.identifier, generateId()),
  order: types.optional(types.number, 0),
  link: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  titleColor: types.optional(types.string, "#fff"),
  timeOfDay: types.optional(types.string, "all"),
  subtitle: types.optional(types.string, ""),
  subtitleColor: types.optional(types.string, "#fff"),
  backgroundColor: types.optional(types.string, ""),
  backgroundImage: types.optional(types.string, ""),
  backgroundOpacity: types.optional(types.string, "0.1"),
  backgroundVideo: types.optional(types.string, ""),
});

export const Location = types.model("Location", {
  id: types.optional(types.identifier, generateId()),
  order: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  vietnamese: types.optional(types.string, ""),
  gps: types.optional(types.array(types.number), [0, 0]),
});

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const Store = types
  .model("Store", {
    restaurantStore: types.optional(RestaurantStore, {
      restaurants: {},
    }),
    isLoading: true,
    title: types.optional(types.string, "Foood App "),
    metaTitle: types.optional(types.string, ""),
    metaImage: types.optional(types.string, ""),
    subtitle: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    showSlideArrows: types.optional(types.boolean, false),
    showSlideDots: types.optional(types.boolean, false),
    slidesAutoPlay: types.optional(types.boolean, true),
    slideDelay: types.optional(types.number, 5),
    font1: types.optional(types.string, "Roboto_700Bold"),
    primaryColor1: types.optional(types.string, "#FE5F2D"),
    secondaryColor1: types.optional(types.string, "#FFCC00"), //"#fff"),
    textColor1: types.optional(types.string, "#000"),
    buttonColor1: types.optional(types.string, "#FE5F2D"),
    buttonTextColor1: types.optional(types.string, "#FFF"),
    backgroundColor1: types.optional(types.string, "#fff"), //"#FFCC00"),
    logoImageUrl1: types.optional(types.string, "/foood.vn/logo.png"),
    font2: types.optional(types.string, "Roboto_700Bold"),
    primaryColor2: types.optional(types.string, "#fff"),
    secondaryColor2: types.optional(types.string, "#fff"),
    buttonColor2: types.optional(types.string, "#fff"),
    buttonTextColor2: types.optional(types.string, "#FFF"),
    backgroundColor2: types.optional(types.string, "#FF8000"),
    logoImageUrl2: types.optional(types.string, "/foood.vn/vietnammm.png"),
    surfaceColor: types.optional(types.string, "#F2F2F2"),
    theme: types.optional(types.string, "1"),
    videoDirectory: types.optional(
      types.string,
      // "http://localhost:12345"
      // "https://imagedelivery.net/IkSv-uxKi1GMhxlEU7FrJw/foood.vn"
      "https://images.foood.vn"
    ), //'https://static.thaodien.me'),
    imageDirectory: types.optional(
      types.string,
      // "http://localhost:12345"
      // "https://imagedelivery.net/IkSv-uxKi1GMhxlEU7FrJw/foood.vn"
      "https://images.foood.vn"
    ), //'https://static.thaodien.me'),
    favIconUrl: types.optional(types.string, ""),
    currency: types.optional(types.string, "vnd"),
    currencyCode: types.optional(types.string, "₫"),
    languages: types.optional(types.array(types.string), [
      "English",
      "Vietnamese",
    ]),
    favs: types.optional(types.array(types.string), []),
    lang: types.optional(types.string, "en"),
    screenType: types.optional(
      types.string,
      width < 800 ? "mobile" : "desktop"
    ),
    domain: "foood.vn",
    screen: "Splash",
    lastScreen: "Splash",
    search: "",
    showSideModal: false,
    showSearch: false,
    showCloseAlert: false,
    submittingForm: false,
    closeAlertVisible: false,
    reviewDialogVisible: false,
    PayPalDialogVisible: false,
    momoDialogVisible: false,
    theme: types.optional(types.string, "1"),
    restaurantId: types.optional(types.string, ""),
    productId: types.optional(types.string, ""),
    postId: types.optional(types.string, ""),
    exchangeRate: 23400,
    exchangeCurrency: "USD",
    currency: "vnd",
    location: "Thao Dien",
    contactForm:
      "https://docs.google.com/forms/d/e/1FAIpQLSfnD9Z_eFmDURNFh40_Y5J-POvt-7FGb-43wZlWFQcFGe1kIA/viewform",
    gps: types.optional(types.array(types.number), [10.8079389, 106.7348868]),
    locationType: types.optional(types.string, "delivers"),
    restaurantsDisplay: types.optional(types.string, "list"),
    // locationItems: types.optional(types.array(types.string), [
    //   "An Phu",
    //   "Phu My Hung",
    //   "District 1",
    //   "District 10",
    //   "District 11",
    //   "District 12",
    //   "District 2",
    //   "District 3",
    //   "District 4",
    //   "District 5",
    //   "District 6",
    //   "District 7",
    //   "District 8",
    //   "District 9",
    //   "District Binh Tan",
    //   "Binh Thanh",
    //   "District Go Vap",
    //   "District Phu Nhuan",
    //   "District Tan Binh",
    //   "District Tan Phu",
    // ]),
    categories: types.optional(types.array(types.string), ["All"]),
    sort: types.optional(types.array(types.string), [
      "default",
      "rating",
      "price",
      "distance",
    ]),
    sortBy: types.optional(types.string, "default"),
    cuisines: types.map(Cuisine),
    products: types.map(Product),
    discounts: types.map(Discount),
    posts: types.map(Post),
    reels: types.map(Reel),
    slides: types.map(Slide),
    locations: types.map(Location),
    // slides: types.optional(types.array(Slide), []),
    splashVideo: types.optional(
      types.string,
      "https://images.foood.vn/foood.vn/splash.mp4"
    ),
    splashTextColor: types.optional(types.string, "#fff"),
    splashTitle: types.optional(types.string, ""),
    splashSubtitle: types.optional(types.string, ""),
    splashBackgroundOpacity: types.optional(types.string, "0.1"),
    screenWidth: types.optional(types.number, width),
    screenHeight: types.optional(types.number, height),
    analytics: types.optional(types.string, ""),
    sheetURL:
      // "http://localhost:8080/index.php?accessKey=659ac858-0971-45ef-8598-014b028eeea1&action=view&file=",
      "https://api.foood.vn/index.php?accessKey=659ac858-0971-45ef-8598-014b028eeea1&action=view&region=vn&file=",
    restaurantsGID: "348653586",
    cuisinesGID: "1594995965",
    locationsGID: "1350477766",
    settingsGID: "1085321732",
    slidesGID: "1874464804",
    key: types.optional(types.Date, new Date().getTime()),
    forceShowInstallPrompt: false,
    lastUpdate: types.optional(types.Date, new Date()),
    splashShown: false,
    // blog: types.optional(BlogStore, {
    //   posts: [],
    // }),
    // view: types.optional(ViewStore, {}),
  })
  .views((self) => ({
    get font() {
      if (self.theme === "2") return self.font2;
      return self.font1;
      // return self.font[self.theme];
    },
    get primaryColor() {
      if (self.selectedRestaurant && self.screen === "Restaurant")
        return self.selectedRestaurant.primaryColor;
      if (self.theme === "2") return self.primaryColor1;
      return self.primaryColor1;
      // return self.primaryColor[self.theme];
    },
    get secondaryColor() {
      if (self.selectedRestaurant && self.screen === "Restaurant")
        return self.selectedRestaurant.secondaryColor;
      if (self.theme === "2") return self.secondaryColor1;
      return self.secondaryColor1;
      // return self.secondaryColor[self.theme];
    },
    get textColor() {
      return self.textColor1;
    },
    get buttonColor() {
      if (self.theme === "2") return self.buttonColor2;
      return self.buttonColor1;
      // return self.buttonColor[self.theme];
    },
    get buttonTextColor() {
      if (self.theme === "2") return self.buttonTextColor2;
      return self.buttonTextColor1;
      // return self.buttonColor[self.theme];
    },
    get backgroundColor() {
      if (self.selectedRestaurant && self.screen === "Restaurant")
        return self.selectedRestaurant.backgroundColor;

      if (self.theme === "2") return self.backgroundColor2;
      return self.backgroundColor1;
      // return self.backgroundColor[self.theme];
    },
    get logoImageUrl() {
      var returnImage = self.logoImageUrl1;
      // return "https://d3i4yxtzktqr9n.cloudfront.net/web-eats-v2/97c43f8974e6c876.svg";
      if (self.selectedRestaurant && self.screen === "Restaurant") {
        if (self.selectedRestaurant.latestLogoImageUrl)
          returnImage = self.selectedRestaurant.latestLogoImageUrl;
        else
          returnImage = self.selectedRestaurant.logoImageUrl.replace(
            "images/stores/",
            "restaurants/"
          );
      } else {
        if (self.theme === "2") returnImage = self.logoImageUrl2;
        else returnImage = self.logoImageUrl1;
      }

      if (returnImage.indexOf("http") === -1)
        returnImage = self.imageDirectory + returnImage;
      // + "/public";
      // return self.logoImageUrl[self.theme];
      return returnImage;
    },

    get fetch() {
      return getEnv(self).fetch;
    },
    get alert() {
      return getEnv(self).alert;
    },
    get sortedCuisines() {
      return values(self.cuisines).sort((a, b) => a.order - b.order);
    },
    get sortedScrollCuisines() {
      if (self.categories.length === 0)
        return values(self.cuisines).sort((a, b) => a.order - b.order);

      return [
        ...values(self.cuisines).filter(
          (val, i) => val.title === "All" || self.categories.includes(val.title)
        ),
        ...values(self.cuisines)
          .filter(
            (val, i) =>
              val.title !== "All" && !self.categories.includes(val.title)
          )
          .sort((a, b) => a.order - b.order),
      ];
    },

    get sortedFeed() {
      return values(self.posts);
      // .sort(
      //     (a, b) => new Date(b.date) - new Date(a.date)
      // );
    },
    get sortedReels() {
      return values(self.reels).sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    },
    get sortedProducts() {
      return values(self.products).sort((a, b) => a.order - b.order);
    },
    get sortedDiscounts() {
      const d = new Date();
      let day = weekday[d.getDay()];
      return values(self.discounts).sort(
        (a, b) =>
          a.day.toLowerCase() == day.toLowerCase() ||
          a.day.toLowerCase() == "any"
      );
      // .reverse();
      // .filter(
      //   (val, i) =>
      //     val.day.toLowerCase() == day.toLowerCase() ||
      //     val.day.toLowerCase() == "any"
      // );
    },
    // get isLoading() {
    //   return self.isLoading;
    // },
    get getSlides() {
      var today = new Date();
      var curHr = today.getHours();
      var timeOfDay = "any";
      if (curHr < 11) {
        timeOfDay = "morning";
      } else if (curHr < 17) {
        timeOfDay = "afternoon";
      } else {
        timeOfDay = "evening";
      }
      return values(self.slides).filter((slide, index) => {
        return slide.timeOfDay === "any" || slide.timeOfDay === timeOfDay;
      });
    },
    get getLocations() {
      return values(self.locations).sort((a, b) => a.order - b.order);
    },
    get restaurants() {
      return self.restaurantStore.restaurants;
    },
    get sortedRandomAvailableRestaurants() {
      return self.restaurantStore.sortedRandomAvailableRestaurants;
    },
    get sortedAvailableRestaurants() {
      return self.restaurantStore.sortedAvailableRestaurants;
    },
    get sortedVideoRestaurants() {
      return self.restaurantStore.sortedAvailableRestaurants.filter(
        (val, i) => val.videoUrl && val.videoUrl !== ""
      );
    },
    get siteMap() {
      // cycle through all restaurants and return a list of all the restaurant names and urls for the sitemap return xml
      var sitemap =
        '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">';
      sitemap =
        sitemap +
        `<url>
          <loc>https://${self.domain}/home</loc>
            <lastmod>${new Date().toISOString()}</lastmod>
            <changefreq>daily</changefreq>
            <priority> 0.8</priority>
          </url>
          <url>
            <loc>https://${self.domain}/restaurants</loc>
            <lastmod>${new Date().toISOString()}</lastmod>
            <changefreq>daily</changefreq>
            <priority> 0.8</priority>
          </url>
          <url>
            <loc>https://${self.domain}/feed</loc>
            <lastmod>${new Date().toISOString()}</lastmod>
            <changefreq>daily</changefreq>
            <priority> 0.8</priority>
          </url>
          <url>
            <loc>https://${self.domain}/reels</loc>
            <lastmod>${new Date().toISOString()}</lastmod>
            <changefreq>daily</changefreq>
            <priority> 0.8</priority>
          </url>
          <url>
            <loc>https://${self.domain}/contact</loc>
            <lastmod>${new Date().toISOString()}</lastmod>
            <changefreq>daily</changefreq>
            <priority> 0.8</priority>
          </url>`;

      // cycle through all the different cateogries and add to sitemap
      values(self.cuisines).map((cuisine) => {
        sitemap =
          sitemap +
          `<url>
          <loc>https://${self.domain}/restaurants/${cuisine.slug}</loc>
          <lastmod>${new Date().toISOString()}</lastmod>
          <changefreq>daily</changefreq>
          <priority> 0.8</priority>
        </url>`;
      });

      values(self.restaurants).map((restaurant) => {
        if (restaurant.orderingLink && restaurant.orderingLink !== "") {
          sitemap =
            sitemap +
            `<url>
            <loc>https://${self.domain}/restaurant/${restaurant.slug}</loc>
            <lastmod>${new Date().toISOString()}</lastmod>
            <changefreq>daily</changefreq>
            <priority> 0.8</priority>
          </url>`;
        }
      });

      sitemap = sitemap + "</urlset>";

      var element = document.createElement("a");
      // set attribute to download xml file and convert variable to string
      element.setAttribute("href", "data:text/plain;charset=utf-8," + sitemap);
      element.setAttribute("download", "sitemap.xml");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    get sortedRandomFeaturedRestaurants() {
      return self.restaurantStore.sortedRandomFeaturedRestaurants;
    },
    get sortedFeaturedRestaurants() {
      return self.restaurantStore.sortedFeaturedRestaurants;
    },
    get selectedRestaurant() {
      if (!self.restaurantId || self.restaurantId === "") return false;
      else
        return self.restaurants.get(self.restaurantId)
          ? self.restaurants.get(self.restaurantId)
          : false;
    },
    get selectedProduct() {
      if (!self.productId || self.productId === "") return false;
      else
        return self.products.get(self.productId)
          ? self.products.get(self.productId)
          : false;
    },
    get selectedPost() {
      if (!self.postId || self.postId === "") return false;
      else
        return self.posts.get(self.postId)
          ? self.posts.get(self.postId)
          : false;
    },
  }))
  .actions((self) => ({
    goBack() {
      if (self.lastScreen == "Restaurants") {
        RootNavigation.navigate("Tabs", {
          screen: "RestaurantsStack",
          params: {
            screen: "Restaurants",
            params: {
              category: "all",
              sort: "default",
            },
          },
        });
      } else if (
        self.lastScreen !== "Home" &&
        self.lastScreen !== "Splash" &&
        self.lastScreen !== "Restaurants" &&
        self.lastScreen !== "Restaurants"
      ) {
        RootNavigation.navigate(self.lastScreen);
      } else {
        RootNavigation.navigate("Tabs", {
          screen: "HomeStack",
          params: {
            screen: "Home",
          },
        });
      }
    },
    getSortedAvailableRestaurants(start = 0, end = 30) {
      return self.sortedAvailableRestaurants.splice(start, end);
    },
    getImageDirectory(file) {
      //  if file is video
      if (
        file.indexOf("http") === -1 &&
        (file.indexOf("mp4") !== -1 || file.indexOf("webm") !== -1)
      )
        return self.videoDirectory + file;

      if (file.indexOf("http") === -1) return self.imageDirectory + file;
      // + "/public";

      return file;
    },
    getCorsImage(url) {
      return new Promise((resolve) => {
        toDataUrl("https://cors.foood.vn/" + url, function (myBase64) {
          resolve(myBase64);
        });
      });
    },
    getCorsVideo(url) {
      return new Promise((resolve) => {
        toDataUrl("https://cors.foood.vn/" + url, function (myBase64) {
          resolve(myBase64);
        });
      });
    },
    getSortedReels(start = 0, end = 10) {
      return values(self.reels)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .splice(start, end);
    },
    getSortedPosts(start = 0, end = 10) {
      return values(self.posts)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .splice(start, end);
    },
    // sortedReels() {
    //     return values(self.reels).sort(
    //         (a, b) => new Date(b.date) - new Date(a.date)
    //     );
    // },
    markLoading(loading) {
      self.isLoading = loading;
    },
    updateLastUpdate() {
      self.lastUpdate = new Date();
    },
    updateKey() {
      self.key = new Date().getTime();
    },
    removeFav(slug = "") {
      var currentFavs = self.favs;
      currentFavs = currentFavs.filter((val, i) => val !== slug);
      self.favs = currentFavs;
    },
    addFav(slug = "") {
      var currentFavs = self.favs;
      currentFavs.push(slug);
      self.favs = currentFavs;
    },
    setFavs(slug = "") {
      var currentFavs = self.favs;
      if (currentFavs.includes(slug))
        currentFavs = currentFavs.filter((val, i) => val !== slug);
      else currentFavs.push(slug);
      self.favs = currentFavs;
    },
    setNotification(
      title = "",
      text = "",
      duration = 4000,
      type = "cart",
      backgroundColor = self.hexToRGB(self.backgroundColor, 0.7)
    ) {
      if (title !== "" || text !== "") {
        Toast.show({
          type: type,
          position: "top",
          text1: title,
          text2: text,
          props: { backgroundColor: backgroundColor, width: 200 },
          visibilityTime: duration,
          autoHide: true,
          topOffset: 5,
          bottomOffset: 40,
          onShow: () => {},
          onHide: () => {},
        });
      }
    },
    setForceShowInstallPrompt(force = true) {
      self.forceShowInstallPrompt = force;
      if (force)
        setTimeout(() => {
          self.forceShowInstallPrompt = false;
        }, 3000);
    },
    getTitle() {
      if (self.subtitle !== "") return self.subtitle + " - " + self.title;
      return self.title;
    },
    setSelectedRestaurantId(id = "") {
      self.restaurantId = id;
    },
    setSelectedProductId(id = "") {
      self.productId = id;
    },
    setSelectedPostId(id = "") {
      self.postId = id;
    },
    setTheme(theme = "1") {
      self.theme = theme;
    },
    setSplashShown(shown = true) {
      self.splashShown = shown;
    },
    setLocationType(locationType = "delivers") {
      self.locationType = locationType;
    },
    setRestaurantsDisplay(restaurantsDisplay = "list") {
      self.restaurantsDisplay = restaurantsDisplay;
    },
    setLocation(location = "District 2") {
      self.location = location;
      var gpsObj = self.getLocations.filter((loc, i) => loc.name === location);
      if (gpsObj.length > 0) {
        self.gps = [parseFloat(gpsObj[0].gps[0]), parseFloat(gpsObj[0].gps[0])];
        self.restaurantStore.updateRestaurantsDistance();
      }
    },
    setGPS(gps = [0, 0]) {
      self.gps = [parseFloat(gps[0]), parseFloat(gps[0])];
    },
    setCategories(cats = []) {
      self.categories = cats;
    },
    setScreenWidth(screenWidth = width) {
      self.screenWidth = screenWidth;
    },
    setScreenHeight(screenHeight = height) {
      self.screenHeight = screenHeight;
    },
    setScreen(screen = "home") {
      if (screen !== "Restaurant" && self.restaurantId) {
        self.setSelectedProductId("");
        self.setSelectedRestaurantId("");
        self.setCategories([]);
        //self.(self.description);
        if (self.logoImage)
          self.setMetaImage(self.getImageDirectory(self.logoImageUrl));
      }

      if (self.screen !== screen) self.setLastScreen(self.screen);
      self.screen = screen;
    },
    setLastScreen(lastScreen = "home") {
      self.lastScreen = lastScreen;
    },
    setSubmittingForm(submittingForm = false) {
      self.submittingForm = submittingForm;
    },
    setShowCloseAlert(showCloseAlert = false) {
      self.showCloseAlert = showCloseAlert;
    },
    setCloseAlertVisible(closeAlertVisible = false) {
      self.closeAlertVisible = closeAlertVisible;
    },
    setReviewDialogVisible(reviewDialogVisible = false) {
      self.reviewDialogVisible = reviewDialogVisible;
    },
    setPaypalDialogVisible(PayPalDialogVisible = false) {
      self.PayPalDialogVisible = PayPalDialogVisible;
    },
    setMomoDialogVisible(momoDialogVisible = false) {
      self.momoDialogVisible = momoDialogVisible;
    },
    getScreen() {
      return self.screen;
    },
    setSortBy(sortBy = "default") {
      self.sortBy = sortBy;
    },
    setSearch(search = "") {
      self.search = search;
    },
    setShowSearch(showSearch = false) {
      self.showSearch = showSearch;
    },
    setShowSideModal(showSideModal = false) {
      self.showSideModal = showSideModal;
    },
    setLang(lang = "en") {
      self.lang = lang;
    },
    setTitle(title) {
      if (typeof title === "string") self.title = title;
    },
    setMetaTitle(metaTitle) {
      if (typeof metaTitle === "string") self.metaTitle = metaTitle;
    },
    setSubtitle(title) {
      self.subtitle = title;
    },
    setDescription(description = "") {
      self.description = description
        .replace(/(<([^>]+)>)/gi, "")
        .substring(0, 200);
    },
    setMetaImage(image) {
      self.metaImage = image;
    },
    searchDomain(domain) {
      return self.restaurantStore.searchDomain(domain);
    },
    searchDiscounts(storeSlug = "") {
      if (storeSlug !== "") {
        var storeDiscounts = self.sortedDiscounts.filter((disc, i) => {
          return (disc.storeSlug = storeSlug);
        });
        if (storeDiscounts.length > 0) return storeDiscounts;
        else return [];
      } else return [];
    },
    hexToRGB(hex, alpha) {
      if (hex) {
        if (hex.length < 7) hex = hex + hex.replace("#", "");
        if (hex.indexOf("#FFF") > -1 || hex.indexOf("#fff") > -1)
          return "rgba(255, 255, 255, " + alpha + ")";
        var r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
          return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
          return "rgb(" + r + ", " + g + ", " + b + ")";
        }
      }
    },
    shuffleArray(array) {
      array.reverse().forEach((item, index) => {
        const j = Math.floor(Math.random() * (index + 1));
        [array[index], array[j]] = [array[j], array[index]];
      });

      return array;
    },
    calculateGPSDistance(lat1, lon1, lat2, lon2) {
      var R = 6371; // km
      var dLat = toRad(lat2 - lat1);
      var dLon = toRad(lon2 - lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    },

    restartAppClear(force = false) {
      try {
        console.log("Restarting & clear app");
        if (Platform.OS === "web" && location) {
          if (force) {
            //  || window.location.href.indexOf("127.0.0.1") === -1) {
            AsyncStorage.clear();
            if (Platform.OS === "web") localStorage.removeItem("iosPwaPrompt");
            location.reload();
          }
        } else {
          AsyncStorage.clear();
          Updates.reloadAsync();
        }
      } catch (error) {
        console.log(error);
      }
    },
    updateCuisines(json) {
      // self.cuisines.clear();
      json.forEach((cuisineJson, i) => {
        // if (!self.cuisines.get(cuisineJson.id)) self.cuisines.put(cuisineJson);
        self.cuisines.put(cuisineJson);
      });
      self.loadProducts();
    },
    clearCuisines() {
      detach(self.cuisines);
      self.cuisines.clear();
    },
    loadCuisines() {
      self.clearCuisines();
      try {
        AsyncStorage.clear();
        const db = getDatabase();
        const reference = ref(
          db,
          self.domain.replaceAll(".", "_") + "/cuisines"
        );
        onValue(reference, (snapshot) => {
          self.updateCuisines(snapshot.val());
        });
        // axios.get(self.sheetURL + "cuisines").then((json) => {
        //   self.updateCuisines(json.data);
        // });
      } catch (err) {
        console.error("Failed to load restaurants ", err);
      }
    },
    clearPosts() {
      detach(self.posts);
      self.posts.clear();
    },
    updatePosts(json) {
      self.clearPosts();
      json
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .splice(0, 150)
        .forEach((postJson, i) => {
          self.posts.put(postJson);
        });
    },
    clearReels() {
      detach(self.reels);
      self.reels.clear();
    },
    updateReels(json) {
      self.clearReels();
      json
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .splice(0, 150)
        .forEach((reelJson, i) => {
          self.reels.put(reelJson);
        });
    },
    updateProducts(json) {
      self.clearProducts();
      json.forEach((productJson, i) => {
        productJson.category = JSON.parse(productJson.category);
        productJson.price = parseInt(productJson.price);

        // if (!self.products.get(productJson.id)) self.products.put(productJson);
        self.products.put(productJson);
      });
      self.loadDiscounts();
    },
    clearProducts() {
      detach(self.products);
      self.products.clear();
    },
    loadProducts() {
      try {
        AsyncStorage.clear();
        const db = getDatabase();
        const reference = ref(
          db,
          self.domain.replaceAll(".", "_") + "/products"
        );
        onValue(reference, (snapshot) => {
          self.updateProducts(snapshot.val());
        });
        // axios.get(self.sheetURL + "products").then((json) => {
        //   self.updateProducts(json.data);
        // });
      } catch (err) {
        console.error("Failed to load restaurants ", err);
      }
    },
    updateDiscounts(json) {
      self.clearDiscounts();
      json.forEach((discountJson, i) => {
        self.discounts.put(discountJson);
      });
      self.loadSlides();
    },
    clearDiscounts() {
      detach(self.discounts);
      self.discounts.clear();
    },
    loadDiscounts() {
      try {
        const db = getDatabase();
        const reference = ref(
          db,
          self.domain.replaceAll(".", "_") + "/discounts"
        );
        onValue(reference, (snapshot) => {
          self.updateDiscounts(snapshot.val());
        });
        // axios.get(self.sheetURL + "discounts").then((json) => {
        //   self.updateDiscounts(json.data);
        // });
      } catch (err) {
        console.error("Failed to load restaurants ", err);
      }
    },
    updateSlides(json) {
      self.clearSlides();
      json.forEach((slideJson, i) => {
        // if (!self.slides.get(slideJson.id))
        self.slides.put(slideJson);
      });
      self.restaurantStore.loadRestaurants();
    },
    clearSlides() {
      detach(self.slides);
      self.slides.clear();
    },
    loadSlides() {
      try {
        const db = getDatabase();
        const reference = ref(db, self.domain.replaceAll(".", "_") + "/slides");
        onValue(reference, (snapshot) => {
          self.updateSlides(snapshot.val());
        });
        // axios.get(self.sheetURL + "slides").then((json) => {
        //   self.updateSlides(json.data);
        //   // self.updateSlides(json.data);
        // });
      } catch (err) {
        console.error("Failed to load restaurants ", err);
      }
    },
    clearLocations() {
      detach(self.locations);
      self.locations.clear();
    },
    updateLocations(json) {
      self.clearLocations();
      json.forEach((locationJson, i) => {
        // if (!self.slides.get(slideJson.id))
        self.locations.put(locationJson);
      });
      self.restaurantStore.loadRestaurants();
    },
    loadLocations() {
      try {
        const db = getDatabase();
        const reference = ref(
          db,
          self.domain.replaceAll(".", "_") + "/locations"
        );
        onValue(reference, (snapshot) => {
          self.updateLocations(snapshot.val());
        });
        // axios.get(self.sheetURL + "locations").then((json) => {
        //   self.updateLocations(json.data);
        //   // self.updateSlides(json.data);
        // });
      } catch (err) {
        console.error("Failed to load restaurants ", err);
      }
    },
    updateSettings(json) {
      if (json.length > 0) {
        json = json[0];
        for (var key of Object.keys(json)) {
          if (typeof self[key] && json[key] !== null) {
            if (typeof self[key] == "number") self[key] = parseInt(json[key]);
            else if (typeof self[key] == "object" && key !== "gps") {
              self[key] = JSON.parse(json[key]);
            } else self[key] = json[key];
          }
        }
      }
    },
    loadSettings() {
      try {
        const db = getDatabase();
        const reference = ref(
          db,
          self.domain.replaceAll(".", "_") + "/settings"
        );
        onValue(reference, (snapshot) => {
          self.updateSettings(snapshot.val());
        });
        // axios.get(self.sheetURL + "settings").then((json) => {
        //   self.updateSettings(json.data);
        // });
      } catch (err) {
        console.error("Failed to load restaurants ", err);
      }
    },
    // updateLocations(json) {
    //   self.locationItems.clear();
    //   json.forEach((locationJson, i) => {
    //     self.locationItems.put(locationJson);
    //   });
    // },
    // loadLocations() {
    //   try {
    //     axios
    //       // .get(self.sheetURL + self.locationsGID + "&single=true&output=tsv")
    //       .get(self.sheetURL + "locations")
    //       .then((json) => {
    //         var csv = json.data;
    //         var json = JSON.parse(csv);
    //         // var json = JSON.parse(self.csvJSON(csv));

    //         self.updateLocations(json);
    //       });
    //   } catch (err) {
    //     console.error("Failed to load restaurants ", err);
    //   }
    // },
    prompt() {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
          }
        });
      }
    },
    csvJSON(csv) {
      var lines = csv.split("\n");

      var result = [];

      // NOTE: If your columns contain commas in their values, you'll need
      // to deal with those before doing the next step
      // (you might convert them to &&& or something, then covert them back later)
      // jsfiddle showing the issue https://jsfiddle.net/
      // var headers = lines[0].split(",");
      var headers = lines[0].split("\t");

      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        // var currentline = lines[i].split(",");
        var currentline = lines[i].split("\t");

        for (var j = 0; j < headers.length; j++) {
          headers[j] = headers[j].replace("\r", "").replace("\n", "").trim();
          if (currentline[j] === "FALSE" || currentline[j] === "TRUE")
            obj[headers[j]] = currentline[j] === "TRUE";
          else if (
            currentline[j].indexOf("[") === 0 ||
            currentline[j].indexOf("{") === 0
          )
            obj[headers[j]] = JSON.parse(currentline[j]);
          else if (
            headers[j] === "minimumOrderAmount" ||
            headers[j] === "order"
          )
            obj[headers[j]] = parseInt(currentline[j]);
          else obj[headers[j]] = currentline[j];
          // delete obj[""];
        }
        result.push(obj);
      }

      //return result; //JavaScript object
      return JSON.stringify(result); //JSON
    },
    clearReload() {
      self.restaurantStore.markLoading(true);
      self.restaurantStore.loadRestaurants();
      self.loadCuisines();
      self.loadSlides();
    },
    submitContactForm(fields = {}) {
      if (fields && !self.submittingForm) {
        self.setSubmittingForm(true);
        return fetch(
          "https://cors.foood.vn/" + self.contactForm + "?embedded=true"
        )
          .then(function (response) {
            return response.text();
          })
          .then(function (data) {
            var parser = new DOMParser();
            var htmlDoc = parser.parseFromString(data, "text/html");

            var form = htmlDoc.getElementsByTagName("form");
            var formResponseURL = form[0].action;

            var scripts = htmlDoc.getElementsByTagName("script");

            var formData = new FormData();
            var bodyData = {};

            for (let i = 0; i < scripts.length; i++) {
              var script = scripts[i];
              if (script.innerHTML.indexOf("FB_PUBLIC_LOAD_DATA_") > -1) {
                var formObjs = JSON.parse(
                  script.innerHTML.split("=")[1].trim().replace(";", "")
                )[1][1];
                formObjs.map((inputItem, index, array) => {
                  var code = inputItem[4][0][0];
                  var label =
                    inputItem[1].charAt(0).toUpperCase() +
                    inputItem[1].slice(1);
                  var label = label.trim().replace(" ", "");

                  formData.append("entry." + code, fields[label.toLowerCase()]);
                });
              }
            }

            return axios({
              method: "post",
              url: "https://cors.iconstudio.co/" + formResponseURL,
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then(function (response) {
                self.setSubmittingForm(false);
                return true;
              })
              .catch(function (response) {
                //handle error
              });
          });
      }
    },
    afterCreate() {
      // self.loadCuisines();
      // setTimeout(() => {
      //     console.log(self);
      //     console.log("60 seconds");
      //     self.restartAppClear();
      // }, 60000);
      self.loadSettings();
      self.restaurantStore.updateRestaurantsAvailable();

      setInterval(() => {
        self.restaurantStore.updateRestaurantsAvailable();
      }, 1000 * 60 * 10);
      setInterval(() => {
        if (hourAgo(self.lastUpdate, 1)) self.clearReload();
      }, 1000 * 60 * 15);
      // self.loadLocations();
    },
  }));

const fetcher = (url) => window.fetch(url).then((response) => response.json());
export function initStore(snapshot = null) {
  if (store === null) {
    store = Store.create(
      {},
      {
        fetch: fetcher,
        alert: (m) => console.log(m), // Noop for demo: window.alert(m)
      }
    );
  }
  if (snapshot) {
    applySnapshot(store, snapshot);
  }

  persist("@fooodStoreKey", store, {
    storage: AsyncStorage,
    jsonify: true, // set to true if using AsyncStorage
    whitelist: [
      "restaurantStore",
      "slides",
      "products",
      "discounts",
      "lastUpdate",
      "posts",
      "reels",
      "cuisines",
      "locations",
      "splashShown",
      "favs",
    ],
  }).then(() => {
    if (
      store.cuisines.size === 0 ||
      store.restaurantStore.restaurants.size <= 1 ||
      hourAgo(store.lastUpdate, 1)
    ) {
      store.loadCuisines();
      store.loadSlides();
      store.loadLocations();
    } else {
      store.restaurantStore.markLoading(false);
      store.markLoading(false);
    }
    // fetch chapters on app load so that numNewUnread is updated
    // store.loadCuisines();
  });

  return store;
}

const hourAgo = (date, hours) => {
  const hour = 1000 * 60 * 60;
  var hourago = new Date();
  hourago = hourago.setHours(hourago.getHours() + hours);

  return date > hourago;
};

function minTwoDigits(n) {
  return (n < 10 ? "0" : "") + n;
}
// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

// function doCORSRequest(options, printResult) {
//   return new Promise(function (resolve, reject) {
//     var cors_api_url = "https://cors.foood.vn/";
//     var x = new XMLHttpRequest();
//     x.open(options.method, cors_api_url + options.url);
//     x.onload = x.onerror = function () {
//       for (
//         var responseText = x.responseText,
//           responseTextLen = responseText.length,
//           binary = "",
//           i = 0;
//         i < responseTextLen;
//         ++i
//       ) {
//         binary += String.fromCharCode(responseText.charCodeAt(i) & 255);
//       }
//       resolve(window.btoa(binary));
//     };
//     if (/^POST/i.test(options.method)) {
//       x.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
//     }
//     x.send(options.data);
//   });
// }
