import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  StyleSheet,
  FlatList,
  Platform,
  View,
  Text,
  Image,
  ImageBackground,
  ScrollView,
  SafeAreaView,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Animated,
} from "react-native";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import QRCode from "react-native-qrcode-svg";

import { Map, Marker, Overlay } from "pigeon-maps";

import { stamenToner, maptiler } from "pigeon-maps/providers";

import ReadMore from "react-native-read-more-text";
import * as Location from "expo-location";

import * as Linking from "expo-linking";
import { inject, observer, Observer } from "mobx-react";
import { debounce, isEmpty } from "lodash";
import RNPickerSelect from "react-native-picker-select";
import Swiper from "react-native-deck-swiper";
import {
  AntDesign,
  Octicons,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  Zocial,
  Fontisto,
} from "@expo/vector-icons";

import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// import { TouchableOpacity } from "react-native-gesture-handler";
import { Paragraph, Dialog, Portal, Searchbar } from "react-native-paper";

import * as RootNavigation from "../navigation/RootNavigation";
import Modal from "../utils/modal";
import RefreshControl from "../utils/refreshcontrol";
import { ResizeMode } from "expo-av";
import { Item } from "react-native-paper/lib/typescript/components/Drawer/Drawer";
import { Store } from "@material-ui/icons";

export const SearchBar = observer((props) => {
  const [search, setSearch] = useState(store.search);
  const [typing, setTyping] = useState(false);
  const [category, setCategory] = useState(
    store.categories.length > 0 ? store.categories[0] : ""
  );
  const handler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    store.setSearch(e);
    RootNavigation.navigate("Tabs", {
      screen: "RestaurantsStack",
      params: {
        screen: "Restaurants",
        params: {
          category: store.categories.toJSON()[0],
          sort: store.sort.toJSON()[0],
          search: e,
        },
      },
    });
    setTimeout(() => {
      setTyping(false);
    }, 500);
  }

  const onChange = (e) => {
    setTyping(true);
    handler(e);
  };

  // const onChange = (query) => {
  //   setTyping(true);
  //   store.setSearch(query);
  //   setSearch(query);
  //   wait(2500).then(() => {
  //     if (query === "") store.setSearch("");
  //   });

  //   setTimeout(() => {
  //     setTyping(false);
  //   }, 2500);
  // };
  return (
    <View style={{}}>
      <Observer>
        {() => (
          <View>
            {/* style={{ position: "absolute", top: 17, right: 30, zIndex: 5 }}> */}
            {search !== undefined &&
            search !== "" &&
            !store.isLoading &&
            !typing ? (
              <Text
                style={{
                  borderRadius: 15,
                  padding: 0,
                  minWidth: 250,
                  borderWidth: 0,
                  textAlign: "left",
                  color: "#666",
                  fontSize: 12,
                  minHeight: 15,
                  marginBottom: 5,
                }}
              >
                {store.sortedAvailableRestaurants.length + " "}

                {category &&
                  (category.endsWith("s") ? category.slice(0, -1) : category)}

                {" Restaurant" +
                  (store.sortedAvailableRestaurants.length === 1 ? "" : "s") +
                  " Available"}
              </Text>
            ) : (
              <View style={{ minHeight: 15 }}></View>
            )}
            <Searchbar
              platform="android"
              placeholder={
                store.isLoading
                  ? "Searching.."
                  : store.sortedAvailableRestaurants.length +
                    " " +
                    (props.category && props.category.endsWith("s")
                      ? props.category.slice(0, -1)
                      : props.category && props.category !== "all"
                      ? props.category
                      : "") +
                    (store.screenWidth > 480
                      ? " Restaurants Available"
                      : " Restaurants Available")
              }
              onSubmitEditing={(e) => {
                // wait(250).then(() => {
                store.isLoading ? null : store.setSearch(search);
                // });
              }}
              onChangeText={(e) => {
                setSearch(e);
                onChange(e);
                wait(2500).then(() => {
                  if (e === "") store.setSearch("");
                });
              }}
              icon={
                store.restaurantStore.isLoading
                  ? () => (
                      <ActivityIndicator
                        animating={true}
                        color={store.primaryColor}
                      />
                    )
                  : () => (
                      <MaterialIcons
                        name={"search"}
                        size={24}
                        color={store.primaryColor}
                      />
                    )
              }
              // onChangeText={(query) => {
              //   onChange(query);
              //   wait(2500).then(() => {
              //     if (query === "") store.setSearch("");
              //   });
              // }}
              placeholderTextColor={"#aaa"}
              bounceinputStyle={{ fontSize: 17 }}
              style={{
                backgroundColor: "#fff",
                marginBottom: 0,
                marginTop: 0,
              }}
              value={search}
              // icon={
              //   store.isLoading
              //     ? () => (
              //         <ActivityIndicator
              //           animating={true}
              //           color={store.primaryColor}
              //         />
              //       )
              //     : () => (
              //         <MaterialIcons
              //           name={"search"}
              //           size={24}
              //           color={store.primaryColor}
              //         />
              //       )
              // }
              // onIconPress={(e) => {
              //   store.isLoading
              //     ? null
              //     : props.searchProducts(search);
              // }}
              // onKeyPress={(e) => {
              // if (e.nativeEvent.key == "Enter")
              //   store.isLoading ? null : props.searchProducts(search);
              // }}
            />
          </View>
        )}
      </Observer>
    </View>
  );
});

export const CategoriesScroll = observer((props) => {
  var colorOveride = false;
  if (props.color) colorOveride = props.color;

  const [categories, setCategories] = useState(store.categories.toJSON());

  function updateCategories(cats) {
    // console.log('updateCategories');
    if (cats.length === 0) cats = [];

    // setSelectedCategories((prev) => cats);
    setTimeout(() => {
      // console.log(cats);
      // props.setCategory((prev) => cat.title);
      setCategories(cats);
      store.setCategories(cats);
      // if (!props.disableNav)
      if (cats.length === 0) {
        RootNavigation.navigate("Tabs", {
          screen: "RestaurantsStack",
          params: {
            screen: "Restaurants",
            params: {
              category: "All",
              sort: store.sort.toJSON()[0],
              search: store.search,
            },
          },
        });
      } else if (cats.length === 1)
        RootNavigation.navigate("Tabs", {
          screen: "RestaurantsStack",
          params: {
            screen: "Restaurants",
            params: {
              category: cats[0],
              sort: store.sort.toJSON()[0],
              search: store.search,
            },
          },
        });
    }, 500);
  }

  const renderItem = ({ item, index }) => {
    var cat = item;
    var catColor = cat.color ? cat.color : store.backgroundColor;
    var selected = categories.includes(cat.title);
    if (colorOveride) catColor = props.color;
    var backgroundColor = selected ? catColor : "#fff";

    var textColor = selected ? "#fff" : catColor;
    return (
      <TouchableOpacity
        key={index}
        onPress={() => {
          if (cat.title === "All") {
            if ("All" === props.category) {
              updateCategories([]);
            } else {
              updateCategories(["All"]);
            }
          } else {
            if (selected) {
              // updateCategories(["All"]);
              updateCategories(categories.filter((sc) => sc !== cat.title));
              // props.searchProducts(undefined, [""]);
            } else {
              if (categories.includes("All")) {
                updateCategories([cat.title]);
              } else {
                var scat = categories;
                scat.push(cat.title);
                updateCategories([...scat]);
              }
            }
          }
        }}
        style={{
          maxHeight: 70,
          minHeight: 70,
          justifyContent: "center",
          alignItems: "center",
          borderColor: textColor,
          borderWidth: selected ? 1 : 0,
          backgroundColor: backgroundColor,
          margin: 3,
          padding: 15,
          borderRadius: 15,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {cat.icon && cat.icon !== "" ? (
            <View style={{ marginBottom: 2 }}>
              {getIcon(cat.iconType, cat.icon, textColor, 20)}
            </View>
          ) : (
            <View></View>
          )}
          <Text
            style={{
              marginTop: -3,
              fontSize: 14,
              color: textColor,
              fontWeight: "bold",
            }}
          >
            {cat.title.replace("%2F", "/").replace("%26", "&")}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <FlatList
        contentContainerStyle={{
          minWidth: "98%",
          maxWidth: store.screenWidth,
          marginLeft: 3,
          // flex: 1,
          // maxWidth: 300,
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        horizontal
        // pagingEnabled={true}
        showsHorizontalScrollIndicator={false}
        legacyImplementation={false}
        key={store.categories.toString()}
        data={store.sortedScrollCuisines}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 5,
          borderRadius: 5,
          // flex: 1,
          minWidth: "98%",
          maxWidth: store.screenWidth,
        }}
      />
    </View>
  );
});

export const FeaturedRestaurants = observer((props) => {
  const { itemWidth, numberToShow } = props;
  const [itemHeight, setItemHeight] = useState(props.itemWidth * 0.75);
  const [imageHeight, setImageHeight] = useState(props.itemWidth * 0.75 * 0.65);
  const [textHeight, setTextHeight] = useState(props.itemWidth * 0.75 * 0.35);
  const [textColor, setTextColor] = useState(store.textColor);
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    setRestaurants(store.sortedRandomFeaturedRestaurants(numberToShow));
  }, []);

  const openRestModal = (slug = "") => {
    store.setSelectedRestaurantId(slug);
    props.openRestModal();
  };

  const renderItem = ({ item, index }) => {
    const logoBGColor =
      item.logoImageBG !== "" ? item.logoImageBG : "transparent";
    const mt = item.title.length > 18 ? 5 : 12;
    if (!item) return <View></View>;
    return (
      <TouchableOpacity
        onPress={(e) => {
          if (item.newWindow) window.open(item.orderingLink, "_blank").focus();
          else openRestModal(item.slug);
        }}
        style={{
          width: itemWidth,
          height: itemHeight,
          justifyContent: "flex-start",
          alignItems: "center",
          borderWidth: 0.25,
          borderColor: "#ccc",
          backgroundColor: "#fff",
          margin: 5,
          padding: 0,
          color: textColor,
          borderRadius: 15,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {item.logoImageUrl && (
            <View
              style={{
                position: "absolute",
                top: -5,
                left: 5,
                zIndex: 2,
              }}
            >
              <View
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 100,
                  backgroundColor: logoBGColor,
                  margin: 10,
                }}
              >
                <Image
                  source={store.getImageDirectory(item.logoImageUrl)}
                  style={{
                    width: "100%",
                    height: "100%",
                    resizeMode: "cover",
                    borderRadius: 100,
                  }}
                  resizeMode="contain"
                />
              </View>
            </View>
          )}
          <Image
            source={{
              uri: store.getImageDirectory(item.heroImage),
            }}
            resizeMode={"cover"}
            style={{
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              minHeight: imageHeight,
              maxHeight: imageHeight,
              minWidth: itemWidth,
              width: "100%",
            }}
          />
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              minHeight: textHeight,
              maxHeight: textHeight,
              width: "100%",
            }}
          >
            <Text
              style={{
                width: "90%",
                marginTop: mt,
                fontSize: 14,
                // color: textColor,
                fontWeight: "bold",
                textAlign: "center",
                justifyContent: "center",
              }}
              // numberOfLines={2}
            >
              {item.title}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  if (!restaurants || restaurants.length == 0) return <View></View>;

  return (
    <FlatList
      contentContainerStyle={{
        minWidth: "98%",
        // maxWidth: 300,
        justifyContent: "center",
        alignItems: "center",
      }}
      horizontal
      // pagingEnabled={true}
      showsHorizontalScrollIndicator={false}
      legacyImplementation={false}
      key={store.categories.toString()}
      data={restaurants}
      renderItem={renderItem}
      keyExtractor={(item, index) => index}
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 5,
        borderRadius: 5,

        // top: 0,
        // marginBottom:
        //   store.screenType === "desktop" && Platform.OS === "web" ? 25 : 5,
        minWidth: "100%",
        // backgroundColor: "red",
        // minWidth: 300,
        // maxWidth: 300,
      }}
    />
  );
});

export const FeaturedProducts = observer((props) => {
  const { itemWidth, numberToShow } = props;
  const [itemHeight, setItemHeight] = useState(itemWidth * 0.85);
  const [imageHeight, setImageHeight] = useState(itemWidth * 0.75 * 0.7);
  const [textHeight, setTextHeight] = useState(itemWidth * 0.85 * 0.3);
  const [textColor, setTextColor] = useState(store.textColor);
  const [restaurants, setRestaurants] = useState([]);

  // sortedRandomFeaturedRestaurants
  useEffect(() => {
    setRestaurants(store.sortedProducts.slice(0, numberToShow));
  }, [numberToShow]);

  const onPress = (item) => {
    if (item.newWindow) window.open(item.orderingLink, "_blank").focus();
    else if (item.directLink && item.directLink !== "") {
      store.setSelectedProductId(item.id);
      store.setSelectedRestaurantId(item.storeSlug);
      props.openRestModal();
    } else {
      store.setSelectedRestaurantId(item.storeSlug);
      props.openRestModal();
    }
  };

  const renderItem = ({ item, index }) => {
    const logoBGColor =
      item.logoImageBG !== "" ? item.logoImageBG : "transparent";

    return (
      <TouchableOpacity
        key={index}
        onPress={() => {
          onPress(item);
        }}
        style={{
          width: itemWidth,
          maxWidth: itemWidth,
          height: itemHeight,
          justifyContent: "flex-start",
          alignItems: "center",
          borderWidth: 0.25,
          borderColor: "#ccc",
          backgroundColor: "#fff", // cardBackgroundColor,
          margin: 5,
          padding: 0,
          color: textColor,
          borderRadius: 15,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <View
            style={{
              position: "absolute",
              top: -5,
              left: 5,
              zIndex: 2,
            }}
          >
            <View
              style={{
                width: 60,
                height: 60,
                borderRadius: 100,
                backgroundColor: logoBGColor,
                margin: 10,
              }}
            >
              <Image
                source={store.getImageDirectory(item.logoImageUrl)}
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "cover",
                  borderRadius: 100,
                }}
                resizeMode="contain"
              />
            </View>
          </View>

          <Image
            source={{
              uri: store.getImageDirectory(item.image),
            }}
            resizeMode={"cover"}
            style={{
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              minHeight: imageHeight,
              maxHeight: imageHeight,
              minWidth: itemWidth,
              width: "100%",
            }}
          />
          <View
            style={{
              flexDirection: "column",

              justifyContent: "center",
              alignItems: "center",
              minHeight: textHeight,
              maxHeight: textHeight,
              width: "100%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                color: store.textColor,
                fontWeight: "bold",
                textAlign: "center",
                maxWidth: itemWidth - 5,
              }}
              numberOfLines={1}
            >
              {item.title.replace("%2F", "/").replace("%26", "&")}
            </Text>
            <Text
              // numberOfLines={1}
              style={{
                fontWeight: "normal",
                fontFamily: "Roboto_400Regular",
                textAlign: "center",
                fontSize: 13,
              }}
            >
              {item.price.toLocaleString()}
              {store.currency}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  if (!restaurants || restaurants.length == 0) return <View></View>;

  return (
    <View style={{ width: "100%" }}>
      <FlatList
        contentContainerStyle={{
          minWidth: "98%",
          // maxWidth: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
        horizontal
        pagingEnabled={true}
        showsHorizontalScrollIndicator={false}
        legacyImplementation={false}
        key={store.categories.toString()}
        data={restaurants}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 5,
          borderRadius: 5,

          // top: 0,
          // marginBottom:
          //   store.screenType === "desktop" && Platform.OS === "web" ? 25 : 5,
          minWidth: "100%",
          // backgroundColor: "red",
          // minWidth: 300,
          // maxWidth: 300,
        }}
      />
    </View>
  );
});

export const FeaturedFeed = observer((props) => {
  const { itemWidth, numberToShow } = props;
  const [itemHeight, setItemHeight] = useState(itemWidth * 0.85);
  const [imageHeight, setImageHeight] = useState(itemWidth * 0.85 * 0.7);
  const [textHeight, setTextHeight] = useState(itemWidth * 0.85 * 0.3);
  const [textColor, setTextColor] = useState(store.textColor);
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    setRestaurants(store.sortedFeed.slice(0, numberToShow));
  }, [numberToShow]);

  const renderItem = ({ item, index }) => (
    <RenderedItem
      item={item}
      index={index}
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      imageHeight={imageHeight}
      textHeight={textHeight}
      textColor={store.textColor}
    />
  );

  if (!restaurants || restaurants.length == 0) return <View></View>;

  return (
    <View style={{ width: "100%" }}>
      <FlatList
        contentContainerStyle={{
          minWidth: "98%",
          // maxWidth: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
        horizontal
        pagingEnabled={true}
        showsHorizontalScrollIndicator={false}
        legacyImplementation={false}
        key={store.categories.toString()}
        data={restaurants}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 5,
          borderRadius: 5,

          // top: 0,
          // marginBottom:
          //   store.screenType === "desktop" && Platform.OS === "web" ? 25 : 5,
          minWidth: "100%",
          // backgroundColor: "red",
          // minWidth: 300,
          // maxWidth: 300,
        }}
      />
    </View>
  );
});

class RenderedItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      postImage: store.getImageDirectory(props.item.image),
    };
  }
  loadOriginal() {
    store
      .getCorsImage(
        // this.props.item.originalImage
        this.props.item.link + "/media/?size=l"
      )
      .then((myBase64) => {
        this.setState({ show: true, postImage: myBase64 });
      });
  }
  componentDidMount() {
    this.loadOriginal();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    var {
      item,
      index,
      itemWidth,
      itemHeight,
      textColor,
      imageHeight,
      textHeight,
    } = this.props;
    var post = item;

    if (!this.state.show) return <View></View>;

    return (
      <TouchableOpacity
        key={index}
        onPress={() => {
          RootNavigation.navigate("Feed", {
            id: index,
          });
        }}
        style={{
          width: itemWidth,
          maxWidth: itemWidth,
          height: itemHeight,
          justifyContent: "flex-start",
          alignItems: "center",
          borderWidth: 0.25,
          borderColor: "#ccc",
          backgroundColor: "#fff", // cardBackgroundColor,
          margin: 5,
          padding: 0,
          color: textColor,
          borderRadius: 15,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <View
            style={{
              position: "absolute",
              top: -5,
              left: 5,
              zIndex: 2,
            }}
          >
            <View
              style={{
                width: 60,
                height: 60,
                borderRadius: 100,
                backgroundColor:
                  item.logoImageBG !== "" ? item.logoImageBG : "transparent",
                margin: 10,
              }}
            >
              <Image
                source={store.getImageDirectory(item.logoImageUrl)}
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "cover",
                  borderRadius: 100,
                }}
                resizeMode="contain"
              />
            </View>
          </View>

          <Image
            source={{
              uri: this.state.postImage, // store.getImageDirectory(post.image),
            }}
            onError={(e) => {
              if (e.nativeEvent.error.indexOf("/restaurants/") == -1) {
                this.setState({
                  postImage: store.getImageDirectory(item.image),
                });
              } else this.setState({ show: false });
            }}
            resizeMode={"cover"}
            style={{
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              minHeight: imageHeight,
              maxHeight: imageHeight,
              minWidth: itemWidth,
              width: "100%",
            }}
          />
          <View
            style={{
              flexDirection: "column",

              justifyContent: "center",
              alignItems: "center",
              minHeight: textHeight,
              maxHeight: textHeight,
              width: "100%",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                color: textColor,
                fontFamily: "Roboto_400Regular",
                textAlign: "center",
                maxWidth: itemWidth - 5,
              }}
              numberOfLines={2}
            >
              {post.text.replace("%2F", "/").replace("%26", "&")}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export const FeaturedDiscounts = observer((props) => {
  const { itemWidth, numberToShow } = props;
  const [itemHeight, setItemHeight] = useState(itemWidth * 0.85);
  const [imageHeight, setImageHeight] = useState(itemWidth * 0.75 * 0.7);
  const [textHeight, setTextHeight] = useState(itemWidth * 0.75 * 0.3);
  const [textColor, setTextColor] = useState(store.textColor);
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    setRestaurants(store.sortedDiscounts.slice(0, numberToShow));
  }, [numberToShow]);

  const onPress = (item) => {
    if (item.newWindow) window.open(item.orderingLink, "_blank").focus();
    else {
      store.setSelectedRestaurantId(item.storeSlug);
      props.openRestModal();
    }
  };

  const renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        key={index}
        onPress={() => {
          onPress(item);
        }}
        style={{
          width: itemWidth,
          maxWidth: itemWidth,
          height: itemHeight,
          justifyContent: "flex-start",
          alignItems: "center",
          borderWidth: 0.25,
          borderColor: "#ccc",
          backgroundColor: "#fff", // cardBackgroundColor,
          margin: 5,
          padding: 0,
          color: textColor,
          borderRadius: 15,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <ImageBackground
            source={{
              uri: store.getImageDirectory(item.image),
            }}
            resizeMode={item.image.indexOf(".png") > -1 ? "contain" : "cover"}
            imageStyle={[
              item.image.indexOf(".png") > -1
                ? {
                    alignSelf: "center",
                    width: "50%",
                    marginLeft: "25%",
                  }
                : {
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    width: "100%",
                  },
            ]}
            style={{
              backgroundColor: item.imageBackgroundColor,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              minHeight: imageHeight,
              maxHeight: imageHeight,
              minWidth: itemWidth,
              width: "100%",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: item.image.indexOf(".png") > -1 ? 10 : 0,
            }}
          />
          <View
            style={{
              flexDirection: "column",

              justifyContent: "center",
              alignItems: "center",
              minHeight: itemHeight * 0.45,
              maxHeight: itemHeight * 0.45,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                color: store.textColor,
                fontWeight: "bold",
                textAlign: "center",
              }}
              numberOfLines={1}
            >
              {item.title.replace("%2F", "/").replace("%26", "&")}
            </Text>
            <Text
              numberOfLines={2}
              style={{
                fontWeight: "normal",
                fontFamily: "Roboto_400Regular",
                textAlign: "center",
                fontSize: 11,
                paddingHorizontal: 30,
              }}
            >
              {item.description}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                fontWeight: "normal",
                fontFamily: "Roboto_400Regular",
                textAlign: "center",
                fontSize: 12,
                color: store.buttonColor,
              }}
            >
              {item.code ? item.code + " " : ""}
              {item.amount}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  if (!restaurants || restaurants.length == 0) return <View></View>;

  return (
    <FlatList
      contentContainerStyle={{
        minWidth: "98%",
        // maxWidth: 300,
        justifyContent: "center",
        alignItems: "center",
      }}
      horizontal
      // pagingEnabled={true}
      showsHorizontalScrollIndicator={false}
      legacyImplementation={false}
      key={store.categories.toString()}
      data={restaurants}
      renderItem={renderItem}
      keyExtractor={(item, index) => index}
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 5,
        borderRadius: 5,

        // top: 0,
        // marginBottom:
        //   store.screenType === "desktop" && Platform.OS === "web" ? 25 : 5,
        minWidth: "100%",
        // backgroundColor: "red",
        // minWidth: 300,
        // maxWidth: 300,
      }}
    />
  );
});

export const FullDiscounts = observer((props) => {
  const itemHeight = props.itemHeight ? props.itemHeight : 75;

  const renderItem = ({ item, index }) => {
    return (
      <View
        key={index}
        style={{ width: "100%", marginBottom: 10, marginRight: 5 }}
      >
        <TouchableOpacity
          key={index}
          onPress={() => {
            store.setSelectedRestaurantId(item.storeSlug);
            // props.closeDiscModal();
            props.openRestModal();
          }}
          style={{
            minWidth: store.screenWidth - 2,
            width: "100%",
            height: itemHeight + 10,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            borderBottomWidth: 0.15,
            borderColor: "#cccc",
            backgroundColor: store.surfaceColor, // cardBackgroundColor,
            margin: 2,
            padding: 0,
            // borderRadius: 15,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <View style={{ flex: 0.2, alignItems: "flex-start" }}>
              <Image
                source={{
                  uri: store.getImageDirectory(item.image),
                }}
                resizeMode={
                  item.image.indexOf(".png") > -1 ? "contain" : "cover"
                }
                imageStyle={[
                  item.image.indexOf(".png") > -1
                    ? {
                        alignSelf: "center",
                        width: "50%",
                        marginLeft: "25%",
                      }
                    : {
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        width: "100%",
                      },
                ]}
                style={{
                  backgroundColor: item.imageBackgroundColor,
                  borderRadius: 15,
                  width: itemHeight,
                  height: itemHeight,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: item.image.indexOf(".png") > -1 ? 10 : 0,
                  // minWidth: 134,
                  // marginTop: -5,
                  // margin: 4,
                  // flex: 1,
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "column",

                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginLeft: 5,
                flex: 0.75,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: store.textColor,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
                numberOfLines={1}
              >
                {item.title.replace("%2F", "/").replace("%26", "&")}
              </Text>
              <Text
                numberOfLines={2}
                style={{
                  fontWeight: "normal",
                  fontFamily: "Roboto_400Regular",
                  textAlign: "left",
                  fontSize: 11,
                }}
              >
                {item.description}
              </Text>
              <Text
                numberOfLines={1}
                style={{
                  fontWeight: "normal",
                  fontFamily: "Roboto_400Regular",
                  textAlign: "left",
                  fontSize: 12,
                  color: store.buttonColor,
                }}
              >
                {item.code ? item.code + " " : ""}
                {item.amount}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={{ margin: 10 }}>
      <View style={{ flexDirection: "row" }}>
        <TouchableOpacity
          onPress={() => {
            props.closeDiscModal();
          }}
          style={{ margin: 10, marginRight: 0 }}
        >
          <MaterialCommunityIcons
            name="chevron-left"
            size={Platform.OS === "web" ? 24 : 28}
            color={store.primaryColor}
          />
        </TouchableOpacity>
        <Text
          style={{
            margin: 10,
            fontFamily: store.font, //"Roboto_400Regular",
            fontSize: 20,
            color: store.primaryColor,
          }}
        >
          {"Offers "}
        </Text>
      </View>
      <FlatList
        contentContainerStyle={{
          // minWidth: "100%",
          // maxWidth: 300,
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        numColumns={1}
        pagingEnabled={true}
        showsHorizontalScrollIndicator={false}
        legacyImplementation={false}
        key={store.categories.toString()}
        data={store.sortedDiscounts}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
        style={{
          flex: 1,
          marginTop: 5,
          borderRadius: 5,
          width: store.screenWidth - 20,
          backgroundColor: store.surfaceColor,
          // top: 0,
          // marginBottom:
          //   store.screenType === "desktop" && Platform.OS === "web" ? 25 : 5,
          // minWidth: "100%",
          // backgroundColor: "red",
          // minWidth: 300,
          // maxWidth: 300,
        }}
      />
    </View>
  );
});
export const FullProducts = observer((props) => {
  const itemHeight = props.itemHeight ? props.itemHeight : 75;

  const renderItem = ({ item, index }) => {
    return (
      <View
        key={index}
        style={{ width: "100%", marginBottom: 10, marginRight: 5 }}
      >
        <TouchableOpacity
          key={index}
          onPress={() => {
            if (item.directLink && item.directLink !== "") {
              store.setSelectedProductId(item.id);
              store.setSelectedRestaurantId(item.storeSlug);
              props.openRestModal();
            } else {
              store.setSelectedRestaurantId(item.storeSlug);
              props.openRestModal();
            }
          }}
          style={{
            minWidth: store.screenWidth - 2,
            width: "100%",
            height: itemHeight + 10,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            borderBottomWidth: 0.15,
            borderColor: "#cccc",
            backgroundColor: store.surfaceColor, // cardBackgroundColor,
            margin: 2,
            padding: 0,
            // borderRadius: 15,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <View style={{ flex: 0.2, alignItems: "flex-start" }}>
              <Image
                source={{
                  uri: store.getImageDirectory(item.image),
                }}
                resizeMode={
                  item.image.indexOf(".png") > -1 ? "contain" : "cover"
                }
                imageStyle={[
                  item.image.indexOf(".png") > -1
                    ? {
                        alignSelf: "center",
                        width: "50%",
                        marginLeft: "25%",
                      }
                    : {
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        width: "100%",
                      },
                ]}
                style={{
                  backgroundColor: item.imageBackgroundColor,
                  borderRadius: 15,
                  width: itemHeight,
                  height: itemHeight,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: item.image.indexOf(".png") > -1 ? 10 : 0,
                  // minWidth: 134,
                  // marginTop: -5,
                  // margin: 4,
                  // flex: 1,
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "column",

                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginLeft: 5,
                flex: 0.75,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: store.textColor,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
                numberOfLines={1}
              >
                {item.title.replace("%2F", "/").replace("%26", "&")}
              </Text>
              <Text
                numberOfLines={2}
                style={{
                  fontWeight: "normal",
                  fontFamily: "Roboto_400Regular",
                  textAlign: "left",
                  fontSize: 11,
                }}
              >
                {item.description}
              </Text>
              <Text
                numberOfLines={1}
                style={{
                  fontWeight: "normal",
                  fontFamily: "Roboto_400Regular",
                  textAlign: "left",
                  fontSize: 12,
                  color: store.buttonColor,
                }}
              >
                {item.code ? item.code + " " : ""}
                {item.amount}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={{ margin: 10 }}>
      <View style={{ flexDirection: "row" }}>
        <TouchableOpacity
          onPress={() => {
            props.closeProdModal();
          }}
          style={{ margin: 10, marginRight: 0 }}
        >
          <MaterialCommunityIcons
            name="chevron-left"
            size={Platform.OS === "web" ? 24 : 28}
            color={store.primaryColor}
          />
        </TouchableOpacity>
        <Text
          style={{
            margin: 10,
            fontFamily: store.font, //"Roboto_400Regular",
            fontSize: 20,
            color: store.primaryColor,
          }}
        >
          {"Products "}
        </Text>
      </View>
      <FlatList
        contentContainerStyle={{
          // minWidth: "100%",
          // maxWidth: 300,
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        numColumns={1}
        pagingEnabled={true}
        showsHorizontalScrollIndicator={false}
        legacyImplementation={false}
        key={store.categories.toString()}
        data={store.sortedProducts}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
        style={{
          flex: 1,
          marginTop: 5,
          borderRadius: 5,
          width: store.screenWidth - 20,
          backgroundColor: store.surfaceColor,
          maxHeight: store.screenHeight - 10,
          // top: 0,
          // marginBottom:
          //   store.screenType === "desktop" && Platform.OS === "web" ? 25 : 5,
          // minWidth: "100%",
          // backgroundColor: "red",
          // minWidth: 300,
          // maxWidth: 300,
        }}
      />
    </View>
  );
});

export const CategoriesPanel = observer((props) => {
  const { itemWidth, numberToShow } = props;
  const [itemHeight, setItemHeight] = useState(itemWidth * 0.75);
  const [imageHeight, setImageHeight] = useState(itemWidth * 0.75 * 0.65);
  const [textHeight, setTextHeight] = useState(itemWidth * 0.75 * 0.35);
  const [textColor, setTextColor] = useState(store.textColor);

  const [categories, setCategories] = useState(
    store.sortedCuisines.sort((a, b) => a.order - b.order)
  );
  function updateCategories(cats) {
    if (cats.length === 0) cats = [];
    setTimeout(() => {
      store.setCategories(cats);
      RootNavigation.navigate("Tabs", {
        screen: "RestaurantsStack",
        params: {
          screen: "Restaurants",
          params: {
            category: cats[0],
            sort: store.sort.toJSON()[0],
            search: store.search,
          },
        },
      });
    }, 500);
  }

  useEffect(() => {
    if (props.removeAll)
      setCategories(categories.filter((val) => val.title !== "All"));
  }, []);

  const renderItem = ({ item, index }) => {
    var cat = item;
    var cardHeight = itemHeight;
    var cardBorderColor = store.categories.includes(cat.title)
      ? cat.color
        ? cat.color
        : store.backgroundColor
      : "#ccc";
    var cardBackgroundColor = cat.image
      ? "#fff"
      : store.categories.includes(cat.title)
      ? cat.color
        ? cat.color
        : store.backgroundColor
      : "#fff";
    var cardTextColor = store.categories.includes(cat.title)
      ? store.secondaryColor
      : store.primaryColor;
    return (
      <TouchableOpacity
        activeOpacity={1.0}
        key={index}
        onPress={() => {
          updateCategories([cat.title]);
          store.setShowSideModal(false);
          if (props.openPage)
            RootNavigation.navigate("Tabs", {
              screen: "RestaurantsStack",
              params: {
                screen: "Restaurants",
                params: {
                  category: cat.title,
                  sort: "default",
                },
              },
            });
        }}
        style={{
          width: itemWidth,
          maxWidth: itemWidth,
          height: cardHeight,
          justifyContent: cat.image ? "flex-start" : "center",
          alignItems: "center",
          borderWidth: 0.25,
          borderColor: cardBorderColor,
          backgroundColor: "#fff", // cardBackgroundColor,
          margin: 5,
          padding: 0,
          borderRadius: 15,
        }}
      >
        {/* <View
        style={{
          flexDirection: "column",
          // justifyContent: "flex-start",
          // alignItems: "center",
          // width: "100%",

          // marginLeft: -4,
          // padding: 2,
          backgroundColor: "red",
          width: 140,
          height: 120,
        }}
      > */}
        {cat.image && cat.image !== "" ? (
          <Image
            source={{
              uri: store.getImageDirectory(cat.image),
            }}
            resizeMode={"cover"}
            style={{
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              minHeight: imageHeight,
              maxHeight: imageHeight,
              width: "100%",
            }}
          />
        ) : (
          <View>
            {getIcon(
              cat.iconType,
              cat.icon,
              store.categories.includes(cat.title)
                ? store.secondaryColor
                : cat.color
                ? cat.color
                : store.primaryColor,
              40
            )}
          </View>
        )}
        <View
          style={[
            {
              flexDirection: "column",

              justifyContent: "center",
              alignItems: "center",
            },
            cat.image && {
              minHeight: textHeight,
              maxHeight: textHeight,
            },
          ]}
        >
          <Text
            style={{
              marginTop: cat.image ? 0 : 10,
              fontSize: cat.image ? 14 : 16,
              color: cat.image
                ? store.hexToRGB(store.text, 0.7)
                : store.categories.includes(cat.title)
                ? store.secondaryColor
                : cat.color
                ? cat.color
                : store.primaryColor,
              fontFamily: store.font,
              textAlign: "center",
              alignSelf: "center",
            }}
            numberOfLines={2}
          >
            {cat.title.replace("%2F", "/").replace("%26", "&")}
          </Text>
        </View>
        {/* </View> */}
      </TouchableOpacity>
    );
  };
  if (props.horizontal && props.horizontal === true)
    return (
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          minWidth: "98%",
          // maxWidth: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
        key={store.categories.toString()}
        data={categories.slice(0, numberToShow)}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 5,
          borderRadius: 5,
          maxWidth: store.screenWidth,
          // top: 0,
          // marginBottom:
          //   store.screenType === "desktop" && Platform.OS === "web" ? 25 : 5,
          minWidth: "100%",
          // backgroundColor: "red",
          // minWidth: 300,
          // maxWidth: 300,
        }}
      />
    );
  else
    return (
      <FlatList
        numColumns={Math.floor(props.width / itemWidth)}
        contentContainerStyle={{
          minWidth: "98%",
          // maxWidth: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
        key={store.categories.toString()}
        data={categories.slice(0, numberToShow)}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 5,
          borderRadius: 5,
          maxWidth: store.screenWidth,
          // top: 0,
          // marginBottom:
          //   store.screenType === "desktop" && Platform.OS === "web" ? 25 : 5,
          minWidth: "100%",
          // backgroundColor: "red",
          // minWidth: 300,
          // maxWidth: 300,
        }}
      />
    );
});

export const SortModal = observer((props) => {
  const modalWidth =
    store.screenWidth < store.screenWidth ? store.screenWidth - 50 : 400;

  return (
    <Modal
      isVisible={store.showSideModal}
      onBackdropPress={(e) => {
        store.setShowSideModal(false);
      }} // Android back press
      onSwipeComplete={(e) => {
        console.log("onSwipe");
        store.setShowSideModal(false);
      }} // Swipe to discard
      animationIn="slideInRight" // Has others, we want slide in from the left
      animationOut="slideOutRight" // When discarding the drawer
      swipeDirection="right" // Discard the drawer with swipe to left
      useNativeDriver // Faster animation
      hideModalContentWhileAnimating // Better performance, try with/without
      propagateSwipe // Allows swipe events to propagate to children components (eg a ScrollView inside a modal)
      style={{
        margin: 0,
        width: modalWidth,
        marginLeft: store.screenWidth - modalWidth,
        maxWidth: 400,
      }} // Needs to contain the width, 75% of screen store.screenWidth in our case
    >
      <View
        style={{
          flex: 1,
          backgroundColor: store.backgroundColor,
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: store.screenWidth < 800 ? 20 : 0,
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: store.backgroundColor,
            height: 40,
          }}
        >
          <TouchableOpacity
            activeOpacity={1.0}
            onPress={(e) => {
              store.setShowSideModal(false);
            }}
            style={{ position: "absolute", top: 0, left: 0 }}
          >
            <MaterialCommunityIcons
              name="chevron-left"
              size={48}
              color={store.primaryColor}
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            marginVertical: 10,
            width: "100%",
            alignItems: "center",
          }}
        >
          <SelectLocation showButtons={true} />
        </View>
        <CategoriesPanel width={modalWidth} itemWidth={modalWidth / 2 - 20} />
      </View>
    </Modal>
  );
});

export const SelectLocation = observer((props) => {
  var showButtons = props.showButtons ? props.showButtons : false;

  const [location, setLocation] = useState(store.location);
  const [locationType, setLocationType] = useState(store.locationType);

  var selectBorderColor = store.primaryColor; //store.hexToRGB("#000", 0.3);
  var selectBackgroundColor = "#fff";
  var selectTextColor = store.primaryColor; //"#000";

  const pickerSelectStyles = {
    inputIOS: {
      fontSize: 12,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: selectBorderColor,
      borderRadius: 25,
      color: selectTextColor,
      backgroundColor: selectBackgroundColor,
    },
    inputWeb: {
      fontSize: 12,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: selectBorderColor,
      borderRadius: 25,
      color: selectTextColor,
      backgroundColor: selectBackgroundColor,
      // paddingRight: 30,
    },
    inputAndroid: {
      fontSize: 12,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: selectBorderColor,
      borderRadius: 25,
      color: selectTextColor,
      backgroundColor: selectBackgroundColor,
    },
  };

  return (
    <View style={{ flex: 1, width: "100%", flexDirection: "row" }}>
      {showButtons && (
        <View
          style={{
            flex: 0.5,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TouchableOpacity
            activeOpacity={1.0}
            style={{
              height: 30,
              flex: 0.5,
              marginHorizontal: 5,
              padding: 8,
              borderRadius: 15,
              backgroundColor:
                store.locationType === "delivers" ? store.primaryColor : "#fff",
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={(e) => {
              store.setLocationType("delivers");
            }}
          >
            <Text
              style={{
                alignSelf: "center",
                fontSize: 11,
                color:
                  store.locationType === "delivers"
                    ? "#fff"
                    : store.primaryColor,
              }}
            >
              Delivers to
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={1.0}
            style={{
              height: 30,
              flex: 0.5,
              marginHorizontal: 5,
              padding: 8,
              borderRadius: 15,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                store.locationType === "located" ? store.primaryColor : "#fff",
            }}
            onPress={(e) => {
              store.setLocationType("located");
            }}
          >
            <Text
              style={{
                alignSelf: "center",
                fontSize: 11,
                color:
                  store.locationType === "located"
                    ? "#fff"
                    : store.primaryColor,
              }}
            >
              Located in
            </Text>
          </TouchableOpacity>
        </View>
      )}
      {showButtons && (
        <View
          style={{
            flex: 0.2,
            minHeight: 30,
            maxHeight: 30,
            justifyContent: "center",
            paddingHorizontal: 5,
          }}
        ></View>
      )}
      <View
        style={{
          flex: showButtons ? 0.3 : 1,
          maxWidth: 150,
          minHeight: 30,
          maxHeight: 30,
          justifyContent: "center",
          paddingHorizontal: 5,
        }}
      >
        <RNPickerSelect
          placeholder={{}}
          //   label: "Select a number or add another...",
          //   value: null,
          //   color: "red",
          // }}
          onValueChange={(value) => {
            setLocation(value);
            store.setLocation(value, true);
          }}
          value={location}
          // onValueChange={(value) => console.log(value)}
          items={store.getLocations.map((suburb, i) => ({
            value: suburb.name,
            label: suburb.name,
          }))}
          style={{
            ...pickerSelectStyles,
            ...{
              minWidth: 120,
              maxWidth: 120,
              minHeight: 30,
              maxHeight: 30,
            },
          }}
        />
      </View>
    </View>
  );
});

export const FlickDisplay = observer((props) => {
  var { itemWidth, data } = props;
  var itemHeight = parseInt(
    store.screenWidth > 800 ? itemWidth * 0.85 : itemWidth * 0.85
  );

  const useSwiper = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOnSwipedLeft = () => useSwiper.current.swipeLeft();
  const handleOnSwipedTop = () => useSwiper.current.swipeTop();
  const handleOnSwipedRight = () => useSwiper.current.swipeRight();
  const handleOnSwipedBottom = () => useSwiper.current.swipeBottom();

  React.useEffect(() => {
    document.addEventListener("keydown", function (e) {
      switch (e.keyCode) {
        case 37:
          handleOnSwipedLeft();
          break;
        case 38:
          handleOnSwipedTop();
          break;
        case 39:
          handleOnSwipedRight();
          break;
        case 40:
          handleOnSwipedBottom();
          break;
      }
    });
  }, []);

  const renderCard = (card, index) => {
    const item = card;

    var cost = item.item.deliveryAreas.filter(
      (da) => da.name === store.location
    );
    if (cost.length > 0) cost = cost[0].cost;
    else cost = -1;
    return (
      <RenderedCard
        currentIndex={currentIndex}
        item={item}
        index={index}
        itemWidth={itemWidth}
        itemHeight={itemHeight}
      />
    );
  };

  return (
    <ScrollView
      style={{
        marginTop: 250,
        maxHeight:
          store.screenWidth > 800 ? itemHeight : store.screenHeight - 120,
        minHeight:
          store.screenWidth > 800 ? itemHeight : store.screenHeight - 120,
        width: store.screenWidth,
      }}
      contentContainerStyle={{
        flex: 1,
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center ",
      }}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <Swiper
        key={
          store.sortedAvailableRestaurants.length.toString() +
          "_" +
          itemWidth.toString()
        }
        ref={useSwiper}
        containerStyle={{ marginTop: 0 }}
        // goBackToPreviousCardOnSwipeLeft={true}
        // animateCardOpacity
        cardIndex={0}
        // swipeBackCard={true}
        // infinite
        showSecondCard
        // animateOverlayLabelsOpacity
        cards={data}
        // cards={["DO", "MORE", "OF", "WHAT", "MAKES", "YOU", "HAPPY"]}
        renderCard={renderCard}
        onSwiped={(cardIndex) => {
          setCurrentIndex((prev) => parseInt(cardIndex) + 1);
        }}
        onSwipedAll={() => {
          console.log("onSwipedAll");
        }}
        backgroundColor={store.surfaceColor}
        stackSize={5}
      ></Swiper>
    </ScrollView>
  );
});

class RenderedCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fav: props.item.item.cuisines.includes("My Favs"),
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    var { item, index, itemWidth, itemHeight, currentIndex } = this.props;
    var { item, cost, opensAt, closingIn } = item;

    return (
      <View
        key={index}
        style={{
          width: itemWidth,
          height: itemHeight,
          borderWidth: 2,
          backgroundColor: item.onlineOrdering ? "#fff" : "#efefef",
          borderColor: "#fff", // store.primaryColor,
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: 15,
          margin: 5,
          padding: 1,
          opacity: 1,

          // opacity: !item.isAvailable ? 0.4 : 1,
        }}
      >
        {opensAt !== 0 && (
          <View
            style={{
              borderRadius: 15,
              // backgroundColor: "#fff",
              padding: 5,
              position: "absolute",
              alignSelf: "center",
              top: "25%",
              left: "25%",
            }}
          >
            <Text
              style={{
                borderRadius: 15,
                backgroundColor: "#000",
                padding: 5,
                fontWeight: "100",
                fontSize: 14,
                fontFamily: store.font,
                color: "#fff",
                alignSelf: "center",
                // marginTop: -20,
              }}
              numberOfLines={1}
            >
              {" Opens at " + opensAt + " "}
            </Text>
          </View>
        )}
        <TouchableOpacity
          activeOpacity={1.0}
          onPress={(e) => {
            // RootNavigation.navigate("Restaurant", {
            if (item.orderingLink !== "") {
              if (item.newWindow)
                window.open(item.orderingLink, "_blank").focus();
              else
                RootNavigation.navigate("Tabs", {
                  screen: "RestaurantsStack",
                  params: {
                    screen: "Restaurant",
                    params: { id: item.id },
                  },
                });
            } else if (Platform.OS === "web") window.open(item.messenger);
            else Linking.openURL("fb://facewebmodal/f?href=" + item.messenger);
          }}
          style={{
            width: itemWidth,
            borderWidth: 0,
            backgroundColor: item.onlineOrdering ? "#fff" : "#efefef",
            borderColor: store.backgroundColor,
            justifyContent: "flex-start",
            alignItems: "center",
            opacity: !item.isAvailable ? 0.4 : 1,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <ImageBackground
            style={{
              width: "100%", //itemWidth,
              height: itemHeight - 4,
              borderRadius: 15,
              justifyContent: "flex-end",
            }}
            imageStyle={{
              borderRadius: 15,
              resizeMode: "cover",
              alignSelf: "flex-start",
            }}
            // style={{
            //   width: "100%", //itemWidth,
            //   height: itemHeight * 0.6,
            //   borderTopLeftRadius: 5,
            //   borderTopRightRadius: 5,
            // }}
            // imageStyle={{
            //   resizeMode: "cover",
            //   alignSelf: "flex-start",
            //   borderTopLeftRadius: 5,
            //   borderTopRightRadius: 5,
            // }}
            resizeMode="cover"
            source={{
              uri: store.getImageDirectory(item.heroImage),
            }}
          >
            {item.videoUrl &&
              item.videoUrl !== "" &&
              index === currentIndex && (
                <video
                  // paused={index !== currentIndex}
                  poster={store.getImageDirectory(item.heroImage)}
                  preload={"none"}
                  height="100%"
                  style={{
                    position: "absolute",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    zIndex: 0,
                    borderRadius: 15,
                  }}
                  controls={false}
                  autoPlay //={index === currentIndex}
                  loop
                  muted
                  playsInline
                >
                  <source
                    src={store.getImageDirectory(item.videoUrl)}
                    type="video/mp4"
                  />
                </video>
              )}
            <View
              style={{
                flex: 1,
                backgroundColor: "rgba(0,0,0, 0.35)",
                borderRadius: 15,
                justifyContent: "flex-end",
                position: "relative",
                zIndex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor:
                    item.orderingLink !== "" && item.newWindow
                      ? store.hexToRGB(item.backgroundColor, 0.7)
                      : item.orderingLink !== ""
                      ? "rgba(0,0,0, 0.1)"
                      : "rgba(2, 132, 255, 0.8)",
                }}
              >
                {!!item.showLogo && (
                  <TouchableOpacity
                    activeOpacity={1.0}
                    onPress={(e) => {
                      // RootNavigation.navigate("Restaurant", {
                      if (item.orderingLink !== "") {
                        if (item.newWindow)
                          window.open(item.orderingLink, "_blank").focus();
                        else
                          RootNavigation.navigate("Tabs", {
                            screen: "RestaurantsStack",
                            params: {
                              screen: "Restaurant",
                              params: { id: item.id },
                            },
                          });
                      } else if (Platform.OS === "web")
                        if (store.screenWidth > 800)
                          window.open(item.messenger, "_blank").focus();
                        else window.open(item.messenger);
                      else
                        Linking.openURL(
                          "fb://facewebmodal/f?href=" + item.messenger
                        );
                    }}
                  >
                    <View
                      style={{
                        width: 70,
                        height: 70,
                        borderRadius: 100,
                        backgroundColor:
                          item.logoImageBG !== ""
                            ? item.logoImageBG
                            : "transparent",
                        margin: 10,
                      }}
                    >
                      <Image
                        source={store.getImageDirectory(item.logoImageUrl)}
                        style={{
                          width: "100%",
                          height: "100%",
                          resizeMode: "cover",
                          borderRadius: 100,
                        }}
                        resizeMode="contain"
                      />
                    </View>
                  </TouchableOpacity>
                )}
                {!!item.orderingLink !== "" && item.newWindow ? (
                  <TouchableOpacity
                    activeOpacity={1.0}
                    onPress={(e) => {
                      // RootNavigation.navigate("Restaurant", {
                      if (Platform.OS === "web")
                        window.open(item.orderingLink, "_blank").focus();
                      else Linking.openURL(item.orderingLink);
                    }}
                    style={{
                      position: "absolute",
                      alignSelf: "center",
                      top: "5%",
                      zIndex: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Feather
                      name="external-link"
                      size={48}
                      color={"#fff"}
                      style={
                        {
                          //marginBottom: 20,
                          // marginRight: 20,
                        }
                      }
                    />
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 18,
                        font: store.fontFamily,
                        textAlign: "center",
                      }}
                    >
                      Open in New Tab
                    </Text>
                  </TouchableOpacity>
                ) : item.orderingLink === "" ? (
                  <TouchableOpacity
                    activeOpacity={1.0}
                    onPress={(e) => {
                      // RootNavigation.navigate("Restaurant", {
                      if (Platform.OS === "web")
                        if (store.screenWidth > 800)
                          window.open(item.messenger, "_blank").focus();
                        else window.open(item.messenger);
                      else
                        Linking.openURL(
                          "fb://facewebmodal/f?href=" + item.messenger
                        );
                    }}
                    style={{
                      position: "absolute",
                      alignSelf: "center",
                      top: "5%",
                      zIndex: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MaterialCommunityIcons
                      name="facebook-messenger"
                      size={64}
                      color={"#fff"}
                      style={
                        {
                          //marginBottom: 20,
                          // marginRight: 20,
                        }
                      }
                    />
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 18,
                        font: store.fontFamily,
                        textAlign: "center",
                      }}
                    >
                      Order via Messenger
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <View></View>
                )}
                <View
                  style={{
                    borderRadius: 15,
                    backgroundColor: "#efefef",
                    padding: 5,
                    position: "absolute",
                    top: 7,
                    right: 5,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "100",
                      fontSize: 10,
                      fontFamily: "Roboto_400Regular",
                      color: store.hexToRGB(store.textColor, 1),
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    numberOfLines={1}
                  >
                    {item.suburb}
                  </Text>
                </View>
                {closingIn > 0 && closingIn < 60 && (
                  <View
                    style={{
                      borderRadius: 15,
                      backgroundColor: store.primaryColor,
                      padding: 5,
                      position: "absolute",
                      bottom: 7,
                      right: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "100",
                        fontSize: 12,
                        fontFamily: "Roboto_400Regular",
                        color: "#fff", //store.hexToRGB(store.textColor, 0.6),
                        alignSelf: "flex-end",
                        // marginTop: -10,
                      }}
                      numberOfLines={1}
                    >
                      {" closes in " + closingIn + " minutes"}
                    </Text>
                  </View>
                )}
                <TouchableOpacity
                  onPress={() => {
                    item.setFavs("My Favs");
                    store.setFavs(item.title);
                    this.setState({ fav: !this.state.fav });
                  }}
                  style={{
                    position: "absolute",
                    top: 35,
                    right: 5,
                    width: 50,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name={this.state.fav ? "heart" : "heart-outline"}
                    style={{
                      color: "white",
                      fontSize: 25,
                    }}
                  />
                </TouchableOpacity>
              </View>

              <View
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  width: "100%",
                  marginTop: 2,
                  padding: 4,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    // minHeight: 25,
                  }}
                >
                  <TouchableOpacity
                    activeOpacity={1.0}
                    style={{
                      flex: 1,
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                    onPress={(e) => {
                      // RootNavigation.navigate("Restaurant", {
                      if (item.orderingLink !== "") {
                        if (item.newWindow)
                          window.open(item.orderingLink, "_blank").focus();
                        else
                          RootNavigation.navigate("Tabs", {
                            screen: "RestaurantsStack",
                            params: {
                              screen: "Restaurant",
                              params: { id: item.id },
                            },
                          });
                      } else if (Platform.OS === "web")
                        if (store.screenWidth > 800)
                          window.open(item.messenger, "_blank").focus();
                        else window.open(item.messenger);
                      else
                        Linking.openURL(
                          "fb://facewebmodal/f?href=" + item.messenger
                        );
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 24,
                        fontFamily: store.font,
                        color: "#fff",
                      }}
                      numberOfLines={1}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      flex: 0.8,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        store.setReviewDialogVisible(
                          !store.reviewDialogVisible
                        );
                        store.setSelectedRestaurantId(item.id);
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <MaterialCommunityIcons
                        name="star"
                        size={16}
                        color="#fff"
                      />
                      <Text
                        style={{
                          marginLeft: 2,
                          marginTop: -1,
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {item.rating}
                      </Text>
                      <Text
                        style={{
                          marginLeft: 2,
                          fontSize: 12,
                          marginTop: 1,
                          color: "#fff",
                        }}
                      >
                        {"(" + item.noReviews + " Ratings)"}
                      </Text>
                    </TouchableOpacity>
                    <Text
                      style={{
                        marginLeft: 5,
                        fontSize: 14,
                        marginTop: 0,
                        fontWeight: "bold",
                      }}
                    >
                      {!!item.priceBucket.replaceAll("$", store.currencyCode)}
                    </Text>
                    {store.selectedRestaurant.facebook !== "" && (
                      <TouchableOpacity
                        style={{ marginLeft: 5, marginTop: -2 }}
                        activeOpacity={1.0}
                        onPress={(e) => {
                          // RootNavigation.navigate("Restaurant", {
                          if (Platform.OS === "web")
                            if (store.screenWidth > 800)
                              window.open(item.facebook, "_blank").focus();
                            else window.open(item.facebook);
                          else
                            Linking.openURL(
                              "fb://facewebmodal/f?href=" + item.facebook
                            );
                        }}
                      >
                        <Fontisto
                          name="facebook"
                          size={16}
                          color={"#fff"}
                          style={
                            {
                              //marginBottom: 20,
                              // marginRight: 20,
                            }
                          }
                        />
                      </TouchableOpacity>
                    )}
                    {store.selectedRestaurant.phone !== "" && (
                      <TouchableOpacity
                        style={{ marginLeft: 10 }}
                        onPress={(e) => {
                          if (Platform.OS === "web")
                            window
                              .open(
                                "tel//:" + store.selectedRestaurant.phone,
                                "_blank"
                              )
                              .focus();
                          else
                            Linking.openURL(
                              "tel//:" + store.selectedRestaurant.phone
                            );
                        }}
                      >
                        <MaterialIcons
                          name="phone"
                          size={20}
                          color={"#fff"}
                          style={
                            {
                              //marginBottom: 20,
                              // marginRight: 20,
                            }
                          }
                        />
                      </TouchableOpacity>
                    )}
                    {store.selectedRestaurant.messenger !== "" && (
                      <TouchableOpacity
                        style={{ marginLeft: 5 }}
                        activeOpacity={1.0}
                        onPress={(e) => {
                          // RootNavigation.navigate("Restaurant", {
                          if (Platform.OS === "web")
                            if (store.screenWidth > 800)
                              window.open(item.messenger, "_blank").focus();
                            else window.open(item.messenger);
                          else
                            Linking.openURL(
                              "fb://facewebmodal/f?href=" + item.messenger
                            );
                        }}
                      >
                        <MaterialCommunityIcons
                          name="facebook-messenger"
                          size={24}
                          color={"#0284FF"}
                          style={
                            {
                              //marginBottom: 20,
                              // marginRight: 20,
                            }
                          }
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                  <View
                    style={{
                      flex: 0.2,
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        if (Platform.OS === "web")
                          window.open(
                            // "https://www.google.com/maps?saddr=My+Location&daddr=" +
                            //   item.gps[0] +
                            //   "," +
                            //   item.gps[1]
                            "https://www.google.com/maps/place/?q=place_id:" +
                              item.placeId
                          );
                        else
                          Linking.openURL(
                            // "https://www.google.com/maps?saddr=My+Location&daddr=" +
                            //   item.gps[0] +
                            //   "," +
                            //   item.gps[1]
                            "https://www.google.com/maps/place/?q=place_id:" +
                              item.placeId
                          );
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <MaterialCommunityIcons
                        name="map-marker-outline"
                        size={24}
                        color="#fff"
                      />
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Roboto_400Regular",
                      color: "#fff",
                      width: "100%",
                      marginTop: 2,
                      marginBottom: 0,
                    }}
                    numberOfLines={2}
                  >
                    {item.cuisines.toString()}
                  </Text>

                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Roboto_400Regular",
                      color: store.hexToRGB(store.textColor, 0.7),
                      flex: 1,
                      marginTop: 5,
                      marginBottom: 5,
                      color: "#fff",
                    }}
                    numberOfLines={3}
                  >
                    {store.location}
                    {" - "}
                    {cost == null || cost === -1
                      ? "Delivery not available"
                      : cost === 0
                      ? "Free Delivery"
                      : cost}
                  </Text>
                </View>
              </View>
            </View>
          </ImageBackground>
        </TouchableOpacity>
      </View>
    );
  }
}
export const RestaurantMap = observer((props) => {
  var { itemWidth, data } = props;

  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [hue, setHue] = useState(0);
  const color = `hsl(${hue % 360}deg 39% 70%)`;

  const [Restaurants, setRestaurants] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState("");
  const [center, setCenter] = useState([
    store.gps.toJSON()[0],
    store.gps.toJSON()[1],
  ]);
  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    setRestaurants(data.slice());
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setErrorMsg("Permission to access location was denied");
        return;
      }

      let location = await Location.getCurrentPositionAsync({});
      if (location && location.latitude) {
        store.setGPS([
          parseFloat(location.latitude),
          parseFloat(location.longitude),
        ]);
        setCenter([
          parseFloat(location.latitude),
          parseFloat(location.longitude),
        ]);
      }
    })();
  }, []);

  const RestaurantCard = (item, index) => {
    var cost = item.item.deliveryAreas.filter(
      (da) => da.name === store.location
    );
    if (cost.length > 0) cost = cost[0].cost;
    else cost = -1;
    return (
      <RenderedMapCard
        item={item}
        index={index}
        itemWidth={itemWidth}
        itemHeight={itemWidth}
      />
    );
  };

  const RestaurantMarker = (props, i) => {
    var restaurant = props.restaurant;

    return (
      <Overlay
        style={{
          zIndex:
            selected === restaurant.slug
              ? 1000
              : Restaurants.length - restaurant.order,
        }}
        anchor={restaurant.gps.toJSON()}
      >
        <TouchableOpacity
          onPress={(e) => {
            setSelected(restaurant.slug);
          }}
          style={{}}
        >
          <Image
            source={{ uri: store.getImageDirectory(restaurant.logoImageUrl) }}
            style={{ width: 30, height: 30, borderRadius: "50%" }}
          />
          <svg
            style={{
              fill: store.primaryColor, //rest.color,
              width: 35,
              left: -2,
              top: -4,
              position: "absolute",
              zIndex: -1,
            }}
            viewBox="0 0 384 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m172.268 501.67c-145.298-210.639-172.268-232.257-172.268-309.67 0-106.039 85.961-192 192-192s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zm19.732-229.67c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
          </svg>
        </TouchableOpacity>
      </Overlay>
    );
  };

  const maptilerProvider = maptiler("HB6w43Z9xrzlPAN6ySS8", "streets");
  return (
    <View
      key={center.toString() + store.search + store.categories.toString()}
      style={{
        flex: 1,
        minWidth: store.screenWidth,
        minHeight: store.screenHeight - 200,
        paddingTop: 190,
      }}
    >
      <Map
        key={selected}
        provider={maptilerProvider}
        dprs={[1, 2]}
        height={store.screenHeight - 200}
        defaultCenter={center}
        // center={center}
        defaultZoom={zoom}
        onBoundsChanged={({ center, zoom }) => {
          // setCenter(center);
          // setZoom(zoom);
        }}
      >
        {Restaurants.map((item, i) => (
          <Overlay
            key={i}
            style={{
              zIndex:
                selected === item.item.slug
                  ? 1000
                  : Restaurants.length - item.order,
            }}
            anchor={item.item.gps.toJSON()}
          >
            {selected === item.item.slug && (
              <View
                style={{
                  position: "absolute",
                  top: -250,
                  left: -100,
                  zIndex: 1000,
                }}
              >
                <RestaurantCard
                  itemWidth={props.itemWidth}
                  itemHeight={props.itemWidth}
                  item={item.item}
                />
              </View>
            )}
            <TouchableOpacity
              onPress={(e) => {
                setCenter(item.item.gps.toJSON());
                if (selected === item.item.slug) {
                  setSelected("");
                  setCenter([store.gps.toJSON()[0], store.gps.toJSON()[1]]);
                } else setSelected(item.item.slug);
              }}
              style={{ position: "absolute", zIndex: 1 }}
            >
              <Image
                source={{
                  uri: store.getImageDirectory(item.item.logoImageUrl),
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  // tintColor: store.hexToRGB(store.primaryColor, 0.7),
                }}
              />
              <svg
                style={{
                  fill:
                    selected === item.item.slug
                      ? store.primaryColor
                      : store.hexToRGB(store.primaryColor, 0.7), //rest.color,
                  width: 35,
                  left: -2,
                  top: -4,
                  position: "absolute",
                  zIndex: -1,
                }}
                viewBox="0 0 384 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m172.268 501.67c-145.298-210.639-172.268-232.257-172.268-309.67 0-106.039 85.961-192 192-192s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zm19.732-229.67c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
              </svg>
            </TouchableOpacity>
          </Overlay>
        ))}
      </Map>
    </View>
  );
});

class RenderedMapCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fav: props.item.item.cuisines.includes("My Favs"),
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    var { item, index, itemWidth, itemHeight } = this.props;
    var { item, cost, opensAt, closingIn } = item;

    return (
      <View
        key={index}
        style={{
          width: itemWidth,
          height: itemHeight,
          borderWidth: 2,
          backgroundColor: item.onlineOrdering ? "#fff" : "#efefef",
          borderColor: "#fff", // store.primaryColor,
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: 15,
          margin: 5,
          padding: 1,
          opacity: 1,
          // opacity: !item.isAvailable ? 0.4 : 1,
        }}
      >
        {opensAt !== 0 && (
          <View
            style={{
              borderRadius: 15,
              // backgroundColor: "#fff",
              padding: 5,
              position: "absolute",
              alignSelf: "center",
              top: "25%",
              left: "25%",
            }}
          >
            <Text
              style={{
                borderRadius: 15,
                backgroundColor: "#000",
                padding: 5,
                fontWeight: "100",
                fontSize: 14,
                fontFamily: store.font,
                color: "#fff",
                alignSelf: "center",
                // marginTop: -20,
              }}
              numberOfLines={1}
            >
              {" Opens at " + opensAt + " "}
            </Text>
          </View>
        )}
        <TouchableOpacity
          activeOpacity={1.0}
          onPress={(e) => {
            // RootNavigation.navigate("Restaurant", {
            if (item.orderingLink !== "") {
              if (item.newWindow)
                window.open(item.orderingLink, "_blank").focus();
              else
                RootNavigation.navigate("Tabs", {
                  screen: "RestaurantsStack",
                  params: {
                    screen: "Restaurant",
                    params: { id: item.id },
                  },
                });
            } else if (Platform.OS === "web") window.open(item.messenger);
            else Linking.openURL("fb://facewebmodal/f?href=" + item.messenger);
          }}
          style={{
            width: itemWidth,
            borderWidth: 0,
            backgroundColor: item.onlineOrdering ? "#fff" : "#efefef",
            borderColor: store.backgroundColor,
            justifyContent: "flex-start",
            alignItems: "center",
            opacity: !item.isAvailable ? 0.4 : 1,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <ImageBackground
            style={{
              width: "100%", //itemWidth,
              height: itemHeight - 4,
              borderRadius: 15,
              justifyContent: "flex-end",
            }}
            imageStyle={{
              borderRadius: 15,
              resizeMode: "cover",
              alignSelf: "flex-start",
            }}
            // style={{
            //   width: "100%", //itemWidth,
            //   height: itemHeight * 0.6,
            //   borderTopLeftRadius: 5,
            //   borderTopRightRadius: 5,
            // }}
            // imageStyle={{
            //   resizeMode: "cover",
            //   alignSelf: "flex-start",
            //   borderTopLeftRadius: 5,
            //   borderTopRightRadius: 5,
            // }}
            resizeMode="cover"
            source={{
              uri: store.getImageDirectory(item.heroImage),
            }}
          >
            {item.videoUrl && item.videoUrl !== "" && (
              <video
                poster={store.getImageDirectory(item.heroImage)}
                preload={"none"}
                height="100%"
                style={{
                  position: "absolute",
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  zIndex: 0,
                  borderRadius: 15,
                }}
                controls={false}
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src={store.getImageDirectory(item.videoUrl)}
                  type="video/mp4"
                />
              </video>
            )}
            <View
              style={{
                flex: 1,
                backgroundColor: "rgba(0,0,0, 0.35)",
                borderRadius: 15,
                justifyContent: "flex-end",
                position: "relative",
                zIndex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor:
                    item.orderingLink !== "" && item.newWindow
                      ? store.hexToRGB(item.backgroundColor, 0.7)
                      : item.orderingLink !== ""
                      ? "rgba(0,0,0, 0.1)"
                      : "rgba(2, 132, 255, 0.8)",
                }}
              >
                {!!item.showLogo && (
                  <TouchableOpacity
                    activeOpacity={1.0}
                    onPress={(e) => {
                      // RootNavigation.navigate("Restaurant", {
                      if (item.orderingLink !== "") {
                        if (item.newWindow)
                          window.open(item.orderingLink, "_blank").focus();
                        else
                          RootNavigation.navigate("Tabs", {
                            screen: "RestaurantsStack",
                            params: {
                              screen: "Restaurant",
                              params: { id: item.id },
                            },
                          });
                      } else if (Platform.OS === "web")
                        if (store.screenWidth > 800)
                          window.open(item.messenger, "_blank").focus();
                        else window.open(item.messenger);
                      else
                        Linking.openURL(
                          "fb://facewebmodal/f?href=" + item.messenger
                        );
                    }}
                  >
                    <View
                      style={{
                        width: 70,
                        height: 70,
                        borderRadius: 100,
                        backgroundColor:
                          item.logoImageBG !== ""
                            ? item.logoImageBG
                            : "transparent",
                        margin: 10,
                      }}
                    >
                      <Image
                        source={store.getImageDirectory(item.logoImageUrl)}
                        style={{
                          width: "100%",
                          height: "100%",
                          resizeMode: "cover",
                          borderRadius: 100,
                        }}
                        resizeMode="contain"
                      />
                    </View>
                  </TouchableOpacity>
                )}
                {!!item.orderingLink !== "" && item.newWindow ? (
                  <TouchableOpacity
                    activeOpacity={1.0}
                    onPress={(e) => {
                      // RootNavigation.navigate("Restaurant", {
                      if (Platform.OS === "web")
                        window.open(item.orderingLink, "_blank").focus();
                      else Linking.openURL(item.orderingLink);
                    }}
                    style={{
                      position: "absolute",
                      alignSelf: "center",
                      top: "5%",
                      zIndex: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Feather
                      name="external-link"
                      size={48}
                      color={"#fff"}
                      style={
                        {
                          //marginBottom: 20,
                          // marginRight: 20,
                        }
                      }
                    />
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 18,
                        font: store.fontFamily,
                        textAlign: "center",
                      }}
                    >
                      Open in New Tab
                    </Text>
                  </TouchableOpacity>
                ) : item.orderingLink === "" ? (
                  <TouchableOpacity
                    activeOpacity={1.0}
                    onPress={(e) => {
                      // RootNavigation.navigate("Restaurant", {
                      if (Platform.OS === "web")
                        if (store.screenWidth > 800)
                          window.open(item.messenger, "_blank").focus();
                        else window.open(item.messenger);
                      else
                        Linking.openURL(
                          "fb://facewebmodal/f?href=" + item.messenger
                        );
                    }}
                    style={{
                      position: "absolute",
                      alignSelf: "center",
                      top: "5%",
                      zIndex: 100,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MaterialCommunityIcons
                      name="facebook-messenger"
                      size={64}
                      color={"#fff"}
                      style={
                        {
                          //marginBottom: 20,
                          // marginRight: 20,
                        }
                      }
                    />
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 18,
                        font: store.fontFamily,
                        textAlign: "center",
                      }}
                    >
                      Order via Messenger
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <View></View>
                )}
                <View
                  style={{
                    borderRadius: 15,
                    backgroundColor: "#efefef",
                    padding: 5,
                    position: "absolute",
                    top: 7,
                    right: 5,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "100",
                      fontSize: 10,
                      fontFamily: "Roboto_400Regular",
                      color: store.hexToRGB(store.textColor, 1),
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    numberOfLines={1}
                  >
                    {item.suburb}
                  </Text>
                </View>
                {closingIn > 0 && closingIn < 60 && (
                  <View
                    style={{
                      borderRadius: 15,
                      backgroundColor: store.primaryColor,
                      padding: 5,
                      position: "absolute",
                      bottom: 7,
                      right: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "100",
                        fontSize: 12,
                        fontFamily: "Roboto_400Regular",
                        color: "#fff", //store.hexToRGB(store.textColor, 0.6),
                        alignSelf: "flex-end",
                        // marginTop: -10,
                      }}
                      numberOfLines={1}
                    >
                      {" closes in " + closingIn + " minutes"}
                    </Text>
                  </View>
                )}
                <TouchableOpacity
                  onPress={() => {
                    item.setFavs("My Favs");
                    store.setFavs(item.title);
                    this.setState({ fav: !this.state.fav });
                  }}
                  style={{
                    position: "absolute",
                    top: 35,
                    right: 5,
                    width: 50,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name={this.state.fav ? "heart" : "heart-outline"}
                    style={{
                      color: "white",
                      fontSize: 25,
                    }}
                  />
                </TouchableOpacity>
              </View>

              <View
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  width: "100%",
                  marginTop: 2,
                  padding: 4,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    // minHeight: 25,
                  }}
                >
                  <TouchableOpacity
                    activeOpacity={1.0}
                    style={{
                      flex: 1,
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                    onPress={(e) => {
                      // RootNavigation.navigate("Restaurant", {
                      if (item.orderingLink !== "") {
                        if (item.newWindow)
                          window.open(item.orderingLink, "_blank").focus();
                        else
                          RootNavigation.navigate("Tabs", {
                            screen: "RestaurantsStack",
                            params: {
                              screen: "Restaurant",
                              params: { id: item.id },
                            },
                          });
                      } else if (Platform.OS === "web")
                        if (store.screenWidth > 800)
                          window.open(item.messenger, "_blank").focus();
                        else window.open(item.messenger);
                      else
                        Linking.openURL(
                          "fb://facewebmodal/f?href=" + item.messenger
                        );
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 24,
                        fontFamily: store.font,
                        color: "#fff",
                      }}
                      numberOfLines={1}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      flex: 0.8,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        store.setReviewDialogVisible(
                          !store.reviewDialogVisible
                        );
                        store.setSelectedRestaurantId(item.id);
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <MaterialCommunityIcons
                        name="star"
                        size={16}
                        color="#fff"
                      />
                      <Text
                        style={{
                          marginLeft: 2,
                          marginTop: -1,
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {item.rating}
                      </Text>
                      <Text
                        style={{
                          marginLeft: 2,
                          fontSize: 12,
                          marginTop: 1,
                          color: "#fff",
                        }}
                      >
                        {"(" + item.noReviews + " Ratings)"}
                      </Text>
                    </TouchableOpacity>
                    <Text
                      style={{
                        marginLeft: 5,
                        fontSize: 14,
                        marginTop: 0,
                        fontWeight: "bold",
                      }}
                    >
                      {!!item.priceBucket.replaceAll("$", store.currencyCode)}
                    </Text>
                    {store.selectedRestaurant.facebook !== "" && (
                      <TouchableOpacity
                        style={{ marginLeft: 5, marginTop: -2 }}
                        activeOpacity={1.0}
                        onPress={(e) => {
                          // RootNavigation.navigate("Restaurant", {
                          if (Platform.OS === "web")
                            if (store.screenWidth > 800)
                              window.open(item.facebook, "_blank").focus();
                            else window.open(item.facebook);
                          else
                            Linking.openURL(
                              "fb://facewebmodal/f?href=" + item.facebook
                            );
                        }}
                      >
                        <Fontisto
                          name="facebook"
                          size={16}
                          color={"#fff"}
                          style={
                            {
                              //marginBottom: 20,
                              // marginRight: 20,
                            }
                          }
                        />
                      </TouchableOpacity>
                    )}
                    {store.selectedRestaurant.phone !== "" && (
                      <TouchableOpacity
                        style={{ marginLeft: 10 }}
                        onPress={(e) => {
                          if (Platform.OS === "web")
                            window
                              .open(
                                "tel//:" + store.selectedRestaurant.phone,
                                "_blank"
                              )
                              .focus();
                          else
                            Linking.openURL(
                              "tel//:" + store.selectedRestaurant.phone
                            );
                        }}
                      >
                        <MaterialIcons
                          name="phone"
                          size={20}
                          color={"#fff"}
                          style={
                            {
                              //marginBottom: 20,
                              // marginRight: 20,
                            }
                          }
                        />
                      </TouchableOpacity>
                    )}
                    {store.selectedRestaurant.messenger !== "" && (
                      <TouchableOpacity
                        style={{ marginLeft: 5 }}
                        activeOpacity={1.0}
                        onPress={(e) => {
                          // RootNavigation.navigate("Restaurant", {
                          if (Platform.OS === "web")
                            if (store.screenWidth > 800)
                              window.open(item.messenger, "_blank").focus();
                            else window.open(item.messenger);
                          else
                            Linking.openURL(
                              "fb://facewebmodal/f?href=" + item.messenger
                            );
                        }}
                      >
                        <MaterialCommunityIcons
                          name="facebook-messenger"
                          size={24}
                          color={"#0284FF"}
                          style={
                            {
                              //marginBottom: 20,
                              // marginRight: 20,
                            }
                          }
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                  <View
                    style={{
                      flex: 0.2,
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        if (Platform.OS === "web")
                          window.open(
                            // "https://www.google.com/maps?saddr=My+Location&daddr=" +
                            //   item.gps[0] +
                            //   "," +
                            //   item.gps[1]
                            "https://www.google.com/maps/place/?q=place_id:" +
                              item.placeId
                          );
                        else
                          Linking.openURL(
                            // "https://www.google.com/maps?saddr=My+Location&daddr=" +
                            //   item.gps[0] +
                            //   "," +
                            //   item.gps[1]
                            "https://www.google.com/maps/place/?q=place_id:" +
                              item.placeId
                          );
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <MaterialCommunityIcons
                        name="map-marker-outline"
                        size={24}
                        color="#fff"
                      />
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Roboto_400Regular",
                      color: "#fff",
                      width: "100%",
                      marginTop: 2,
                      marginBottom: 0,
                    }}
                    numberOfLines={2}
                  >
                    {item.cuisines.toString()}
                  </Text>

                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Roboto_400Regular",
                      color: store.hexToRGB(store.textColor, 0.7),
                      flex: 1,
                      marginTop: 5,
                      marginBottom: 5,
                      color: "#fff",
                    }}
                    numberOfLines={3}
                  >
                    {store.location}
                    {" - "}
                    {cost == null || cost === -1
                      ? "Delivery not available"
                      : cost === 0
                      ? "Free Delivery"
                      : cost}
                  </Text>
                </View>
              </View>
            </View>
          </ImageBackground>
        </TouchableOpacity>
      </View>
    );
  }
}

export const SelectSort = observer((props) => {
  const [sortBy, setSortBy] = useState(store.sortBy);

  var selectBorderColor = store.primaryColor; //store.hexToRGB("#000", 0.3);
  var selectBackgroundColor = "#fff";
  var selectTextColor = store.primaryColor;

  const pickerSelectStyles = {
    inputIOS: {
      fontSize: 12,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: selectBorderColor,
      borderRadius: 25,
      color: selectTextColor,
      backgroundColor: selectBackgroundColor,
    },
    inputWeb: {
      fontSize: 12,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: selectBorderColor,
      borderRadius: 25,
      color: selectTextColor,
      backgroundColor: selectBackgroundColor,
      // paddingRight: 30,
    },
    inputAndroid: {
      fontSize: 12,
      paddingVertical: 6,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: selectBorderColor,
      borderRadius: 25,
      color: selectTextColor,
      backgroundColor: selectBackgroundColor,
    },
  };

  return (
    <View
      style={{
        flex: 1,
        minWidth: 170,
        maxWidth: 170,
        minHeight: 30,
        maxHeight: 30,
        justifyContent: "center",
        paddingHorizontal: 5,
        flexDirection: "row",
        marginRight: 5,
      }}
    >
      <TouchableOpacity
        onPress={(e) => {
          store.setRestaurantsDisplay(
            store.restaurantsDisplay === "map" ? "list" : "map"
          );
          setTimeout(() => {
            store.setShowSideModal(false);
          }, 500);
        }}
        style={{
          marginTop: 0,
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <MaterialCommunityIcons
          name={
            store.restaurantsDisplay === "map"
              ? "map-marker"
              : "map-marker-outline"
          }
          size={32}
          color={store.primaryColor}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onLongPress={() => {
          console.log("pressed");
          props.setShowTotalOrders(!props.showTotalOrders);
        }}
        style={{
          width: 30,
          height: 30,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 15,
        }}
      >
        <MaterialIcons
          name={
            props.showTotalOrders
              ? "radio-button-checked"
              : "radio-button-unchecked"
          }
          size={18}
          color="#fff"
        />
      </TouchableOpacity>
      {props.showButtons && (
        <TouchableOpacity
          activeOpacity={1.0}
          style={{
            height: 30,
            flex: 0.3,
            marginHorizontal: 5,
            padding: 8,
            borderRadius: 15,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor:
              store.restaurantsDisplay === "flick"
                ? store.primaryColor
                : "#fff",
          }}
          onPress={(e) => {
            if (store.restaurantsDisplay === "flick")
              store.setRestaurantsDisplay("list");
            else store.setRestaurantsDisplay("flick");
            setTimeout(() => {
              store.setShowSideModal(false);
            }, 500);
          }}
        >
          <Text
            style={{
              alignSelf: "center",
              fontSize: 11,
              color:
                store.restaurantsDisplay === "flick"
                  ? "#fff"
                  : store.primaryColor,
            }}
          >
            Flick
          </Text>
        </TouchableOpacity>
      )}
      <View style={{ flex: props.showButtons ? 0.7 : 1 }}>
        <RNPickerSelect
          placeholder={{}}
          //   label: "Select a number or add another...",
          //   value: null,
          //   color: "red",
          // }}
          onValueChange={(value) => {
            setSortBy(value);
            store.setSortBy(value, true);
            RootNavigation.navigate("Tabs", {
              screen: "RestaurantsStack",
              params: {
                screen: "Restaurants",
                params: {
                  category: store.categories.toJSON()[0],
                  sort: value,
                  search: store.search,
                },
              },
            });
          }}
          value={sortBy}
          // onValueChange={(value) => console.log(value)}
          items={store.sort
            .toJSON()
            .map((sortItem, i) => ({
              value: sortItem,
              label: "Sort " + sortItem,
            }))
            .slice()}
          style={{
            ...pickerSelectStyles,
            ...{
              borderColor: store.primaryColor,
              minWidth: 120,
              maxWidth: 120,
              minHeight: 30,
              maxHeight: 30,
            },
          }}
        />
      </View>
    </View>
  );
});

export const ReviewDialog = observer((props) => {
  var { restaurant } = props;
  if (!restaurant) return <View></View>;
  // restaurant.sortedReviews.map((review) => {
  //   console.log(review);
  // });

  const _renderTruncatedFooter = (handlePress) => {
    return (
      <Text style={{ color: "#666", marginTop: 5 }} onPress={handlePress}>
        Read more
      </Text>
    );
  };

  const _renderRevealedFooter = (handlePress) => {
    return (
      <Text style={{ color: "#666", marginTop: 5 }} onPress={handlePress}>
        Show less
      </Text>
    );
  };

  return (
    <Portal>
      <Dialog
        visible={store.reviewDialogVisible}
        onDismiss={(e) => {
          store.setReviewDialogVisible(false);
        }}
        style={{
          maxWidth:
            store.screenWidth > 800
              ? store.screenWidth * 0.7
              : store.screenWidth - 20,
          alignSelf: "center",
          height:
            store.screenWidth > 800
              ? store.screenHeight * 0.7
              : store.screenHeight - 50,
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: store.surfaceColor,
        }}
      >
        <Dialog.Title
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "#000",
            marginTop: 10,
            maxWidth: "70%",
            textAlign: "center",
          }}
          numberOfLines={2}
        >
          {/* <Image
          source={{
            uri: store.getImageDirectory(restaurant.logoImage),
          }}
          style={{
            width: "100%",
            maxWidth: 100,
            height: 80,
          }}
          resizeMode="contain"
        /> */}
          {restaurant.title} Reviews
          <TouchableOpacity
            onPress={(e) => {
              store.setReviewDialogVisible(false);
            }}
            style={{
              position: "absolute",
              right: 5,
              top: 5,
            }}
          >
            <MaterialCommunityIcons
              name="window-close"
              size={24}
              color="#666"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              backgroundColor: "#1a73e8",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: 100,
              height: 30,
              position: "absolute",
              right: store.screenWidth > 800 ? 35 : 10,
              top: store.screenWidth > 800 ? 40 : 40,
            }}
            onPress={(e) => {
              var url =
                "https://search.google.com/local/writereview?placeid=" +
                restaurant.placeId;
              if (Platform.OS == "web") {
                window.open(url, "_blank");
              } else {
                Linking.openURL(url);
              }
            }}
          >
            <MaterialIcons name="rate-review" size={18} color="#fff" />
            <Text
              style={{
                color: "#fff",
                fontSize: 9,
                fontWeight: "bold",
                marginLeft: 5,
              }}
            >
              Write a review
            </Text>
          </TouchableOpacity>
        </Dialog.Title>
        <Dialog.Content style={{ marginTop: 20 }}>
          <ScrollView
            style={{
              flex: 1,
              maxHeight:
                store.screenWidth > 800
                  ? store.screenHeight * 0.7 - 100
                  : store.screenHeight - 150,
            }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <Paragraph>
              {restaurant &&
                restaurant.sortedReviews.map((review, i) => (
                  <View key={i}>
                    {(review.rating > 3 || i > 0) && (
                      <View
                        style={{
                          flexDirection: "column",
                          minHeight: 60,
                          marginBottom: 25,
                        }}
                      >
                        <View style={{ flexDirection: "row", minHeight: 60 }}>
                          <Image
                            source={{ uri: review.profile }}
                            style={{ width: 40, height: 40, marginRight: 5 }}
                          />
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                lineHeight: 12,
                                flexDirection: "row",
                              }}
                              numberOfLines={1}
                            >
                              {review.name}
                              <Text
                                numberOfLines={2}
                                style={{
                                  color: "#666",
                                  fontSize: 10,
                                  fontWeight: "normal",
                                }}
                              >
                                {review.posted}
                              </Text>
                            </Text>
                            <View style={{ flexDirection: "row" }}>
                              <Text
                                style={{ fontWeight: "bold", fontSize: 14 }}
                                numberOfLines={2}
                              >
                                {review.rating}
                              </Text>
                              <MaterialCommunityIcons
                                name="star"
                                size={18}
                                color="#FCBE01"
                              />
                            </View>
                          </View>
                        </View>

                        <ReadMore
                          numberOfLines={3}
                          renderTruncatedFooter={_renderTruncatedFooter}
                          renderRevealedFooter={_renderRevealedFooter}
                        >
                          <Text style={{ fontSize: 13, lineHeight: 10 }}>
                            "{review.description}"
                          </Text>
                        </ReadMore>
                      </View>
                    )}
                  </View>
                ))}
            </Paragraph>
          </ScrollView>
        </Dialog.Content>
      </Dialog>
    </Portal>
  );
});

export const PayPalDialog = observer((props) => {
  const [paid, setPaid] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orderTime, setOrderTime] = useState(new Date());
  const [amount, setAmount] = useState(0);
  var { restaurant } = props;
  if (!restaurant) return <View></View>;

  const inputStyle = {
    borderColor: "#2B2E2F",
    backgroundColor: store.surfaceColor,
    borderWidth: 1,
    borderStyle: "solid",
    padding: 10,
    marginVertical: 5,
    minHeight: 40,
    borderRadius: 5,
  };

  return (
    <Portal>
      <Dialog
        visible={store.PayPalDialogVisible}
        onDismiss={(e) => {
          store.setPayPalDialogVisible(false);
        }}
        style={{
          maxWidth:
            store.screenWidth > 800
              ? store.screenWidth * 0.7
              : store.screenWidth - 20,
          alignSelf: "center",
          height:
            store.screenWidth > 800
              ? store.screenHeight * 0.7
              : store.screenHeight - 250,
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: store.surfaceColor,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 10,
            maxWidth: "80%",
          }}
        >
          {restaurant.logoImageUrl && (
            <View
              style={{
                width: 60,
                height: 60,
                borderRadius: 100,
                backgroundColor:
                  restaurant.logoImageBG !== ""
                    ? restaurant.logoImageBG
                    : "transparent",
                margin: 10,
              }}
            >
              <Image
                source={store.getImageDirectory(restaurant.logoImageUrl)}
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "cover",
                  borderRadius: 100,
                }}
                resizeMode="contain"
              />
            </View>
          )}
          <Text
            numberOfLines={3}
            style={{
              color: "#2B2E2F",
              fontSize: 16,
              textAlign: "center",
              marginTop: 5,
            }}
          >
            {!paid
              ? "Pay " + restaurant.title + " Order With "
              : "" + restaurant.title + " Order PAID "}
            <Text style={{ color: "#003087" }}>{"Pay"}</Text>
            <Text style={{ color: "#019DDD" }}>{"Pal"}</Text>
          </Text>
        </View>
        <TouchableOpacity
          onPress={(e) => {
            store.setPaypalDialogVisible(false);
          }}
          style={{
            position: "absolute",
            right: 5,
            top: 5,
          }}
        >
          <MaterialCommunityIcons name="window-close" size={24} color="#666" />
        </TouchableOpacity>
        <Dialog.Content style={{ marginTop: 20 }}>
          <ScrollView
            style={{
              flex: 1,
              maxHeight:
                store.screenWidth > 800
                  ? store.screenHeight * 0.7 - 100
                  : store.screenHeight - 150,
            }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            {!paid ? (
              <Paragraph>
                <ScrollView
                  style={{
                    maxWidth: "750px",
                    minHeight: "200px",
                    maxHeight: store.screenHeight - 300,
                  }}
                >
                  <PayPalScriptProvider
                    options={{
                      "client-id": restaurant.paypal,
                      components: "buttons",
                      currency: "USD",
                    }}
                  >
                    <TextInput
                      required
                      placeholder={"Enter your name"}
                      placeholderTextColor={store.hexToRGB("#2B2E2F", 0.4)}
                      onChangeText={(e) => {
                        setName(e);
                      }}
                      defaultValue={name}
                      // value={name}
                      style={inputStyle}
                    />
                    <TextInput
                      required
                      placeholder={"Enter your email"}
                      placeholderTextColor={store.hexToRGB("#2B2E2F", 0.4)}
                      onChangeText={(e) => {
                        setEmail(e);
                      }}
                      // value={email}
                      defaultValue={email}
                      style={inputStyle}
                    />
                    <TextInput
                      required
                      placeholder={
                        "Enter your order amount (" + store.currency + ")"
                      }
                      placeholderTextColor={store.hexToRGB("#2B2E2F", 0.4)}
                      onChangeText={(e) => {
                        setAmount(e);
                      }}
                      // value={amount}
                      defaultValue={amount}
                      style={inputStyle}
                    />
                    <ButtonWrapper
                      restaurant={restaurant}
                      currency={store.exchangeCurrency}
                      email={email}
                      name={name}
                      original_amount={amount}
                      amount={parseFloat(amount / store.exchangeRate).toFixed(
                        2
                      )}
                      showSpinner={false}
                      setPaid={setPaid}
                    />
                  </PayPalScriptProvider>
                </ScrollView>
              </Paragraph>
            ) : (
              <Paragraph>
                <View
                  style={{
                    margin: 20,
                    marginBottom: 0,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name="check-circle"
                    size={48}
                    color="#65FF02"
                    style={{ marginBottom: 25 }}
                  />
                  <Text style={{ textAlign: "center" }}>
                    {"Thanks, your order has been paid.\n\n"}
                    {"Please contact us if you have any questions or issues.\n"}
                  </Text>
                  <View
                    style={{
                      margin: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <TouchableOpacity
                      style={{ marginHorizontal: 10 }}
                      onPress={(e) => {
                        if (Platform.OS === "web")
                          window
                            .open("tel//:" + restaurant.phone, "_blank")
                            .focus();
                        else Linking.openURL("tel//:" + restaurant.phone);
                      }}
                    >
                      <MaterialIcons
                        name="phone"
                        size={32}
                        color={restaurant.primaryColor}
                        style={
                          {
                            //marginBottom: 20,
                            // marginRight: 20,
                          }
                        }
                      />
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{ marginHorizontal: 10 }}
                      onPress={(e) => {
                        if (Platform.OS === "web")
                          window.open(restaurant.messenger, "_blank").focus();
                        else
                          Linking.openURL(
                            "fb://facewebmodal/f?href=" + restaurant.messenger
                          );
                      }}
                    >
                      <MaterialCommunityIcons
                        name="facebook-messenger"
                        size={32}
                        color={restaurant.primaryColor}
                        style={
                          {
                            //marginBottom: 20,
                            // marginRight: 20,
                          }
                        }
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </Paragraph>
            )}
          </ScrollView>
        </Dialog.Content>
      </Dialog>
    </Portal>
  );
});

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({
  restaurant,
  currency,
  showSpinner,
  original_amount,
  amount,
  email,
  name,
  setPaid,
}) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);

  const style = {
    layout: "vertical",
    // label: restaurant.title.replace("'", "") + " order payment",
  };

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  description:
                    restaurant.title +
                    " order - " +
                    name +
                    " - " +
                    original_amount +
                    " " +
                    store.currency,
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
            setPaid(true);
            // Your code here after capture the order
          });
        }}
      />
    </>
  );
};

export const MomoDialog = observer((props) => {
  const [paid, setPaid] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orderTime, setOrderTime] = useState(new Date());
  const [amount, setAmount] = useState(0);
  var { restaurant } = props;
  if (!restaurant) return <View></View>;

  const inputStyle = {
    borderColor: "#2B2E2F",
    backgroundColor: store.surfaceColor,
    borderWidth: 1,
    borderStyle: "solid",
    padding: 10,
    marginVertical: 5,
    minHeight: 40,
    borderRadius: 5,
  };

  return (
    <Portal>
      <Dialog
        visible={store.momoDialogVisible}
        onDismiss={(e) => {
          store.setMomoDialogVisible(false);
        }}
        style={{
          maxWidth:
            store.screenWidth > 800
              ? store.screenWidth * 0.7
              : store.screenWidth - 20,
          alignSelf: "center",
          height:
            store.screenWidth > 800
              ? store.screenHeight * 0.7
              : store.screenHeight - 250,
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: store.surfaceColor,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 10,
            maxWidth: "80%",
          }}
        >
          {restaurant.logoImageUrl && (
            <View
              style={{
                width: 60,
                height: 60,
                borderRadius: 100,
                backgroundColor:
                  restaurant.logoImageBG !== ""
                    ? restaurant.logoImageBG
                    : "transparent",
                margin: 10,
              }}
            >
              <Image
                source={store.getImageDirectory(restaurant.logoImageUrl)}
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "cover",
                  borderRadius: 100,
                }}
                resizeMode="contain"
              />
            </View>
          )}
          <Text
            numberOfLines={3}
            style={{
              color: "#2B2E2F",
              fontSize: 16,
              textAlign: "center",
              marginTop: 5,
            }}
          >
            {!paid
              ? "Pay " + restaurant.title + " Order With "
              : "" + restaurant.title + " Order PAID "}
            <Text style={{ color: "#B02874" }}>{"MoMo"}</Text>
          </Text>
        </View>
        <TouchableOpacity
          onPress={(e) => {
            store.setMomoDialogVisible(false);
          }}
          style={{
            position: "absolute",
            right: 5,
            top: 5,
          }}
        >
          <MaterialCommunityIcons name="window-close" size={24} color="#666" />
        </TouchableOpacity>
        <Dialog.Content style={{ marginTop: 20 }}>
          <ScrollView
            style={{
              flex: 1,
              maxHeight:
                store.screenWidth > 800
                  ? store.screenHeight * 0.7 - 100
                  : store.screenHeight - 150,
            }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            {!paid ? (
              <Paragraph>
                <ScrollView
                  style={{
                    maxWidth: "750px",
                    minHeight: "200px",
                    maxHeight: store.screenHeight - 300,
                  }}
                >
                  <View>
                    {/* <TextInput
                    required
                    placeholder={"Enter your name"}
                    placeholderTextColor={store.hexToRGB("#2B2E2F", 0.4)}
                    onChangeText={(e) => {
                      setName(e);
                    }}
                    defaultValue={name}
                    // value={name}
                    style={inputStyle}
                  />
                  <TextInput
                    required
                    placeholder={"Enter your email"}
                    placeholderTextColor={store.hexToRGB("#2B2E2F", 0.4)}
                    onChangeText={(e) => {
                      setEmail(e);
                    }}
                    // value={email}
                    defaultValue={email}
                    style={inputStyle}
                  />
                  <TextInput
                    required
                    placeholder={
                      "Enter your order amount (" + store.currency + ")"
                    }
                    placeholderTextColor={store.hexToRGB("#2B2E2F", 0.4)}
                    onChangeText={(e) => {
                      setAmount(e);
                    }}
                    // value={amount}
                    defaultValue={amount}
                    style={inputStyle}
                  /> */}
                    <QRCode
                      value={restaurant.momo}
                      size={200}
                      logoSize={30}
                      color={"#B02874"}
                    />
                  </View>
                </ScrollView>
              </Paragraph>
            ) : (
              <Paragraph>
                <View
                  style={{
                    margin: 20,
                    marginBottom: 0,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name="check-circle"
                    size={48}
                    color="#65FF02"
                    style={{ marginBottom: 25 }}
                  />
                  <Text style={{ textAlign: "center" }}>
                    {"Thanks, your order has been paid.\n\n"}
                    {"Please contact us if you have any questions or issues.\n"}
                  </Text>
                  <View
                    style={{
                      margin: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <TouchableOpacity
                      style={{ marginHorizontal: 10 }}
                      onPress={(e) => {
                        if (Platform.OS === "web")
                          window
                            .open("tel//:" + restaurant.phone, "_blank")
                            .focus();
                        else Linking.openURL("tel//:" + restaurant.phone);
                      }}
                    >
                      <MaterialIcons
                        name="phone"
                        size={32}
                        color={restaurant.primaryColor}
                        style={
                          {
                            //marginBottom: 20,
                            // marginRight: 20,
                          }
                        }
                      />
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{ marginHorizontal: 10 }}
                      onPress={(e) => {
                        if (Platform.OS === "web")
                          window.open(restaurant.messenger, "_blank").focus();
                        else
                          Linking.openURL(
                            "fb://facewebmodal/f?href=" + restaurant.messenger
                          );
                      }}
                    >
                      <MaterialCommunityIcons
                        name="facebook-messenger"
                        size={32}
                        color={restaurant.primaryColor}
                        style={
                          {
                            //marginBottom: 20,
                            // marginRight: 20,
                          }
                        }
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </Paragraph>
            )}
          </ScrollView>
        </Dialog.Content>
      </Dialog>
    </Portal>
  );
});

export const CloseDialog = observer((props) => {
  var { restaurant } = props;

  return (
    <Portal>
      <Dialog
        visible={store.closeAlertVisible}
        onDismiss={(e) => {
          store.setCloseAlertVisible(false);
          if (props.closeRestModal) props.closeRestModal();
        }}
        style={{
          maxWidth: 500,
          alignSelf: "center",
          height: 250,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Dialog.Title>Leave {restaurant.title} Order</Dialog.Title>
        <Dialog.Content>
          <Paragraph>
            Are you sure want to leave your order with {restaurant.title}
          </Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <TouchableOpacity
            onPress={(e) => {
              store.setCloseAlertVisible(false);
            }}
            style={{
              borderRadius: 5,
              backgroundColor: store.backgroundColor,
              padding: 10,
              width: 150,
              height: 50,
              margin: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={(e) => {
              store.setCloseAlertVisible(false);
              if (props.closeRestModal) props.closeRestModal();
              else
                RootNavigation.navigate("Tabs", {
                  screen: "RestaurantsStack",
                  params: {
                    screen: "Restaurants",
                    params: {
                      category: "all",
                      sort: "default",
                    },
                  },
                });
            }}
          >
            <Text
              style={{
                color: store.primaryColor,
                fontSize: 12,
                textAlign: "center",
              }}
            >
              Yes, leave Restaurant
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={(e) => {
              store.setCloseAlertVisible(false);
            }}
            style={{
              borderRadius: 5,
              backgroundColor: store.backgroundColor,
              padding: 10,
              width: 150,
              height: 50,
              margin: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: store.primaryColor,
                fontSize: 12,
                textAlign: "center",
              }}
            >
              Continue Shopping
            </Text>
          </TouchableOpacity>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
});

function getIcon(icon_type, icon, color, size = 20) {
  if (icon.indexOf(".png") > 0 || icon.indexOf(".svg") > 0) {
    return (
      <Image
        source={{ uri: icon }}
        style={{ width: size * 1.2, height: size * 1.2, tintColor: color }}
        resizeMode="contain"
      />
    );
  } else if (icon.indexOf(".png") > 0 || icon.indexOf(".svg") > 0) {
    return (
      <Image
        source={{ uri: icon }}
        style={{ width: size * 1.2, height: size * 1.2, tintColor: color }}
        resizeMode="contain"
      />
    );
  } else if (icon_type.indexOf("AntDesign") > -1) {
    return <AntDesign name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Octicons") > -1) {
    return <Octicons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Entypo") > -1) {
    return <Entypo name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("EvilIcons") > -1) {
    return <EvilIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Feather") > -1) {
    return <Feather name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesomeIcon") > -1) {
    return <FontAwesomeIcon icon={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesome5") > -1) {
    return <FontAwesome5 name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("FontAwesome") > -1) {
    return <FontAwesome name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Foundation") > -1) {
    return <Foundation name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Ionicons") > -1) {
    return <Ionicons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("MaterialCommunityIcons") > -1) {
    return <MaterialCommunityIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("MaterialIcons") > -1) {
    return <MaterialIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("SimpleLineIcons") > -1) {
    return <SimpleLineIcons name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Zocial") > -1) {
    return <Zocial name={icon} color={color} size={size} />;
  } else if (icon_type.indexOf("Fontisto") > -1) {
    return <Fontisto name={icon} color={color} size={size} />;
  }
}
function wait(timeout) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}
