import {
  StyleSheet,
  Button,
  View,
  Text,
  ActivityIndicator,
  Platform,
  ImageBackground,
  TouchableOpacity,
} from "react-native";
import { useState, useEffect, useRef, useCallback } from "react";
import { inject, observer, Observer } from "mobx-react";
import { Modalize } from "react-native-modalize";
import { Host, Portal } from "react-native-portalize";
import { RestaurantDetails } from "../screens/RestaurantScreen";
import { ProductDetails } from "../screens/ProductScreen";
import { RootTabScreenProps } from "../types";

import {
  CategoriesPanel,
  FeaturedRestaurants,
  FeaturedProducts,
  FeaturedDiscounts,
  FeaturedFeed,
  FullDiscounts,
  FullProducts,
} from "../components/RestaurantComponents";

import { SearchBar, HomeSlider } from "../components/HomeComponents";

import * as RootNavigation from "../navigation/RootNavigation";

import { ScrollView } from "react-native-gesture-handler";
import { Store } from "../stores/rootStore";

export const HomeScreen = observer(({ route, navigation }) => {
  // useEffect(() => {
  //   if (store.getSlides[0].backgroundVideo !== "") videoRef.current.play();
  // }, []);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const restaurentRef = useRef<Modalize>(null);
  const discountRef = useRef<Modalize>(null);
  const productRef = useRef<Modalize>(null);

  const [restModalOpen, setRestModalOpen] = useState(false);
  const [prodModalOpen, setProdModalOpen] = useState(false);
  const [discModalOpen, setDiscModalOpen] = useState(false);

  useEffect(() => {
    // setTimeout(() => {
    // openDiscModal();
    //     store.setSelectedProductId("marcel-gourmet-burger-le-mushy-blue");
    //     store.setSelectedRestaurantId("marcel-gourmet-burger");
    //     openRestModal();
    // }, 2000);
  }, []);

  const openDiscModal = () => {
    discountRef.current?.open();
    setDiscModalOpen(true);
  };
  const closeDiscModal = () => {
    discountRef.current?.close();
    setDiscModalOpen(false);
  };

  const openProdModal = () => {
    setProdModalOpen(true);
    productRef.current?.open();
  };
  const closeProdModal = () => {
    productRef.current?.close();
    setProdModalOpen(false);
  };

  const openRestModal = () => {
    restaurentRef.current?.open();
    setRestModalOpen(true);
  };
  const closeRestModal = () => {
    restaurentRef.current?.close();
    setRestModalOpen(false);
  };

  return (
    <>
      <ScrollView
        style={{ flex: 1 }}
        //  contentContainerStyle={{ flexGrow: 1 }}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ flex: 1, marginTop: 65 }}>
          <View
            style={{
              minHeight: 50,
              position: "absolute",
              width: "100%",
              padding: 5,
              top: 0,
              zIndex: 10,
            }}
          >
            <SearchBar />
          </View>
          <HomeSlider />
          <View style={{ flex: 1, flexDirection: "column", padding: 5 }}>
            {store.sortedRandomFeaturedRestaurants && (
              <View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View
                    style={{
                      flex: 0.65,
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        RootNavigation.navigate("Tabs", {
                          screen: "RestaurantsStack",
                          params: {
                            screen: "Restaurants",
                            params: {
                              category: "all",
                              sort: "default",
                            },
                          },
                        });
                        // RootNavigation.navigate("RestaurantsStack", {
                        //   screen: "Restaurants",
                        // });
                      }}
                    >
                      <Text
                        style={{
                          margin: 5,
                          marginTop: 10,
                          fontFamily: store.font, //"Roboto_400Regular",
                          fontSize: 20,
                          color: store.primaryColor,
                        }}
                      >
                        Featured Restaurants
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flex: 0.35,
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        RootNavigation.navigate("Tabs", {
                          screen: "RestaurantsStack",
                          params: {
                            screen: "Restaurants",
                            params: {
                              category: "all",
                              sort: "default",
                            },
                          },
                        });
                        // navigation.navigate("RestaurantsStack", {
                        //   screen: "Restaurants",
                        //   params: {
                        //     category: "all",
                        //     sort: "default",
                        //   },
                        // });
                      }}
                    >
                      <Text
                        style={{
                          margin: 5,
                          marginTop: 10,
                          fontFamily: "Roboto_400Regular",
                          fontSize: 14,
                          color: store.primaryColor,
                          textAlign: "right",
                        }}
                      >
                        View All
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <FeaturedRestaurants
                  openRestModal={openRestModal}
                  closeRestModal={closeRestModal}
                  width={store.screenWidth}
                  numberToShow={store.screenWidth > 800 ? 15 : 10}
                  itemWidth={
                    store.screenWidth > 800
                      ? store.screenWidth / 5 - 20
                      : store.screenWidth / 2 - 20
                  }
                />
              </View>
            )}
            {store.sortedProducts && (
              <View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View
                    style={{
                      flex: 0.65,
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        RootNavigation.navigate("Tabs", {
                          screen: "RestaurantsStack",
                          params: {
                            screen: "Restaurants",
                            params: {
                              category: "all",
                              sort: "default",
                            },
                          },
                        });
                        // RootNavigation.navigate("RestaurantsStack", {
                        //   screen: "Restaurants",
                        // });
                      }}
                    >
                      <Text
                        style={{
                          margin: 5,
                          marginTop: 10,
                          fontFamily: store.font, //"Roboto_400Regular",
                          fontSize: 20,
                          color: store.primaryColor,
                        }}
                      >
                        Featured Products
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flex: 0.35,
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        openProdModal();
                      }}
                    >
                      <Text
                        style={{
                          margin: 5,
                          marginTop: 10,
                          fontFamily: "Roboto_400Regular",
                          fontSize: 14,
                          color: store.primaryColor,
                          textAlign: "right",
                        }}
                      >
                        View All
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <FeaturedProducts
                  openRestModal={openRestModal}
                  closeRestModal={closeRestModal}
                  width={store.screenWidth}
                  numberToShow={store.screenWidth > 800 ? 15 : 10}
                  itemWidth={
                    store.screenWidth > 800
                      ? store.screenWidth / 5 - 20
                      : store.screenWidth / 2 - 20
                  }
                />
              </View>
            )}
            {store.sortedDiscounts && (
              <View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View
                    style={{
                      flex: 0.65,
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        openDiscModal();
                      }}
                    >
                      <Text
                        style={{
                          margin: 5,
                          marginTop: 10,
                          fontFamily: store.font, //"Roboto_400Regular",
                          fontSize: 20,
                          color: store.primaryColor,
                        }}
                      >
                        Featured Offers
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flex: 0.35,
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        openDiscModal();
                      }}
                    >
                      <Text
                        style={{
                          margin: 5,
                          marginTop: 10,
                          fontFamily: "Roboto_400Regular",
                          fontSize: 14,
                          color: store.primaryColor,
                          textAlign: "right",
                        }}
                      >
                        View All
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <FeaturedDiscounts
                  openRestModal={openRestModal}
                  closeRestModal={closeRestModal}
                  width={store.screenWidth}
                  numberToShow={store.screenWidth > 800 ? 15 : 10}
                  itemWidth={
                    store.screenWidth > 800
                      ? store.screenWidth / 5 - 20
                      : store.screenWidth / 2 - 20
                  }
                />
              </View>
            )}
            {store.sortedFeed && (
              <View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View
                    style={{
                      flex: 0.65,
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        RootNavigation.navigate("Feed");
                      }}
                    >
                      <Text
                        style={{
                          margin: 5,
                          marginTop: 10,
                          fontFamily: store.font, //"Roboto_400Regular",
                          fontSize: 20,
                          color: store.primaryColor,
                        }}
                      >
                        Restaurants Feed
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flex: 0.35,
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      onPress={(e) => {
                        RootNavigation.navigate("Feed");
                      }}
                    >
                      <Text
                        style={{
                          margin: 5,
                          marginTop: 10,
                          fontFamily: "Roboto_400Regular",
                          fontSize: 14,
                          color: store.primaryColor,
                          textAlign: "right",
                        }}
                      >
                        View All
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <FeaturedFeed
                  openRestModal={openRestModal}
                  closeRestModal={closeRestModal}
                  width={store.screenWidth}
                  numberToShow={store.screenWidth > 800 ? 15 : 10}
                  itemWidth={
                    store.screenWidth > 800
                      ? store.screenWidth / 5 - 20
                      : store.screenWidth / 2 - 20
                  }
                />
              </View>
            )}
            <TouchableOpacity
              onPress={(e) => {
                RootNavigation.navigate("Tabs", {
                  screen: "RestaurantsStack",
                  params: {
                    screen: "Restaurants",
                    params: {
                      category: "all",
                      sort: "default",
                    },
                  },
                });
                // RootNavigation.navigate("RestaurantsStack", {
                //   screen: "Restaurants",
                // });
              }}
            >
              <Text
                style={{
                  margin: 5,
                  marginTop: 10,
                  fontFamily: store.font, //"Roboto_400Regular",
                  fontSize: 20,
                  color: store.primaryColor,
                }}
              >
                Top categories
              </Text>
            </TouchableOpacity>
            <CategoriesPanel
              horizontal={store.screenWidth < 800}
              numberToShow={store.screenWidth > 800 ? 15 : 10}
              openPage={true}
              removeAll={true}
              width={store.screenWidth}
              itemWidth={
                store.screenWidth > 800
                  ? store.screenWidth / 5 - 20
                  : store.screenWidth / 2 - 20
              }
            />
          </View>
        </View>
      </ScrollView>
      <Portal>
        <Modalize
          ref={discountRef}
          modalTopOffset={20}
          onClose={(e) => {}}
          alwaysOpen={discModalOpen ? store.screenHeight : false}
          modalStyle={{
            backgroundColor: store.selectedRestaurant.backgroundColor,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <View
            style={{
              height: store.screenHeight - 10, //"100%",
              width: "100%",
              marginTop: 10,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              backgroundColor: store.surfaceColor,
            }}
          >
            <FullDiscounts
              openRestModal={openRestModal}
              closeRestModal={closeRestModal}
              openDiscModal={openDiscModal}
              closeDiscModal={closeDiscModal}
              width={store.screenWidth}
              itemHeight={55}
            />
          </View>
        </Modalize>
      </Portal>
      <Portal>
        <Modalize
          ref={productRef}
          modalTopOffset={20}
          alwaysOpen={prodModalOpen ? store.screenHeight : false}
          onClose={(e) => {}}
          modalStyle={{
            backgroundColor: store.selectedRestaurant.backgroundColor,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <View
            style={{
              height: store.screenHeight - 10, //"100%",
              width: "100%",
              marginTop: 10,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              backgroundColor: store.surfaceColor,
            }}
          >
            <FullProducts
              openRestModal={openRestModal}
              closeRestModal={closeRestModal}
              openProdModal={openProdModal}
              closeProdModal={closeProdModal}
              width={store.screenWidth}
              itemHeight={55}
            />
          </View>
        </Modalize>
      </Portal>

      <Portal>
        <Modalize
          ref={restaurentRef}
          modalTopOffset={0}
          alwaysOpen={restModalOpen ? store.screenHeight : false}
          onClose={(e) => {
            store.setSelectedRestaurantId("");
            store.setSelectedProductId("");
          }}
          modalStyle={{
            backgroundColor: store.selectedRestaurant.backgroundColor,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          <View
            style={{
              height: store.screenHeight, //"100%",
              width: "98%",
              marginTop: 10,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              backgroundColor: store.selectedRestaurant.backgroundColor,
            }}
          >
            {store.selectedProduct ? (
              <ProductDetails
                inline={true}
                openRestModal={openRestModal}
                closeRestModal={closeRestModal}
              />
            ) : (
              <RestaurantDetails
                inline={true}
                openRestModal={openRestModal}
                closeRestModal={closeRestModal}
              />
            )}
          </View>
        </Modalize>
      </Portal>
    </>
  );
});
